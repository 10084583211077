// ** React Imports
import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'

// ** Third party libraries
import Cookies from 'js-cookie'

// ** SPINNER
import FallbackSpinner from 'core/components/spinner'
import { getAdminAccess, getAdminAppAccess, refreshFreshbooksToken } from 'configs/axios/api_helper'

// ** Config
// import authConfig from 'src/configs/auth'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  error: false,
  isInitialized: false,
  login: () => null,
  logout: () => null,
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [error, setError] = useState(defaultProvider.error)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)

  // ** Hooks
  const navigate = useNavigate()

  // ** This part needs to be refactored for securtiy issues (IMPORTANT!!!)
  const search = window.location.search
  const params = new URLSearchParams(search)
  const sharedEmail = params.get('email')
  const sharedName = params.get('name')
  const sharedPicture = params.get('picture')

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      setLoading(true)
      const storedToken = Cookies.get('sharedToken')
      if (storedToken !== undefined && storedToken != 'undefined') {
        const tokenInfo = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${storedToken}`)
          .then(res => res.json())
          .catch(error => error)
        if (tokenInfo.error_description === 'Invalid Value') {
          Cookies.remove('userData')
          Cookies.remove('sharedToken')
          setUser(null)
          setLoading(false)
          navigate('/login')
        } else if (tokenInfo?.expires_in > 1) {
          // ** Check if token exist and still valid
          const storedUser = Cookies.get('userData')
          setUser(JSON.parse(storedUser) ?? null)
          setLoading(false)
          if (window.location.pathname == '/login') navigate('/')
          else navigate(window.location.pathname)
        }
      } else if (sharedEmail && sharedName) {
        // ** Case of log in from another app
        // This part need to be refactored for security issues (IMPORTANT!!!)
        // Google token which is delivered from the backend (Admin Plus) should be sent to frontend also to verify it here too
        //const storedUser = cookies.userData
        const userData = { email: sharedEmail, name: sharedName, picture: sharedPicture || '' }

        handleLogin(userData, () => {})
      } else {
        Cookies.remove('userData')
        Cookies.remove('sharedToken')
        setUser(null)
        setLoading(false)
        // if (window.location.pathname !== '/login' || window.location.pathname !== '/privacy-policy') {
        //   navigate('/login')
        // }
      }
    }
    initAuth()
  }, [])

  const handleLogin = async (data, errorCallback) => {
    setLoading(true)
    setError(false)
    Cookies.remove('userData')
    Cookies.remove('sharedToken')
    try {
      const response = await getAdminAccess({ email: data.email })
      const access_apps = await getAdminAppAccess({ email: data.email })
      Cookies.set('sharedToken', data.access_token)
      Cookies.set(
        'userData',
        JSON.stringify({ email: data.email, name: data.name, picture: data.picture, access_apps: access_apps })
      )
      setUser({ email: data.email, name: data.name, picture: data.picture, access_apps: access_apps })
      //Call this api to refresh freshbook token in the backend
      //(nothing to do with the front application just an action needs to be executed)
      await refreshFreshbooksToken()
      setLoading(false)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      errorCallback({ message: 'not authorized', code: 401 })
      setError(true)
    }
  }

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    Cookies.remove('userData')
    Cookies.remove('sharedToken')
    Cookies.remove('accountData')
    navigate('/login')
  }

  const values = {
    user,
    loading,
    error,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout
    // register: handleRegister
  }
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mt: '20%'
        }}
      >
        <FallbackSpinner />
      </Box>
    )
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
