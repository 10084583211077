// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

// ** Icons Imports
import Icon from 'mdi-material-ui/Plus'

// ** Custom Components
import ContactsContainer from '../cards/ContactsCardsContainer'
import ContactsForm from '../forms/ContactsForm'

const Container = styled(Grid)(({ theme }) => ({
  paddingLeft: '20%',
  paddingRight: '20%',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

const ContactsInformations = () => {
  // ** State
  const [openAdd, setOpenAdd] = useState(false)

  return (
    <Grid container>
      <Container item xs={12}>
        <ContactsContainer />
      </Container>
      <Container item xs={12}>
        {!openAdd && (
          <Box sx={{ display: 'flex' }}>
            <Button
              sx={{ mt: '1rem' }}
              color='primary'
              variant='contained'
              size='medium'
              onClick={() => setOpenAdd(true)}
              startIcon={<Icon />}
            >
              Add contact
            </Button>
          </Box>
        )}

        {openAdd && <ContactsForm hide={setOpenAdd} />}
      </Container>
    </Grid>
  )
}

export default ContactsInformations
