// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit'
import pricingBooksReducer from './metaData/pricingbook'
import rulesReducer from './rules'
import taxReducer from './metaData/tax'
import commitmentDurationReducer from './metaData/commitmentDuration'
import pricePlanReducer from './metaData/pricePlan'
import engagementTypeReducer from './metaData/engagementType'
import currenciesReducer from './metaData/currency'
import paymentMethodsReducer from './metaData/paymentMethod'
import customersReducer from './customers'
import { apiSlice } from './customers/apiCaching'
import authReducer from './auth'
import dueDateReducer from './metaData/dueDate'
import productsReducer from './products'
import storage from 'redux-persist/lib/storage'

// const persistConfig = {
//     key: 'root',
//     storage,
// }

// const persistedReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
  reducer: {
    pricingBooksData: pricingBooksReducer,
    rulesData: rulesReducer,
    taxData: taxReducer,
    commitmentDurationData: commitmentDurationReducer,
    pricePlanData: pricePlanReducer,
    engagementTypeData: engagementTypeReducer,
    currenciesData: currenciesReducer,
    paymentMethodsData: paymentMethodsReducer,
    dueDateData: dueDateReducer,
    customers: customersReducer,
    authData: authReducer,
    productsData: productsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true
    }).concat(apiSlice.middleware)
})

// export const persistor = persistStore(store)

export default store
