// ** React Imports
import React, { useState, Fragment } from 'react'

// ** Next Import
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// ** Icons Imports
import CogOutline from 'mdi-material-ui/CogOutline'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'
import AccountOutline from 'mdi-material-ui/AccountOutline'


// ** Context
import { useAuth } from 'hooks/useAuth'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.dark,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = props => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)

  // ** Hooks
  const navigate = useNavigate()
  const { logout, user } = useAuth()

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = url => {
    if (url) {
      navigate(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  const handleLogout = () => {
    logout()
    handleDropdownClose()
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Avatar
          alt={user?.name?.toUpperCase()}
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40, color: 'common.white', backgroundColor: "secondary.light" }}
          src={user?.picture}
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 370, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ pb: 3, px: 1 }}>
          <Box
            sx={{
              backgroundColor: "info.blue",
              borderRadius: "0.2rem",
              py: "0.3rem",
              mt: "-0.2rem",
              mb: "1rem",
              textAlign: "center"
            }}>
            <Typography sx={{ color: 'text.secondary', fontSize: "0.825rem" }}>
              This account is managed by <strong>{user?.email}</strong>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "center" }}>
            <Badge
              overlap='circular'
              //badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <Avatar alt={user?.name?.toUpperCase()} src="/logo/main-logo.png" sx={{ width: '5.5rem', height: '5.5rem' }} />
            </Badge>
            <Box sx={{ display: 'flex', ml: 3, mt: "1rem", alignItems: 'center', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 600 }}>{user?.name?.toUpperCase()}</Typography>
              <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                {user?.email}
              </Typography>
            </Box>
          </Box>
          {/* <Box sx={{ display: 'flex', mt: "1rem", justifyContent: 'center' }}>
            <Box sx={{ py: 1.5, px: 4, borderRadius: "1rem", border: theme => `1px solid ${theme.palette.grey[300]}`, width: "fit-content" }}>
              <Typography sx={{ fontSize: "0.825rem", fontWeight: 600 }}>Manage you Google Account</Typography>
            </Box>
          </Box> */}
        </Box>
        <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
        <Box sx={{
          py: 2,
          color: 'text.secondary',
          textAlign: 'center',
          border: theme => `1px solid ${theme.palette.grey[300]}`,
          mx: "auto",
          my: "1rem",
          borderRadius: "0.3rem",
          width: 120,
          fontSize: "0.875rem",
          fontWeight: 600,
          cursor: "pointer"
        }}
          onClick={handleLogout}
        >
          Sign out
        </Box>
        <Divider sx={{ mt: 0, mb: 1, borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
        <Box
          sx={{
            mx: "auto",
            my: "0.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
          <Typography
            sx={{
              fontSize: "0.825rem",
              color: "text.secondary",
              borderRadius: "0.2rem",
              cursor: "pointer",
              px: "0.2rem",
              "&:hover": { backgroundColor: theme => theme.palette.grey[100] }
            }}>
            Privacy Policy
          </Typography>
          <Typography sx={{ mx: "0.5rem" }}>
            .
          </Typography>
          <Typography
            sx={{
              fontSize: "0.825rem",
              color: "text.secondary",
              borderRadius: "0.2rem",
              cursor: "pointer",
              px: "0.2rem",
              "&:hover": { backgroundColor: theme => theme.palette.grey[100] }
            }}>
            Terms of service
          </Typography>
        </Box>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
