// ** React Imports
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

// ** MUI Imports
import { Typography, Card, CardHeader, Tab, CircularProgress, Box } from '@mui/material'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'

// ** Demo Components Imports
import BillingTable from './containers/BillingTable'
import TransactionsList from './containers/TransactionsList'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerInvoices, getBoardedCustomerInformationOnly, customersSelector } from 'store/customers'

const Customers = () => {
  // ** Hooks
  const { pathname } = useLocation()
  const { id } = useParams()
  const dispatch = useDispatch()

  // ** Selectors
  const { billingSearch, loading } = useSelector(customersSelector)

  // ** Fetch customers
  useEffect(() => {
    if (!!id) {
      dispatch(getCustomerInvoices({ id }))
      dispatch(getBoardedCustomerInformationOnly(id))
      // if (!billingSearch.organization && !billingSearch.billingDay && !billingSearch.freshbooksId) {
      //   dispatch(getBoardedCustomerInformationOnly(id))
      // }
    }
  }, [id])

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant='h6' sx={{ color: 'common.black', fontWeight: 600 }}>
            Billing
          </Typography>
        }
      />
      <TabContext value='1'>
        <TabList
          sx={{
            pl: '1rem',
            '&.MuiTabs-root': {
              borderBottom: theme => `solid 1px ${theme.palette.grey[300]}`
            },
            '& .MuiButtonBase-root': { textTransform: 'capitalize', fontWeight: 600 },
            '& .MuiTabs-indicator': {}
          }}
          aria-label='full width tabs example'
        >
          <Tab value='1' label='Invoices' />
        </TabList>
        <TabPanel value='1' sx={{ px: '1rem' }}>
          {pathname == '/billing' && <BillingTable />}
          {id && loading !== 'GET' && <TransactionsList />}
          {id && loading === 'GET' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <CircularProgress disableShrink sx={{ mt: 6 }} />
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </Card>
  )
}

export default Customers
