// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'


// ** Icons Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import { useNavigate } from 'react-router-dom'

// Styled Grid component
const StyledGrid = styled(Grid)(({ theme }) => ({
    // [theme.breakpoints.down('sm')]: {
    //     borderBottom: `1px solid ${theme.palette.divider}`
    // },
    // [theme.breakpoints.up('sm')]: {
    //     borderRight: `1px solid ${theme.palette.grey[300]}`
    // }
}))

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    border: `solid 1px ${theme.palette.grey[300]}`
}))


const HomeCard = (props) => {

    // ** Hooks 
    const navigate = useNavigate()

    // ** Props
    const { title, items, icon } = props

    // ** State
    const [collapsed, setCollapsed] = useState(true)

    return (
        <StyledCard>
            <CardHeader
                title={
                    <Stack direction="row" alignItems="center" gap={4} sx={{ my: 2, color: "common.blue" }}>
                        {icon}
                        <Typography variant='body2' sx={{ color: 'common.black', fontSize: "1rem" }}>
                            {title}
                        </Typography>
                    </Stack>
                }
                action={
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant='outlined'
                            sx={{
                                mx: 1,
                                border: "none",
                                fontWeight: 600,
                            }}
                        >
                            Manage
                        </Button>
                        <IconButton
                            size='small'
                            aria-label='collapse'
                            sx={{ color: 'text.secondary' }}
                            onClick={() => setCollapsed(!collapsed)}
                        >
                            {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
                        </IconButton>

                    </Box>

                }
            // sx={{ borderBottom: "solid 1px" }}
            />
            <Collapse in={collapsed}>
                <CardContent>
                    {items.map((item, index) => (
                        <Typography
                            key={index}
                            variant='body2'
                            sx={{ color: "primary.main", fontWeight: 600, my: 5, fontSize: "1rem", cursor: "pointer" }}
                            onClick={() => navigate(item.path)}
                        >
                            {item.name}
                        </Typography>)
                    )}
                </CardContent>
            </Collapse>


        </StyledCard>
    )
}

export default HomeCard
