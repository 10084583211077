// ** Axios
import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createMetadata, editMetadata, getMetaDataData } from 'configs/axios/api_helper'

// ** API Imports

export const initialState = {
  loading: null,
  dueDateData: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const dueDateSlice = createSlice({
  name: 'dueDateData',
  initialState,
  reducers: {
    addDueDate: (state, action) => {
      state.dueDateData.push(action.payload)
      state.success = 'CREATE'
      state.loading = null
    },
    updateDueDate: (state, action) => {
      state.dueDateData = state.dueDateData.map(dueDate =>
        dueDate.id.toString() === action.payload.id.toString() ? { dueDate, ...action.payload } : dueDate
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removeDueDate: (state, action) => {
      state.dueDateData = state.dueDateData.filter(dueDate => dueDate.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getDueDateDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.dueDateData = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getDueDateDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addDueDate,
  updateDueDate,
  removeDueDate,
  startLoading,
  resetMessages,
  getDueDateDataFailure,
  getDueDateDataSuccess
} = dueDateSlice.actions

// export user selector to get the slice in any component
export const dueDateSelector = state => state.dueDateData

// export The reducer
const dueDateReducer = dueDateSlice.reducer
export default dueDateReducer

// Actions
export const fetchDueDateData = () => async dispatch => {
  try {
    dispatch(startLoading('GET'))
    const { data } = await getMetaDataData('type/dueDate')
    dispatch(getDueDateDataSuccess(data ?? []))
  } catch (error) {
    dispatch(getDueDateDataFailure())
  }
}

export const createDueDate = dueDate => async dispatch => {
  try {
    dispatch(startLoading('CREATE'))
    const data = await createMetadata({ ...dueDate, type: 'dueDate' })
    dispatch(addDueDate(data))
  } catch (error) {
    dispatch(getDueDateDataFailure())
  }
}

export const deleteDueDate = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE'))
    const data = await editMetadata(id, { status: 'archived' })
    dispatch(removeDueDate(id))
  } catch (error) {
    dispatch(getDueDateDataFailure())
  }
}

export const editDueDate = dueDate => async dispatch => {
  try {
    const { id, ...rest } = dueDate
    dispatch(startLoading('UPDATE'))
    const data = await editMetadata(id, { ...rest })
    dispatch(updateDueDate(dueDate))
  } catch (error) {
    dispatch(getDueDateDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
