


// ** REACT Imports
import React, { useState } from 'react'

// ** MUI Imports
import { Divider, CardHeader, Card, Typography, Tab, Grid, Box, CardContent } from '@mui/material'

// ** Component Imports
import GoogleInformations from '../components/customers-onboarding/cards/GoogleInformations'
import FreshbooksInformations from '../components/customers-onboarding/cards/FreshbooksInformations'
import BillingInformations from '../components/customers-onboarding/cards/BillingInformations'
import ContactsInformations from '../components/customers-onboarding/cards/Contactsinformations'
import CustomersOnboardingTable from '../components/tables/CustomersOnboardingTable'

// ** Icons Imports
import ArrowLeftIcon from "mdi-material-ui/ArrowLeft"
import ChevronRightIcon from "mdi-material-ui/ChevronRight"


const CustomersOnboarding = (props) => {

    // ** State
    const [value, setValue] = useState('1')

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        sx={{ mb: "-1rem" }}
                        title={
                            <Typography variant="h5">
                                Customers Onboarding
                            </Typography>
                        }
                    // action={<ActionButtons />}
                    />
                    <Divider sx={{ color: theme => theme.palette.grey[300] }} />
                    <CardContent>
                        <Grid container>
                            <Grid xs={12} item>
                                <CustomersOnboardingTable />
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                                <GoogleInformations />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FreshbooksInformations />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BillingInformations />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ContactsInformations />
                            </Grid>
                        </Grid> */}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}


export default CustomersOnboarding


