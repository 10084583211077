import { createSlice } from '@reduxjs/toolkit'

// ** API Imports
import { createMetadata, editMetadata, getMetaDataData } from 'configs/axios/api_helper'

export const initialState = {
  loading: null,
  pricePlans: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const pricePlanSlice = createSlice({
  name: 'pricePlanData',
  initialState,
  reducers: {
    addPricePlan: (state, action) => {
      state.pricePlans.push(action.payload)
      ;(state.success = 'CREATE'), (state.loading = null)
    },
    updatePricePlan: (state, action) => {
      state.pricePlans = state.pricePlans.map(tax =>
        tax.id.toString() === action.payload.id.toString() ? { tax, ...action.payload } : tax
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removePricePlan: (state, action) => {
      state.pricePlans = state.pricePlans.filter(tax => tax.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getPricePlanDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.pricePlans = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getPricePlanDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addPricePlan,
  updatePricePlan,
  removePricePlan,
  startLoading,
  resetMessages,
  getPricePlanDataFailure,
  getPricePlanDataSuccess
} = pricePlanSlice.actions

// export user selector to get the slice in any component
export const pricePlanSelector = state => state.pricePlanData

// export The reducer
const pricePlanReducer = pricePlanSlice.reducer
export default pricePlanReducer

// Actions
export const fetchPricePlanData = () => async dispatch => {
  try {
    dispatch(startLoading('GET'))
    const { data } = await getMetaDataData('type/pricePlan')
    dispatch(getPricePlanDataSuccess(data ?? []))
  } catch (error) {
    dispatch(getPricePlanDataFailure())
  }
}

export const createPricePlan = tax => async dispatch => {
  try {
    dispatch(startLoading('CREATE'))
    const data = await createMetadata({ ...tax, type: 'pricePlan' })
    dispatch(addPricePlan(data))
  } catch (error) {
    dispatch(getPricePlanDataFailure())
  }
}

export const deletePricePlan = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE'))
    await editMetadata(id, { status: 'archived' })
    dispatch(removePricePlan(id))
  } catch (error) {
    dispatch(getPricePlanDataFailure())
  }
}

export const editPricePlan = tax => async dispatch => {
  try {
    const { id, ...rest } = tax
    dispatch(startLoading('UPDATE'))
    await editMetadata(id, { ...rest })
    dispatch(updatePricePlan(tax))
  } catch (error) {
    dispatch(getPricePlanDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
