// ** React Imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { Grid, TextField, Stack, IconButton, FormControlLabel, Switch, Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import DeleteIcon from 'mdi-material-ui/Delete'

const CustomInputs = props => {
  // ** Props
  const { data, deleteHandler, setEdit, setDraft, type } = props

  // ** State
  const [updatedData, setUpdatedData] = useState({
    name: data.name,
    value: data.value,
    iso_code: data.iso_code,
    status: data.status,
    convertion_rate: data.convertion_rate
  })
  const [defaultValues, setDefaultValues] = useState({
    name: data.name,
    value: data.value,
    iso_code: data.iso_code,
    status: data.status,
    convertion_rate: data.convertion_rate
  })
  const error = parseFloat(updatedData.value) < 0 ? true : false

  useEffect(() => {
    if (type == 'pricingBook') {
      if (
        updatedData.name &&
        updatedData.status &&
        (updatedData.name != defaultValues.name || updatedData.status != defaultValues.status)
      ) {
        setDraft({ ...updatedData })
        setEdit(data.id)
      }
    } else if (type == 'Currency') {
      if (
        updatedData.name &&
        updatedData.iso_code &&
        (updatedData.name != defaultValues.name ||
          updatedData.iso_code != defaultValues.iso_code ||
          updatedData.convertion_rate != defaultValues.convertion_rate)
      ) {
        setDraft({ ...updatedData })
        setEdit(data.id)
      }
    } else if (
      updatedData.name &&
      updatedData.value &&
      (updatedData.name != defaultValues.name || updatedData.value != defaultValues.value)
    ) {
      setDraft({ ...updatedData })
      setEdit(data.id)
    } else {
      setDraft(null)
      setEdit(null)
    }
  }, [updatedData])

  // ** Functions

  const onChangeHandler = event => {
    const { name, value } = event.target
    setUpdatedData({ ...updatedData, [name]: value })
  }

  const statusHandler = event => {
    const { name, checked } = event.target
    setUpdatedData({ ...updatedData, [name]: checked ? 'active' : 'inactive' })
  }

  return (
    <Grid item xs={12} sx={{ display: 'flex', mb: 5 }}>
      <Stack direction='row' alignItems='center' gap={1} sx={{ my: 2 }}>
        <TextField
          sx={{ mr: 5 }}
          label={type + ' name'}
          size='small'
          name='name'
          value={updatedData.name ?? ''}
          onChange={onChangeHandler}
        />
        {type === 'pricingBook' ? (
          <FormControlLabel
            control={
              <Switch name='status' onChange={statusHandler} checked={updatedData.status === 'active' ? true : false} />
            }
            label={
              <Typography fontSize={14} fontWeight={600}>
                {updatedData.status === 'active' ? 'active' : 'inactive'}
              </Typography>
            }
          />
        ) : (
          <TextField
            sx={{ mr: 5 }}
            label={type == 'Currency' ? 'Code' : 'Fee'}
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {type == 'Payment Term' ? 'days' : type == 'Currency' ? '' : '%'}
                </InputAdornment>
              )
            }}
            type={type === 'Currency' ? 'text' : 'number'}
            name={type == 'Currency' ? 'iso_code' : 'value'}
            value={(type == 'Currency' ? updatedData.iso_code : updatedData.value) ?? ''}
            onChange={onChangeHandler}
            error={error}
            helperText={error && 'Value must be positive'}
          />
        )}
        {type === 'Currency' ? (
          <TextField
            sx={{ mr: 5 }}
            label='Conversion rate'
            size='small'
            name='convertion_rate'
            value={updatedData.convertion_rate ?? ''}
            onChange={onChangeHandler}
          />
        ) : null}
        <IconButton onClick={() => deleteHandler(data.id)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Grid>
  )
}

export default CustomInputs
