// ** REACT Imports
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import {
  Divider,
  CardHeader,
  Card,
  Typography,
  Tab,
  Grid,
  Box,
  Button,
  useMediaQuery,
  CircularProgress
} from '@mui/material'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'

// ** Icons Imports
import ArrowLeftIcon from 'mdi-material-ui/ArrowLeft'
import ChevronRightIcon from 'mdi-material-ui/ChevronRight'

// ** Custom Component Imports
import CustomerInformations from '../components/Tabs/CustomerInformations'
import BillingInformation from '../components/Tabs/BillingInformation'
import ContactsInformations from '../components/Tabs/ContactsInformations'
import GoogleIdsInformation from '../components/Tabs/GoogleIdsInformation'
import CurrenctSpending from '../components/Tabs/CurrenctSpending'
import Overview from '../components/Tabs/Overview'

// ** Reduc Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanCustomerInformation,
  customersSelector,
  deleteBoardedCustomer,
  getBoardedCustomer,
  getCustomerOnboarding
} from 'store/customers'
import InvoiceTemplate from '../components/Tabs/InvoiceTemplate'

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  paddingLeft: '3rem',
  paddingRight: '3rem',
  [theme.breakpoints.down('xl')]: {
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const StyledTabList = styled(TabList)(({ theme }) => ({
  paddingLeft: '3rem',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2)
  },
  ' & .MuiButtonBase-root': { textTransform: 'capitalize' }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '5px !important',
  textTransform: 'capitalize !important',
  border: `2px solid ${theme.palette.grey[300]}`,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: `${theme.palette.grey[100]} !important`,
    border: `2px solid ${theme.palette.grey[300]}`
  },
  [theme.breakpoints.down('sm')]: {
    width: 'fit-content'
  }
}))

const CustomerDisplay = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** Check wether to display boarded or onboarding customers
  const customersType = pathname.split('/')[1]

  // ** State
  const [value, setValue] = useState('1')

  // ** Fetch customer
  useEffect(() => {
    if (id) {
      if (customersType == 'google-customers') dispatch(getCustomerOnboarding(id))
      else dispatch(getBoardedCustomer(id))
    }

    return () => {
      dispatch(cleanCustomerInformation())
    }
  }, [])

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)

  // ** Functions
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const deleteCustomerHandler = () => {
    dispatch(deleteBoardedCustomer(customerInformation?.id, navigate))
  }

  const handleNavigation = () => {
    if (customersType == 'google-customers') {
      navigate('/customers/onboarding-list')
    } else {
      navigate('/customers/customers-list')
    }
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card
          sx={{
            boxShadow: 0,
            border: theme => `solid 1px ${theme.palette.grey[300]}`
          }}
        >
          <CardHeader
            sx={{ mb: '-1rem' }}
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!small && <ArrowLeftIcon sx={{ mr: '1rem', cursor: 'pointer' }} onClick={handleNavigation} />}
                <Typography variant='h5'>
                  {customersType == 'google-cutomers' ? 'Google Customers' : 'Customers'}
                </Typography>
                <ChevronRightIcon sx={{ ml: '0.5rem', fontSize: '2.225rem' }} />
                <StyledButton variant='outlined'>{customerInformation?.organization || '----'}</StyledButton>
              </Box>
            }
            action={
              customerInformation?.googleIds?.length < 1 ? (
                <Button
                  variant='text'
                  color='error'
                  onClick={deleteCustomerHandler}
                  disabled={loading == 'DELETE_CUSTOMER'}
                  startIcon={
                    loading == 'DELETE_CUSTOMER' && <CircularProgress sx={{ color: 'error.main' }} size='1rem' />
                  }
                >
                  Delete
                </Button>
              ) : null
            }
          />
          <Divider sx={{ color: theme => theme.palette.grey[300] }} />
          <TabContext value={value}>
            <StyledTabList onChange={handleChange}>
              {customersType == 'customers' && <Tab value='1' label='Overview' />}
              <Tab value={customersType == 'customers' ? '2' : '1'} label='Customer information' />
              {customersType == 'customers' && <Tab value='3' label='Billing information' />}
              {customersType == 'customers' && <Tab value='4' label='Contacts information' />}
              {customersType == 'customers' && <Tab value='5' label='Google IDs information' />}
              {customersType == 'customers' && <Tab value='6' label='Currenct Spending' />}
              {customersType == 'customers' && <Tab value='7' label='Invoice Template' />}
            </StyledTabList>
            {customersType == 'customers' && (
              <StyledTabPanel value='1'>
                <Overview setValue={setValue} />
              </StyledTabPanel>
            )}
            <StyledTabPanel value={customersType == 'customers' ? '2' : '1'}>
              <CustomerInformations />
            </StyledTabPanel>
            {customersType == 'customers' && (
              <StyledTabPanel value='3'>
                <BillingInformation />
              </StyledTabPanel>
            )}
            {customersType == 'customers' && (
              <StyledTabPanel value='4'>
                <ContactsInformations />
              </StyledTabPanel>
            )}
            {customersType == 'customers' && (
              <StyledTabPanel value='5'>
                <GoogleIdsInformation />
              </StyledTabPanel>
            )}
            {customersType == 'customers' && (
              <StyledTabPanel value='6'>
                <CurrenctSpending />
              </StyledTabPanel>
            )}
            {customersType == 'customers' && (
              <StyledTabPanel value='7'>
                <InvoiceTemplate />
              </StyledTabPanel>
            )}
          </TabContext>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CustomerDisplay
