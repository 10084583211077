// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import { DataGrid } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import Grow from '@mui/material/Grow'
import LinearProgress from '@mui/material/LinearProgress'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'

// ** 3rd Party Libraries
import moment from 'moment'

// ** Custom Components
import Filters from '../components/filters/Filters'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { cleanMessages, customersSelector, fetchBoardedCustomers } from 'store/customers'
import GenerateInvoiceModal from '../components/GenerateInvoiceModal'
import { useGetBoardedCustomersQuery } from 'store/customers/apiCaching'
import { currencySelector, fetchCurrencyData } from 'store/metaData/currency'
import UploadVoiceMonthlyUsage from '../components/UploadVoiceMonthlyUsage'
import UploadGoogleUsage from '../components/UploadGoogleUsage'

// ** renders client column
const renderClient = params => {
  const { row } = params
  const stateNum = Math.floor(Math.random() * 6)
  const states = ['success', 'error', 'warning', 'info', 'primary', 'secondary']
  const color = states[stateNum]
  return <CustomAvatar src={`/images/avatars/${row.avatar}`} sx={{ mr: 3, width: '1.875rem', height: '1.875rem' }} />
}

const statusObj = {
  1: { title: 'current', color: 'primary' },
  2: { title: 'professional', color: 'success' },
  3: { title: 'rejected', color: 'error' },
  4: { title: 'resigned', color: 'warning' },
  5: { title: 'applied', color: 'info' }
}

const BillingTable = () => {
  // ** Hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // ** Selectors
  const { billingSearch, success, error } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** State
  const [selectionModel, setSelectionModel] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [paginationFilters, setPaginationFilters] = useState({
    limit: 20,
    page: 1
  })

  // ** Queries
  const {
    data: boardedCustomers,
    iSuccess,
    isError,
    isLoading,
    isFetching
  } = useGetBoardedCustomersQuery({
    ...billingSearch,
    limit: paginationFilters.limit,
    page: paginationFilters.page
  })
  useEffect(() => {
    if (currenciesData?.length < 1) dispatch(fetchCurrencyData())
  }, [])
  useEffect(() => {
    if (
      success == 'GENERATE_INVOICE' ||
      error == 'GENERATE_INVOICE' ||
      error == 'ADVANCE_INVOICE' ||
      error == 'DAILY_USAGE'
    ) {
      setOpenAlert(true)
      setTimeout(() => {
        dispatch(cleanMessages())
      }, 2000)
    }
  }, [success, error])

  // ** Functions
  const handleCloseMessage = () => {
    setOpenAlert(false)
  }

  const handleChangePage = newPage => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPaginationFilters({
      ...paginationFilters,
      page: ++newPage
    })
  }

  const handleChangeRowsPerPage = rows => {
    setPaginationFilters({
      ...paginationFilters,
      limit: rows
    })
  }

  const columns = [
    {
      flex: 0.25,
      minWidth: 290,
      field: 'organization',
      headerName: 'Organization',
      renderCell: params => {
        const { row } = params

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {renderClient(params)}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant='body2'
                sx={{ color: 'common.blue', cursor: 'pointer' }}
                onClick={() => navigate('/billing/' + row.id)}
              >
                {row.organization}
              </Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      flex: 0.175,
      width: 100,
      headerName: 'Freshbooks ID',
      field: 'freshbooksId',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.freshbooksId}
        </Typography>
      )
    },
    {
      flex: 0.125,
      field: 'lastBillingDate',
      minWidth: 80,
      headerName: 'Last Billing date',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.lastBillingDate ? moment(new Date(params.row.lastBillingDate)).format('MMM DD, YYYY') : '---'}
        </Typography>
      )
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: 'nextBillingDate',
      headerName: 'Next billing date',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.nextBillingDate ? moment(new Date(params.row.nextBillingDate)).format('MMM DD, YYYY') : '---'}
        </Typography>
      )
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: 'billingType',
      headerName: 'Billing Type',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.billingType ? (params.row.billingType === 'inadvance' ? 'in Advance' : 'Rear') : '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      field: '',
      minWidth: 80,
      headerName: 'Actions',
      renderCell: params => (
        <Typography
          variant='body2'
          sx={{ color: 'common.blue', cursor: 'pointer' }}
          onClick={() => navigate('/billing/' + params.row.id)}
        >
          view invoices
        </Typography>
      )
    }
  ]

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '58px'
        }}
        title={
          <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ px: '0.25rem' }}>
            <Box display='flex' alignItems='center' gap={1}>
              <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Billings</Typography>
              <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>| Display all billing list</Typography>
              <GenerateInvoiceModal customers={selectionModel} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <UploadGoogleUsage />
              <UploadVoiceMonthlyUsage />
            </Box>
          </Box>
          // <Stack direction='row' alignItems='center' gap={1} sx={{ px: '0.25rem' }}>
          //   <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Billings</Typography>
          //   <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>| Display all billing list</Typography>
          //   <Typography sx={{ ml: '2rem', color: 'common.blue', fontWeight: 400, fontSize: 14, cursor: 'pointer' }}>
          //     <GenerateInvoiceModal customers={selectionModel} />
          //   </Typography>
          // </Stack>
        }
      />
      <Box sx={{ height: 54, px: 12, display: 'flex', alignItems: 'center' }}>
        <Filters />
      </Box>
      <DataGrid
        //scroll
        autoHeight
        rows={isFetching ? [] : boardedCustomers?.data || []}
        columns={columns}
        loading={isFetching}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={selection => {
          setSelectionModel(selection)

          if (selection.length > 1) {
            const selectionSet = new Set(selectionModel)
            const result = selection.filter(s => !selectionSet.has(s))

            setSelectionModel(result)
          } else {
            setSelectionModel(selection)
          }
        }}
        pagination
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        pageSize={boardedCustomers?.limit || 0}
        rowCount={boardedCustomers?.total || 0}
        page={boardedCustomers?.currentPage ? boardedCustomers?.currentPage - 1 : 0}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />

      {success == 'GENERATE_INVOICE' && (
        <Snackbar
          sx={{ mt: '3rem' }}
          open={openAlert}
          onClose={handleCloseMessage}
          autoHideDuration={2000}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
          message={'Invoice was generated successfully !'}
        />
      )}
      {error == 'GENERATE_INVOICE' && (
        <Snackbar
          sx={{ mt: '3rem' }}
          open={openAlert}
          onClose={handleCloseMessage}
          autoHideDuration={2000}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
          message='There was an error when generating your invoice !'
        />
      )}
      {error == 'DAILY_USAGE' && (
        <Snackbar
          sx={{ mt: '3rem' }}
          open={openAlert}
          onClose={handleCloseMessage}
          autoHideDuration={2000}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
          message='This customer has no daily usage !'
        />
      )}
      {error == 'ADVANCE_INVOICE' && (
        <Snackbar
          sx={{ mt: '3rem' }}
          open={openAlert}
          onClose={handleCloseMessage}
          autoHideDuration={2000}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
          message='You need to generate an advance invoice !'
        />
      )}
    </Card>
  )
}

export default BillingTable
