// ** React Imports
import React, { useState, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'
import FilterIcon from 'mdi-material-ui/FilterVariant'
import CheckIcon from 'mdi-material-ui/CheckCircleOutline'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import ChevronUp from 'mdi-material-ui/ChevronUp'

// ** Component Imports
import ActionButtons from './ActionButtons'
import moment from 'moment'
import SubscriptionCardTable from './cards/SubscriptionCardTable'
import EmptySubCard from './cards/EmptySubCard'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, fetchBoardedCustomers } from 'store/customers'

// ** Thir party libraries
import { useDebouncedCallback } from 'use-debounce'
import AlertMessages from 'pages/Billing/components/AlertMessages'
import { useGetBoardedCustomersQuery } from 'store/customers/apiCaching'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))

// Styled Img component
const Img = styled('img')(({ theme }) => ({
  height: 20,
  width: 20,
  marginLeft: 5
}))

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

// Icons paths and names
const icons = [
  { name: 'workspace', path: '/google_workspace.svg' },
  { name: 'cloud identity', path: '/cloud-identity.svg' },
  { name: 'chrome', path: '/chrome.svg' },
  { name: 'meet', path: '/meet.svg' },
  { name: 'voice', path: '/voice.svg' },
  { name: 'vault', path: '/vault.png' },
  { name: 'drive', path: '/drive.svg' },
  { name: 'cloud platform', path: '/cloud-platform.svg' }
]

const Row = props => {
  // ** Hooks
  const navigate = useNavigate()

  // ** Props
  const { row, open, key } = props

  // ** Initialize Contact
  let contact = {}

  // ** Redirect to customer display
  const redirectHandler = id => {
    navigate('/customers/customer-display/' + id)
  }

  const openRowHandler = id => {
    if (open == id) {
      props.setOpen(null)
    } else {
      props.setOpen(id)
    }
  }

  // ** Functions to remove duplicate sub name
  // Spliting the product Name to take only the commons words for more precision
  const removeDuplicates = () => {
    let newArr = []
    row.googleIds?.map(el =>
      el.subscriptions?.forEach(item => {
        if (
          newArr.some(
            el =>
              el.product?.productName?.split(' ').slice(0, 2).join(' ') ==
              item.product?.productName?.split(' ').slice(0, 2).join(' ')
          ) === false
        ) {
          newArr.push(item)
        }
      })
    )
    return newArr
  }

  // Render Icon by name
  const renderIcon = sub => {
    // access product name in product entity if there is no product then replace the name with skuname
    let name = sub?.product ? sub?.product?.productName?.toLowerCase() : sub?.skuName?.toLowerCase()
    let icon = icons.find(icon => name?.includes(icon?.name) || icon.name.includes('workspace'))
    if (icon !== undefined) return <Img alt={name} src={`/google-products${icon.path}`} />
    else return null
  }
  // Return Active subs
  const activeSubs = () => {
    let active = 0
    row.googleIds?.map(el =>
      el?.subscriptions?.forEach(sub => {
        if (sub?.status == 'ACTIVE' && !sub?.is_removed) active++
      })
    )
    return active
  }
  // Return Suspended subs
  const suspendedSubs = () => {
    let suspended = 0
    row.googleIds?.map(el =>
      el?.subscriptions?.forEach(sub => {
        if (sub?.status == 'SUSPENDED' && !sub?.is_removed) suspended++
      })
    )
    return suspended
  }

  if (row != undefined) {
    if (row?.contacts?.length > 0)
      contact = row.contacts?.reduce((oldest, current) => (current.createdAt < oldest.createdAt ? current : oldest))
  }

  return (
    <Fragment key={row?.id}>
      <TableRow sx={{ '& > *': {}, cursor: 'pointer' }} onClick={() => openRowHandler(row?.id)}>
        <TableCell component='th' scope='row' sx={{ color: 'common.blue' }} onClick={() => redirectHandler(row?.id)}>
          {row?.organization || '----'}
        </TableCell>
        <TableCell align='right'>{row.freshbooksId || '----'}</TableCell>
        <TableCell align='right'>{row.domain || '----'}</TableCell>
        <TableCell align='right'>
          {suspendedSubs() !== 0 ? suspendedSubs() + ' Suspended ' : ''}
          {activeSubs() !== 0 ? activeSubs() + ' Active' : ''}
        </TableCell>
        <TableCell align='right'>{moment(row.createdAt).format('MMM DD, YYYY')}</TableCell>
        <TableCell align='right'>{removeDuplicates()?.map((sub, index) => renderIcon(sub))}</TableCell>
        <TableCell align='right'>{open ? <ChevronUp /> : <ChevronDown />}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={8} sx={{ py: '0 !important' }}>
          <Collapse in={open == row.id ? true : false} timeout='auto' unmountOnExit>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <EmptySubCard />
              {row.googleIds?.map(el =>
                el?.subscriptions
                  ?.filter(item => !item.is_removed)
                  ?.map((sub, index) => (
                    <SubscriptionCardTable key={index} row={row} sub={sub} redirectHandler={redirectHandler} />
                  ))
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const TableCollapsible = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** State
  const [open, setOpen] = useState(null)
  const [paginationFilters, setPaginationFilters] = useState({
    limit: 20,
    page: 1,
    organization: '',
    billingDay: '',
    freshbooksId: ''
  })

  // ** Selectors
  //   const { boardedCustomers, pagination, success, error, loading } = useSelector(customersSelector)

  // ** Queries Cache
  const {
    data: boardedCustomers,
    iSuccess,
    isError,
    isLoading,
    isFetching
  } = useGetBoardedCustomersQuery(paginationFilters)

  // ** Fetch customers
  //   useEffect(() => {
  //     //  dispatch(fetchBoardedCustomers(paginationFilters))
  //   }, [paginationFilters])

  // ** Functions
  const handleChangeRowsPerPage = event => {
    setPaginationFilters({
      ...paginationFilters,
      limit: event.target.value
    })
  }
  const handleChangePage = (event, newPage) => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPaginationFilters({
      ...paginationFilters,
      page: ++newPage
    })
  }

  const debounced = useDebouncedCallback(
    event => {
      const { value } = event.target
      setPaginationFilters({
        ...paginationFilters,
        organization: value
      })
    },
    400,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <Card
      sx={{
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader
        sx={{ mb: '-1rem' }}
        title={<Typography sx={{ fontFamily: 'Google Sans' }}>Customers</Typography>}
        action={<ActionButtons />}
      />
      <StyledDivider />
      <Box sx={{ mt: '1rem', px: 5, display: 'flex' }}>
        <TextField
          placeholder='Search customer or domain'
          size='small'
          onChange={debounced}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ color: 'common.blue' }} />
              </InputAdornment>
            )
          }}
        />
        <Stack direction='row' alignItems='center' sx={{ color: 'common.blue' }}>
          <IconButton>
            <FilterIcon />
          </IconButton>
          <Typography variant='body2'>Add filter</Typography>
        </Stack>
      </Box>
      <StyledDivider />
      <Box sx={{ position: 'relative' }}>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell>Organization</TableCell>
                <TableCell align='right'>Freshbooks ID</TableCell>
                <TableCell align='right'>Domain</TableCell>
                <TableCell align='right'>Subscriptions</TableCell>
                <TableCell align='right'>Onboarding Date</TableCell>
                <TableCell align='right'>Products</TableCell>
                <TableCell align='right'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? null
                : boardedCustomers?.data?.map(row => <Row key={row.id} row={row} setOpen={setOpen} open={open} />)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component='div'
          count={boardedCustomers?.total || 0}
          rowsPerPage={boardedCustomers?.limit || 0}
          page={(boardedCustomers?.currentPage || 0) - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Backdrop
          open={isFetching}
          sx={{
            position: 'absolute',
            color: theme => theme.palette.common.blue,
            zIndex: theme => theme.zIndex.mobileStepper - 1,
            '&.MuiBackdrop-root': {
              backgroundColor: 'common.white'
            }
          }}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </Box>
      {/* <AlertMessages
        success={success}
        error={error}
        messageValue='CREATE_CUSTOMER_GOOGLE_FAIL'
        message="Onboarding successfully done with Google status 'forbidden'"
      /> */}
    </Card>
  )
}

export default TableCollapsible
