import { createSlice } from '@reduxjs/toolkit'

// ** API Imports
import { createMetadata, editMetadata, getMetaDataData } from 'configs/axios/api_helper'

export const initialState = {
  loading: null,
  engagementTypes: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const engagementTypeSlice = createSlice({
  name: 'engagementTypeData',
  initialState,
  reducers: {
    addEngagementType: (state, action) => {
      state.engagementTypes.push(action.payload)
      ;(state.success = 'CREATE'), (state.loading = null)
    },
    updateEngagementType: (state, action) => {
      state.engagementTypes = state.engagementTypes.map(tax =>
        tax.id.toString() === action.payload.id.toString() ? { tax, ...action.payload } : tax
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removeEngagementType: (state, action) => {
      state.engagementTypes = state.engagementTypes.filter(tax => tax.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getEngagementTypeDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.engagementTypes = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getEngagementTypeDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addEngagementType,
  updateEngagementType,
  removeEngagementType,
  startLoading,
  resetMessages,
  getEngagementTypeDataFailure,
  getEngagementTypeDataSuccess
} = engagementTypeSlice.actions

// export user selector to get the slice in any component
export const engagementTypeSelector = state => state.engagementTypeData

// export The reducer
const engagementTypeReducer = engagementTypeSlice.reducer
export default engagementTypeReducer

// Actions
export const fetchEngagementTypeData = () => async dispatch => {
  try {
    dispatch(startLoading('GET'))
    const { data } = await getMetaDataData('type/engagementType')
    dispatch(getEngagementTypeDataSuccess(data ?? []))
  } catch (error) {
    dispatch(getEngagementTypeDataFailure())
  }
}

export const createEngagementType = tax => async dispatch => {
  try {
    dispatch(startLoading('CREATE'))
    const data = await createMetadata({ ...tax, type: 'engagementType' })
    dispatch(addEngagementType(data))
  } catch (error) {
    dispatch(getEngagementTypeDataFailure())
  }
}

export const deleteEngagementType = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE'))
    await editMetadata(id, { status: 'archived' })
    dispatch(removeEngagementType(id))
  } catch (error) {
    dispatch(getEngagementTypeDataFailure())
  }
}

export const editEngagementType = tax => async dispatch => {
  try {
    const { id, ...rest } = tax
    dispatch(startLoading('UPDATE'))
    await editMetadata(id, { ...rest })
    dispatch(updateEngagementType(tax))
  } catch (error) {
    dispatch(getEngagementTypeDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
