// ** React Imports
import React, { useState, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'

// ** Custom Components
import AddNewCustomer from '../buttons/AddNewCustomer'

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'
import FilterIcon from 'mdi-material-ui/FilterVariant'
import CheckIcon from 'mdi-material-ui/CheckCircleOutline'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, fetchCustomersOnboarding } from 'store/customers'

// ** 3rd Part Libraries
import moment from 'moment'
import { useDebouncedCallback } from "use-debounce"
import SynchronizeCustomers from '../buttons/SynchronizeCustomers'

// ** Component Imports


// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    border: `solid 1px ${theme.palette.grey[300]}`
}))

// Styled Img component
const Img = styled("img")(({ theme }) => ({
    height: 20,
    width: 20,
    marginLeft: 5
}))

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
    borderBottomWidth: 1,
    color: theme.palette.grey[300],
}))



const Row = props => {

    // ** Hooks
    const navigate = useNavigate()
    // ** Props
    const { row } = props

    // ** Redirect to customer display
    const redirectHandler = (id) => {
        navigate("/google-customers/customer-display/" + id)
    }

    return (
        <Fragment>
            <TableRow >
                <TableCell component='th' scope='row' sx={{ color: 'common.blue', cursor: "pointer" }} onClick={() => redirectHandler(row.id)}>
                    {row.customerId}
                </TableCell>
                <TableCell align='right'>{row.domain}</TableCell>
                <TableCell align='right'>{row.organization}</TableCell>
                <TableCell align='right'>{row.phone || "------"}</TableCell>
                <TableCell align='right'>{((row.locality && row.locality + ' /') || "") + " " + (row.countryCode || "------")}</TableCell>
                <TableCell align='right'>{moment(row.createdAt).format("MMM DD, YYYY")}</TableCell>
            </TableRow>
        </Fragment>
    )
}


const CustomersOnboardingTable = () => {

    // ** Hooks
    const dispatch = useDispatch()

    // ** State
    const [paginationFilters, setPaginationFilters] = useState({
        limit: 20,
        page: 1,
        field: ""
    })

    // ** Selectors
    const { customersOnboarding, pagination, success, error, loading } = useSelector(customersSelector);


    // ** Fetch Tax data
    useEffect(() => {
        dispatch(fetchCustomersOnboarding(paginationFilters));
    }, [paginationFilters]);

    // ** Functions
    const handleChangeRowsPerPage = (event) => {
        setPaginationFilters({
            ...paginationFilters,
            limit: event.target.value
        })
    }
    const handleChangePage = (event, newPage) => {
        // Increment Page if next button Clicked and there is nextPage (returned from the api)
        setPaginationFilters({
            ...paginationFilters,
            page: ++newPage
        })
    }

    const debounced = useDebouncedCallback(
        (event) => {
            const { value } = event.target;
            setPaginationFilters({
                ...paginationFilters,
                field: value
            });
        },
        400,
        // The maximum time func is allowed to be delayed before it's invoked:
        { maxWait: 3000 }
    );



    return (
        <StyledCard
            sx={{
                position: "relative",
                '& .customer-column-header': {
                    backgroundColor: 'common.white',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600
                    }
                },
            }}>
            <CardHeader
                title={<TextField
                    placeholder="Search customer, domain or google ID"
                    size="small"
                    onChange={debounced}
                    sx={{ width: 450 }}
                    InputProps={{
                        startAdornment: <InputAdornment position='start'><SearchIcon sx={{ color: 'common.blue' }} /></InputAdornment>
                    }}
                />}
                action={
                    <Box display="flex" gap="30px">
                        <SynchronizeCustomers loading={loading} pagination={{ limit: paginationFilters.limit, page: paginationFilters.page, field: '' }} />
                        <AddNewCustomer />
                    </Box>
                }
            />
            <Box sx={{ position: 'relative' }}>
                <TableContainer component={Paper} >
                    <Table aria-label='collapsible table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Customer ID</TableCell>
                                <TableCell align='right'>Domain</TableCell>
                                <TableCell align='right'>Organization</TableCell>
                                <TableCell align='right'>Phone</TableCell>
                                <TableCell align='right'>Locality / Country code</TableCell>
                                <TableCell align='right'>Creation Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customersOnboarding?.map(row => (
                                <Row key={row.id} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component='div'
                    count={pagination.total}
                    rowsPerPage={pagination.limit}
                    page={pagination.currentPage - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Backdrop
                    open={loading == "GET"}
                    sx={{
                        position: 'absolute',
                        color: theme => theme.palette.common.blue,
                        zIndex: theme => theme.zIndex.mobileStepper - 1,
                        "&.MuiBackdrop-root": {
                            backgroundColor: "common.white"
                        }
                    }}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
            </Box>

        </StyledCard>

    )
}

export default CustomersOnboardingTable
