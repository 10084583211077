// ** React Imports
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// ** MUI Components
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiCard from '@mui/material/Card'
import Typography from '@mui/material/Typography'

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  zIndex: 1,
  display: 'block',
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  height: 'auto',
  padding: '2.5rem'
}))

const PrivacyPolicy = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' my={20}>
      <Typography sx={{ fontSize: '3.5rem', color: '#414141', fontWeight: 400 }}>Privacy Policy</Typography>
      <Box display='flex' flexDirection='column' alignItems='start' justifyContent='center' gap={5} mt={10} width={750}>
        <Typography sx={{ color: '#414141' }}>
          <span style={{ fontWeight: 600 }}>Premier Cloud Inc.</span> (“Premier Cloud”) has adopted the following
          privacy policy (“Privacy Policy”), which is applicable to all personal information (e.g., your name, address,
          phone number, e-mail address) you provide during your use of
          <span style={{ color: '#1A73E8', textDecoration: 'undelrine' }}>www.premiercloud.com</span> (referred to as
          the “Site”).
        </Typography>
        <Typography sx={{ fontWeight: 700, color: 'black', fontSize: '2rem' }}>
          Our Commitment to your Privacy
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          Premier Cloud is committed to protecting your privacy and ensuring that your visit to our website is
          completely secure. If you have any questions or problems with any aspect of our privacy policy or its
          implementation, please contact our privacy officer.
        </Typography>
        <Typography sx={{ fontWeight: 700, color: 'black', fontSize: '2rem' }}>Consent</Typography>
        <Typography sx={{ color: '#414141' }}>
          By using this Site, you consent to our use of your information as described in this Privacy Policy. As we
          implement new technology and introduce new services, we will update our Privacy Policy. We reserve the right
          to change our Privacy Policy at any time without advance notice. Should any new privacy policy go into effect,
          we will post it on this site, and the policy will apply to all information collected.
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          Information gathered through
          <span style={{ textDecoration: 'underline', color: '#1A73E8' }}>www.premiercloud.com</span> may be used to
          send you special updates, new products or services, or changes to this privacy policy. We do not sell or rent
          this information to anyone. You may opt-out of future communication from us at any time by either clicking the
          unsubscribe link or by contacting us via the email address or phone number given on our website.
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          This website periodically uses the Google AdWords remarketing service to advertise on third party websites
          (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who
          haven’t completed a task on our site, for example using the contact form to make an inquiry. This could be in
          the form of an advertisement on the Google search results page, or a site in the Google Display Network.
        </Typography>
        <Typography sx={{ fontWeight: 700, color: 'black', fontSize: '2rem' }}>
          Security of your Personal Information
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          may be used to To maintain the security of your information, we use the Transport Layer Security (TLS)
          protocol with 128-bit or higher encryption strength to transmit sensitive information. This is the same
          technology used in transmitting sensitive information by banks, governments, and online businesses such as
          <span style={{ textDecoration: 'underline', color: '#1A73E8' }}> Amazon.com </span> and eBay.
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          Once the information is in our system, it is accessible to authorized Premier Cloud personnel only.
        </Typography>
        <Typography sx={{ fontWeight: 700, color: 'black', fontSize: '2rem' }}>
          How we use your Personal Information
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          We will NOT sell, trade or rent your name or personal information to anyone else. We DO NOT sell, trade, rent
          or provide outside access to our mailing list at all.
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          Premier Cloud will not release your personal information to authorities unless required by law, search
          warrant, court order, subpoena, or fraud investigation.
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          Except as otherwise stated in this policy, Premier Cloud will share your information with third parties only
          to the extent necessary to provide the services and products requested by you. All such third parties are
          prohibited from using your personal information except to provide these services and they are required to
          maintain the confidentiality of your information.
        </Typography>
        <Typography sx={{ fontWeight: 700, color: 'black', fontSize: '2rem' }}>
          Collection of your Personal Information
        </Typography>
        <Typography sx={{ color: '#414141' }}>
          Premier Cloud only saves such personal information that is necessary for you to access and use our services.
          This personal information includes, but is not limited to, first and last name, email address, phone number,
          and document creation data.
        </Typography>
        <Typography sx={{ fontWeight: 700, color: 'black', fontSize: '2rem' }}>Cookies</Typography>
        <Typography sx={{ color: '#414141' }}>
          You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access
          cookies. If you disable or refuse cookies some parts of Premier Cloud websites may become inaccessible or not
          function properly.
        </Typography>
        <Typography sx={{ color: '#414141', fontWeight: 700 }}>We use cookies in the following ways:</Typography>
        <ul>
          <li style={{ color: '#414141', marginBottom: 10 }}>
            In order to personalize your experience with the Site by displaying content we believe might interest you
            based on your information.
          </li>
          <li style={{ color: '#414141', marginBottom: 10 }}>
            Each browser accessing the Site is given a unique cookie which is then used to determine the size of our
            audience and the extent of repeat usage.
          </li>
          <li style={{ color: '#414141', marginBottom: 10 }}>
            To track visitor trends and patterns. This enables us to provide our visitors with more useful content and
            services.
          </li>
        </ul>
        <Typography sx={{ fontWeight: 700, color: 'black', fontSize: '2rem' }}>Questions</Typography>
        <Typography sx={{ color: '#414141' }}>
          If you have any questions about this Privacy Policy, please submit your questions by emailing
          <span style={{ textDecoration: 'underline', color: '#1A73E8' }}>info@premiercloud.com.</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default PrivacyPolicy
