// ** Axios
import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** API Imports
import { createMetadata, editMetadata, getMetaDataData, getTaxData } from 'configs/axios/api_helper'

export const initialState = {
  loading: false,
  currenciesData: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const currencySlice = createSlice({
  name: 'currencyData',
  initialState,
  reducers: {
    addCurrency: (state, action) => {
      state.currenciesData.push(action.payload)
      ;(state.success = 'CREATE'), (state.loading = false)
    },
    updateCurrency: (state, action) => {
      state.currenciesData = state.currenciesData.map(currency =>
        currency.id.toString() === action.payload.id.toString() ? { currency, ...action.payload } : currency
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removeCurrency: (state, action) => {
      state.currenciesData = state.currenciesData.filter(currency => currency.id !== action.payload)
      state.success = 'DELETE'
      state.loading = false
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getCurrencyDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.currenciesData = data
      state.loading = false
      state.pagination = filters
      state.success = 'GET'
    },
    getCurrencyDataFailure: state => {
      state.loading = false
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addCurrency,
  updateCurrency,
  removeCurrency,
  startLoading,
  resetMessages,
  getCurrencyDataFailure,
  getCurrencyDataSuccess
} = currencySlice.actions

// export user selector to get the slice in any component
export const currencySelector = state => state.currenciesData

// export The reducer
const currenciesReducer = currencySlice.reducer
export default currenciesReducer

// Actions
export const fetchCurrencyData = () => async dispatch => {
  try {
    dispatch(startLoading('GET'))
    const { data } = await getMetaDataData('type/currency')
    dispatch(getCurrencyDataSuccess(data))
  } catch (error) {
    dispatch(getCurrencyDataFailure())
  }
}

export const createCurrency = currency => async dispatch => {
  try {
    dispatch(startLoading('CREATE'))
    const data = await createMetadata({ ...currency, type: 'currency' })
    dispatch(addCurrency(data))
  } catch (error) {
    dispatch(getCurrencyDataFailure())
  }
}

export const deleteCurrency = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE'))
    const data = await editMetadata(id, { status: 'archived' })
    dispatch(removeCurrency(id))
  } catch (error) {
    dispatch(getCurrencyDataFailure())
  }
}

export const editCurrency = currency => async dispatch => {
  try {
    const { id, ...rest } = currency
    dispatch(startLoading('UPDATE'))
    const data = await editMetadata(id, { ...rest })
    dispatch(updateCurrency(currency))
  } catch (error) {
    dispatch(getCurrencyDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
