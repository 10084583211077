// ** React Imports
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'

// ** Icon Imports
import Send from 'mdi-material-ui/FileSendOutline'
import RefreshIcon from 'mdi-material-ui/Refresh'

// ** 3rd party Libraries
import moment from 'moment'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  customersSelector,
  getCustomerInvoice,
  sendInvoiceToFreshbooks,
  updateInvoiceFromFreshbooks
} from 'store/customers'

// ** Custom Components
import TransactionsTable from './TransactionsTable'
import AlertMessages from './AlertMessages'
import DeleteInvoiceModal from './DeleteInvoiceModal'
import { currencySelector } from 'store/metaData/currency'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: '-0.5rem',
  borderBottomWidth: 2,
  color: theme.palette.grey[300]
}))

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: '0.5rem',
  border: `solid 1px ${theme.palette.grey[300]}`,
  paddingLeft: 3,
  paddingRight: 3,
  '&:hover': {
    boxShadow: theme.shadows[8]
  }
}))

const Transaction = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()
  // ** Props
  const { invoice, active, setActive } = props

  // ** State
  const [collapsed, setCollapsed] = useState(false)
  const [inAdvanceFormattedTable, setInAdvanceFormattedTable] = useState([])

  // ** Selectors
  const { invoiceInformation, success, error, loading, customerInformation } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** Fetch customer invoice
  useEffect(() => {
    if (active !== invoice?.id) setCollapsed(false)
  }, [active])

  // ** Functions
  const renderTransactionDate = () => {
    const start = moment(invoice?.issue_date, 'YYYY-MM-DD')
    const end = moment(invoice?.due_date, 'YYYY-MM-DD')
    if (start.format('Y') !== end.format('Y')) {
      return moment(start).format('MMM DD, YYYY') + ' - ' + moment(end).format('MMM DD, YYYY')
    } else if (start.format('D') == end.format('D') && start.format('M') == end.format('M')) {
      return moment(start).format('MMM DD, YYYY')
    } else if (start.format('D') != end.format('D') && start.format('M') == end.format('M')) {
      return moment(start).format('MMM DD') + ' - ' + moment(end).format('DD, YYYY')
    } else if (start.format('M') != end.format('M')) {
      return moment(start).format('MMM DD') + ' - ' + moment(end).format('MMM DD, YYYY')
    } else return ''
  }

  const sendToFreshbooksHandler = e => {
    e.stopPropagation()
    const currencyId =
      customerInformation?.currency?.id || currenciesData?.find(item => item.iso_code === 'CAD')?.id || null
    const currencySymbol = customerInformation?.currency?.iso_code === 'EUR' ? '€' : '$'
    dispatch(
      sendInvoiceToFreshbooks(
        invoiceInformation,
        customerInformation,
        currencyId,
        invoiceInformation?.type,
        inAdvanceFormattedTable
      )
    )
  }

  const openCardHandler = () => {
    setActive(collapsed ? null : invoice?.id)
    setCollapsed(!collapsed)
    if (!collapsed && invoice?.id !== invoiceInformation?.id)
      dispatch(getCustomerInvoice({ customerId: id, invoiceId: invoice?.id, show_gcp_usage: customerInformation?.show_gcp_usage }))
  }

  const refreshInvoiceHandler = event => {
    event.stopPropagation()
    if (!!invoiceInformation?.freshbook?.freshbooksInvoiceId) {
      const {
        customer_id,
        id,
        freshbook: { freshbooksInvoiceId }
      } = invoiceInformation
      dispatch(
        updateInvoiceFromFreshbooks({
          customerId: customer_id,
          invoiceId: id,
          freshbooksInvoiceId: freshbooksInvoiceId
        })
      )
    }
  }

  return (
    <StyledCard
      sx={{
        position: 'relative',
        ...(collapsed && {
          border: 0,
          '&:hover': {
            boxShadow: 0
          }
        })
      }}
    >
      <CardHeader
        sx={{
          cursor: 'pointer'
        }}
        onClick={openCardHandler}
        title={
          !collapsed ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.875rem' }}>{renderTransactionDate()}</Typography>
            </Box>
          ) : (
            <Stack direction='row' alignItems='center' gap={6}>
              <Typography sx={{ fontSize: '1.25rem', fontWeight: 550 }}>{renderTransactionDate()}</Typography>
              {!!invoiceInformation.freshbook?.freshbooksInvoiceId && (
                <Tooltip title='Refresh invoice from Freshbooks'>
                  <RefreshIcon
                    onClick={refreshInvoiceHandler}
                    fontSize='small'
                    sx={{
                      verticalAlign: 'bottom',
                      color: `${
                        !!invoiceInformation?.freshbook?.freshbooksInvoiceId ? 'common.blue' : 'text.secondary'
                      }`
                    }}
                  />
                </Tooltip>
              )}
              {/* <Grid item xs={12} sx={{ textAlign: 'end', mt: "3rem" }}> */}
              <Button
                disabled={invoiceInformation?.freshbook?.freshbooksInvoiceId}
                onClick={sendToFreshbooksHandler}
                endIcon={
                  <Send
                    sx={{
                      color: `${!invoiceInformation?.freshbook?.freshbooksInvoiceId ? 'common.blue' : 'text.secondary'}`
                    }}
                  />
                }
                size='small'
                variant='text'
                sx={{
                  color: `${!invoiceInformation?.freshbook?.freshbooksInvoiceId ? 'common.blue' : 'text.secondary'}`,
                  ml: 'auto',
                  '&:disabled': { color: theme => theme.palette.grey[700] }
                }}
              >
                {!invoiceInformation?.freshbook?.freshbooksInvoiceId ? 'Send to Freshbooks' : 'Sent to Freshbooks'}
              </Button>
              {/* </Grid> */}
            </Stack>
          )
        }
        action={
          !collapsed && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.825rem' }}>
                {invoiceInformation?.id === invoice.id ? invoiceInformation?.status : invoice?.status}
              </Typography>
              {<DeleteInvoiceModal invoice={invoice} customerId={customerInformation?.id} />}
            </Box>
          )
        }
      />
      <Collapse in={collapsed} open={collapsed}>
        <StyledDivider />
        <Grid container spacing={2}>
          {!!invoiceInformation.freshbook?.freshbooksInvoiceId && (
            <Grid item xs={12} sx={{ py: '1rem !important', px: '3rem !important', backgroundColor: '#f5f5f5' }}>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2, fontWeight: 600 }}>
                Freshbooks Invoice Number :{' '}
                {invoiceInformation?.freshbook?.freshbooksUrl ? (
                  <a href={invoiceInformation?.freshbook?.freshbooksUrl} target='_blank' rel='noreferrer'>
                    {invoiceInformation?.freshbook?.freshbooksInvoiceNumber}
                  </a>
                ) : (
                  <>{invoiceInformation?.freshbook?.freshbooksInvoiceNumber}</>
                )}
              </Typography>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2, fontWeight: 600 }}>
                Due Date : {moment(invoiceInformation?.freshbook?.dueDate, 'YYYY-MM-DD').format('MMM D, YYYY')}
              </Typography>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2, fontWeight: 600 }}>
                Invoice Status : {invoiceInformation?.freshbook?.freshbooksInvoiceStatus ?? '----'}
              </Typography>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2, fontWeight: 600 }}>
                Payment Status : {invoiceInformation?.freshbook?.paymentStatus ?? '----'}
              </Typography>
            </Grid>
          )}
          {invoiceInformation?.items?.length > 0 && (
            <TransactionsTable
              invoiceDetails={invoiceInformation?.items}
              type={invoiceInformation?.type}
              issueDate={invoiceInformation?.issue_date}
              setInAdvanceFormattedTable={setInAdvanceFormattedTable}
            />
          )}
          {invoiceInformation?.items?.length < 1 && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography>There is no subscriptions</Typography>
            </Grid>
          )}
        </Grid>
        <Backdrop
          open={
            loading == 'REFRESH_INVOICE' ||
            loading == 'SEND_INVOICE' ||
            (loading === 'GET_INVOICE' && invoiceInformation?.id !== invoice?.id)
          }
          sx={{
            position: 'absolute',
            backgroundColor: 'transparent',
            backdropFilter: 'blur(5px)',
            color: theme => theme.palette.common.white,
            zIndex: theme => theme.zIndex.mobileStepper - 1
          }}
        >
          <CircularProgress color='primary' size='4rem' />
        </Backdrop>
      </Collapse>

      <AlertMessages
        success={success}
        error={error}
        messageValue={
          (success == 'SEND_INVOICE' && 'SEND_INVOICE') ||
          (success == 'REMOVE_INVOICE' && 'REMOVE_INVOICE') ||
          (success == 'REFRESH_INVOICE' && 'REFRESH_INVOICE')
        }
        message={
          (success == 'SEND_INVOICE' && 'Invoice was sent successfully to Freshbooks !') ||
          (success == 'REMOVE_INVOICE' && 'Invoice was deleted successfully !') ||
          (success == 'REFRESH_INVOICE' && 'Invoice was updated successfully !')
        }
      />
    </StyledCard>
  )
}

export default Transaction
