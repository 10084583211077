// ** React Imports
import React, { forwardRef, Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'

// ** APIs
import { seedCustomerSubscriptions } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Snackbar from '@mui/material/Snackbar'
import CircularProgress from '@mui/material/CircularProgress'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// ** Third Party imports
import moment from 'moment'
import { Locale } from 'date-fns'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const SynchronizeBilling = props => {
  // ** Hooks
  const { id } = useParams()

  // ** Props
  // ** State
  const [open, setOpen] = useState(false)
  const [endDate, setEndDate] = useState(new Date().setDate(new Date().getDate() + 1))
  const [startDate, setStartDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, message: '' })

  // ** Fucntions
  const handleClickOpen = () => setOpen(true)
  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick' && 'escapeKeyDown') return
    setOpen(false)
  }

  const closeHandler = () => {
    setOpen(false)
    setMessage({ status: '', invoice: {}, item: {}, loading: false })
  }
  const handleCloseAlert = () => {
    setMessage({ success: false, error: false, message: '' })
  }
  const generateInvoiceHandler = async () => {
    setLoading(true)
    const query = await seedCustomerSubscriptions(
      `?from=${moment(startDate).format('YYYY-MM-DD')}&to=${moment(endDate).format('YYYY-MM-DD')}&customerId=${id}`
    )
    if (query?.status === 200) {
      setLoading(false)
      setMessage({
        success: true,
        error: false,
        message: 'Synchronizing this customer billing was done successfully !'
      })
      setOpen(false)
    } else if (query.response.status !== 200) {
      console.log('error: ', query.response.data.message)
      setLoading(false)
      setMessage({ success: false, error: true, message: 'AN error has occured, please try again.' })
    }
  }

  return (
    <Fragment>
      <Button
        variant='contained'
        onClick={handleClickOpen}
        sx={{
          ml: 'auto',
          boxShadow: 0,
          '&:disabled': {
            color: theme => theme.palette.grey[700]
          }
        }}
      >
        Synchronize usage
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              minWidth: '40%' // Set your width here
            }
          }
        }}
      >
        <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Synchronize customer billing</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6} mt={1}>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label='Start date'
                  inputFormat='MM/DD/YYYY'
                  value={startDate}
                  maxDate={endDate}
                  onChange={newValue => setStartDate(newValue)}
                  sx={{ width: '100%' }}
                  slotProps={{ textField: { size: 'small' } }}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label='End date'
                  inputFormat='MM/DD/YYYY'
                  value={endDate}
                  minDate={startDate}
                  onChange={newValue => setEndDate(newValue)}
                  sx={{ width: '100%' }}
                  slotProps={{ textField: { size: 'small' } }}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button
            sx={{
              color: 'text.secondary',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.white} !important`,
                color: 'text.secondary'
              }
            }}
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            sx={{
              backgroundColor: 'common.blue',
              color: 'common.white',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.blue} !important`,
                color: 'common.white'
              },
              '&:disabled': {
                backgroundColor: theme => theme.palette.grey[700],
                borderColor: theme => theme.palette.grey[700]
              }
            }}
            onClick={generateInvoiceHandler}
            disabled={loading}
            endIcon={loading && <CircularProgress size='1rem' sx={{ color: '#fff' }} />}
          >
            Synchronize
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={message.message}
        onClose={handleCloseAlert}
        message={message.message}
        autoHideDuration={1500}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Fragment>
  )
}

export default SynchronizeBilling
