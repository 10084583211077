// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import InputAdornment from '@mui/material/InputAdornment'
import { DataGrid } from '@mui/x-data-grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// ** Redux Imports
import { customersSelector, getCustomersUsages } from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

// ** Icon Imports
import SearchIcon from 'mdi-material-ui/Magnify'

const BillingComparator = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** State
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month'))
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  // ** Selectors
  const { customersGoogleUsagesCompare, pagination, loading, error } = useSelector(customersSelector)

  // ** Get customer invoices
  useEffect(() => {
    dispatch(
      getCustomersUsages({
        from: startDate.startOf('month').format('YYYY-MM-DD'),
        to: startDate.endOf('month').format('YYYY-MM-DD'),
        limit,
        page,
        search
      })
    )
  }, [startDate, page, limit, search])

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'organization',
      headerName: 'Organization',
      renderCell: params => (
        <Typography
          noWrap
          variant='body2'
          sx={{ color: 'common.blue', cursor: 'pointer' }}
          onClick={() => window.open('/customers/customer-display/' + params.row.id)}
        >
          {params.row.organization}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'invoiceHistoryData',
      headerName: 'Premier Cloud transations',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          ${params.row.totalInvoicesHistory}
        </Typography>
      )
    },

    {
      flex: 0.125,
      minWidth: 250,
      field: 'invoiceHistoryDataDiscounted',
      headerName: 'Premier Cloud transactions (-20%)',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          ${(params.row.totalInvoicesHistory * 0.8).toFixed(2)}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'googleUsageData',
      headerName: 'Google transactions',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          ${params.row.totalGoogleUsage}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'difference',
      headerName: 'Difference',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {((params.row.totalGoogleUsage || 0) - (params.row.totalInvoicesHistory || 0) * 0.8).toFixed(2)}
        </Typography>
      )
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: 'currency',
      headerName: 'Currency',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          Candian
        </Typography>
      )
    }
  ]

  // ** Functions
  const handleChangePage = newPage => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPage(++newPage)
  }

  const handleChangeRowsPerPage = rows => {
    setPage(1)
    setLimit(rows)
  }

  const debounced = useDebouncedCallback(
    event => {
      const { value } = event.target
      setSearch(value)
    },
    400,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Billing Reconciliation</Typography>
            <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>| Compare customers transactions</Typography>
          </Box>
        }
      />
      <Box p={5} display='flex' alignItems='center' gap={5}>
        <TextField
          placeholder='Search for organization'
          size='small'
          onChange={debounced}
          sx={{ width: 350 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ color: 'common.blue' }} />
              </InputAdornment>
            )
          }}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box width={350}>
            <DesktopDatePicker
              label='Start date'
              inputFormat='MM/DD/YYYY'
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
              sx={{ width: '100%' }}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={params => <TextField {...params} />}
              views={['year', 'month']}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={loading ? [] : customersGoogleUsagesCompare || []}
        getRowId={row => row.id}
        columns={columns}
        loading={loading}
        pagination
        pageSize={limit || 0}
        rowCount={pagination?.total || 0}
        page={pagination?.currentPage ? pagination?.currentPage - 1 : 0}
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default BillingComparator
