// ** React Imports
import React, { useState, Fragment, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import IconButton from '@mui/material/IconButton'

// ** Custom Components
import AddNewSub from '../components/AddNewSub'

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'
import EditIcon from 'mdi-material-ui/PencilOutline'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** 3rd Part Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'
import { getRulesData, rulesSelector } from 'store/rules'

// ** Component Imports

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))

const Row = props => {
  // ** Hooks
  const navigate = useNavigate()
  // ** Props
  const { row, key } = props

  // ** Redirect to customer display
  const redirectHandler = id => {
    navigate('/commissions/rules/' + id)
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell sx={{ maxWidth: 150 }}>{row.name}</TableCell>
        <TableCell>{row.from ? moment(row.from).format('MMM DD, YYYY') : '------'}</TableCell>
        <TableCell>{row.to ? moment(row.to).format('MMM DD, YYYY') : '------'}</TableCell>
        <TableCell align='left'>{row.status || '------'}</TableCell>
        <TableCell align='left'>{row.updatedAt ? moment(row.updatedAt).format('MMM DD, YYYY') : '------'}</TableCell>
        <TableCell align='center'>
          <IconButton size='small' onClick={() => redirectHandler(row.id)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const RulesList = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** State
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  // ** State
  const [paginationFilters, setPaginationFilters] = useState({
    limit: 20,
    page: 1,
    field: ''
  })
  // ** Selectors
  const { rules, pagination, success, error, loading } = useSelector(rulesSelector)

  // ** Fetch Tax data
  useEffect(() => {
    dispatch(getRulesData())
  }, [])

  // ** Functions
  const handleChangePage = (event, newPage) => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPaginationFilters({
      ...paginationFilters,
      page: ++newPage
    })
  }

  const handleChangeRowsPerPage = event => {
    setPaginationFilters({
      ...paginationFilters,
      limit: event.target.value
    })
  }

  const debounced = useDebouncedCallback(
    event => {
      const { value } = event.target
      setPaginationFilters({
        ...paginationFilters,
        field: value
      })
    },
    400,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <StyledCard
      sx={{
        position: 'relative',
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader
        title={
          <TextField
            placeholder='Search rule name'
            size='small'
            onChange={debounced}
            sx={{ width: 450 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ color: 'common.blue' }} />
                </InputAdornment>
              )
            }}
          />
        }
        action={<AddNewSub />}
      />
      <Box sx={{ position: 'relative' }}>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell>Rule</TableCell>
                <TableCell align='left'>From</TableCell>
                <TableCell align='left'>To</TableCell>
                <TableCell align='left'>Status</TableCell>
                <TableCell align='left'>Last update</TableCell>
                <TableCell align='center'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rules?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(row => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component='div'
          count={pagination.total}
          rowsPerPage={pagination.limit}
          page={pagination.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <Backdrop
          open={loading == 'GET_PRODUCTS'}
          sx={{
            position: 'absolute',
            color: theme => theme.palette.common.blue,
            zIndex: theme => theme.zIndex.mobileStepper - 1,
            '&.MuiBackdrop-root': {
              backgroundColor: 'common.white'
            }
          }}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </Box>
    </StyledCard>
  )
}

export default RulesList
