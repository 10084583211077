// ** React Import
import React, { useEffect, useRef } from 'react'

// ** Layout Components
import VerticalLayout from './VerticalLayout'

const Layout = props => {
    // ** Props
    const { hidden, children, settings, saveSettings } = props

    return <VerticalLayout {...props}>{children}</VerticalLayout>
}

export default Layout
