// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// ** MUI Imports
import { Box, CircularProgress, Typography } from '@mui/material'

// ** Icons Imports
import EventIcon from '@mui/icons-material/Event'
import CreditCardIcon from '@mui/icons-material/CreditCard'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  customersSelector,
  fetchCustomerInvoicesHistory,
  getCustomerGoogleUsages,
  getCustomerInvoices
} from 'store/customers'

// ** Third Party Libraries
import moment from 'moment'
import InvoiceTable from '../tables/InvoiceTable'

// ** Icon Imports
import ArrowLeftIcon from 'mdi-material-ui/ArrowLeft'
import AlertMessages from 'pages/Billing/components/AlertMessages'

// Calculte rows balance
const calculateRowsBalance = items => {
  const subtotalAmount = items
    ?.reduce(
      (acc, { prices, quantity }) =>
        acc + (prices?.find(item => item?.currencyId === 2)?.totalValue || 0) * (quantity || 1),
      0
    )
    ?.toFixed(2)
  return subtotalAmount
}

const renderTransactionDate = (start_date, end_date) => {
  const start = moment(start_date, 'YYYY-MM-DD')
  const end = moment(end_date, 'YYYY-MM-DD')
  if (start.format('Y') !== end.format('Y')) {
    return moment(start).format('MMM DD, YYYY') + ' - ' + moment(end).format('MMM DD, YYYY')
  } else if (start.format('D') == end.format('D') && start.format('M') == end.format('M')) {
    return moment(start).format('MMM DD, YYYY')
  } else if (start.format('D') != end.format('D') && start.format('M') == end.format('M')) {
    return moment(start).format('MMM DD') + ' - ' + moment(end).format('DD, YYYY')
  } else if (start.format('M') != end.format('M')) {
    return moment(start).format('MMM DD') + ' - ' + moment(end).format('MMM DD, YYYY')
  } else return ''
}

function getMonthYearFromDate(dateString) {
  const date = new Date(dateString)
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
}

const CurrenctStand = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()

  // ** State
  const [usage, setUsage] = useState(null)
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  // ** Selectors
  const { customerInformation, customerGoogleUsages, invoicesHistory, loading, error } = useSelector(customersSelector)

  // ** Get customer invoices
  useEffect(() => {
    if (id) {
      const currentDate = moment()
      const startDate = currentDate.clone().subtract(2, 'months').startOf('month').set('date', 1)
      const endDate = currentDate.clone().endOf('day')
      const from = startDate.format('YYYY-MM-DD')
      const to = endDate.format('YYYY-MM-DD')

      // Get the first day of the current month
      const fromMonth = moment().startOf('month').format('YYYY-MM-DD')
      // Get today's date
      const toMonth = moment().format('YYYY-MM-DD')

      const googleUsageStartDate = currentDate
        .clone()
        .subtract(2, 'months')
        .endOf('month')
        .subtract(1, 'month')
        .format('YYYY-MM-DD')
      const googleUsageEndDate = currentDate.clone().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')

      setInvoiceLoading(true)
      dispatch(fetchCustomerInvoicesHistory({ id, from, to, fromMonth, toMonth }))
      dispatch(getCustomerGoogleUsages({ id, from: googleUsageStartDate, to: googleUsageEndDate }))
    }
  }, [id])

  // ** Constants
  const groupedData = customerGoogleUsages?.reduce((result, item) => {
    const startDateMonthYear = getMonthYearFromDate(item.start_date)
    const endDateMonthYear = getMonthYearFromDate(item.end_date)
    const key =
      startDateMonthYear === endDateMonthYear ? startDateMonthYear : `${startDateMonthYear}-${endDateMonthYear}`

    const existingGroup = result.find(group => group.day === item.day)
    if (!existingGroup) {
      result.push({
        day: item.day,
        start_date: item.start_date,
        end_date: item.end_date,
        price: parseInt(item.price),
        items: [item]
      })
    } else {
      existingGroup.start_date = moment
        .min(moment(existingGroup.start_date), moment(item.start_date))
        .format('YYYY-MM-DD')
      existingGroup.end_date = moment.max(moment(existingGroup.end_date), moment(item.end_date)).format('YYYY-MM-DD')
      existingGroup.price += parseInt(item.price)
      existingGroup.items.push(item)
    }

    return result
  }, [])

  return (
    <Fragment>
      {usage ? (
        <Box
          sx={{
            border: theme => `solid 1px ${theme.palette.grey[300]}`,
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 10,
            p: 5
          }}
        >
          <Box width='100%' mb={5} display='flex' alignItems='center' gap={2}>
            <ArrowLeftIcon sx={{ cursor: 'pointer' }} onClick={() => setUsage(null)} />
            <Typography onClick={() => setUsage(null)} sx={{ fontSize: 16, fontWeight: 500, cursor: 'pointer' }}>
              Go back
            </Typography>
          </Box>
          {[...(usage === 'premierCloud' ? invoicesHistory : groupedData)]
            ?.filter(element => element.items?.length > 0)
            ?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: 5,
                  pb: 2
                }}
              >
                <Box width='100%' sx={{ p: 5, backgroundColor: theme => theme.palette.grey[200] }}>
                  <Typography sx={{ fontSize: '1.25rem', fontWeight: 600 }}>
                    {renderTransactionDate(item?.start_date, item?.end_date)}
                  </Typography>
                </Box>
                <InvoiceTable invoiceDetails={item?.items || []} type={usage} />
              </Box>
            ))}
        </Box>
      ) : (
        <Box
          sx={{
            border: theme => `solid 1px ${theme.palette.grey[300]}`,
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
            p: 5
          }}
        >
          <Box
            sx={{
              border: theme => `solid 1px ${theme.palette.grey[300]}`,
              borderRadius: '12px',
              width: '900px',
              height: '220px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              px: 5,
              pt: 5,
              pb: 2
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontSize: 16, color: 'black', fontWeight: 500 }}>Current spending</Typography>
              {/* <Typography sx={{ fontSize: 24, color: 'black', fontWeight: 500 }}>CA$1,026.76</Typography> */}
            </Box>
            <Box sx={{ display: 'flex', gap: 5 }}>
              <Box display='flex' alignItems='center' gap={1}>
                <EventIcon sx={{ color: 'text.secondary' }} />
                <Typography variant='body2' sx={{ fontSize: 14, fontWeight: 500 }}>
                  Automatic payments
                </Typography>
              </Box>
              {/* <Box display='flex' alignItems='center' gap={1}>
                <CreditCardIcon sx={{ color: 'text.secondary' }} />
                <Typography variant='body2' sx={{ fontSize: 14, fontWeight: 500 }}>
                  Your last payment was on Feb 1 for CA$1,026.76
                </Typography>
              </Box> */}
            </Box>
            <Box textAlign='center'>
              <Typography sx={{ color: 'primary.main', fontWeight: 600, fontSize: 14 }}>Pay early</Typography>
            </Box>
          </Box>
          <Box display='flex' alignItems='center' gap={5}>
            <Box
              sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                width: '440px',
                height: '220px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                px: 5,
                pt: 5,
                pb: 2
              }}
            >
              <Typography sx={{ fontSize: 18, color: 'black', fontWeight: 500 }}>Premier Cloud transactions</Typography>
              <Box width='100%'>
                {loading ? (
                  <Box display='flex' alignItems='center'>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box display='flex' flexDirection='column' gap={2}>
                    {invoicesHistory?.map((usage, index) => (
                      <Box key={index} display='flex' justifyContent='space-between' alignItems='center'>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: 'primary.main',
                            fontWeight: 500,
                            '&:hover': { textDecoration: 'underline', cursor: 'pointer' }
                          }}
                          onClick={() => setUsage('premierCloud')}
                        >
                          {moment(usage?.start_date).format('MMM DD') +
                            ' - ' +
                            moment(usage?.end_date).format('DD YYYY')}
                        </Typography>
                        <Box display='flex' alignItems='center' gap={5}>
                          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                            CA${calculateRowsBalance(usage?.items, customerInformation.currency?.id)}
                          </Typography>
                          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                            CA${(calculateRowsBalance(usage?.items, customerInformation.currency?.id) * 0.8).toFixed(2)}{' '}
                            (-20%)
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Box textAlign='center'>
                <Typography sx={{ color: 'primary.main', fontWeight: 600, fontSize: 14 }}>
                  View transactions and documents
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                width: '440px',
                height: '220px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                px: 5,
                pt: 5,
                pb: 2
              }}
            >
              <Typography sx={{ fontSize: 18, color: 'black', fontWeight: 500 }}>Google transactions</Typography>
              <Box width='100%'>
                {loading ? (
                  <Box display='flex' alignItems='center'>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box display='flex' flexDirection='column' gap={2}>
                    {groupedData?.slice(0, 3)?.map((usage, index) => (
                      <Box key={index} display='flex' justifyContent='space-between' alignItems='center'>
                        <Typography
                          key={index}
                          sx={{
                            fontSize: 14,
                            color: 'primary.main',
                            fontWeight: 500,
                            '&:hover': { textDecoration: 'underline', cursor: 'pointer' }
                          }}
                          onClick={() => setUsage('google')}
                        >
                          {moment(usage?.start_date).format('MMM DD') +
                            ' - ' +
                            moment(usage?.end_date).format('DD YYYY')}
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>CA${usage.price}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Box textAlign='center'>
                <Typography sx={{ color: 'primary.main', fontWeight: 600, fontSize: 14 }}>
                  View transactions and documents
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display='flex' alignItems='center' gap={5}>
            <Box
              sx={{
                border: theme => `solid 1px ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                width: '440px',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                px: 5,
                pt: 5,
                pb: 2
              }}
            >
              <Box display='flex' flexDirection='column' gap={5}>
                <Typography sx={{ fontSize: 18, color: 'black', fontWeight: 500 }}>Settings</Typography>
                <Box s>
                  {customerInformation?.googleIds?.slice(0, 4)?.map((googleId, index) => (
                    <Typography key={index} sx={{ fontSize: 16, color: 'text.secondary' }}>
                      {googleId.domain}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box textAlign='center'>
                <Typography sx={{ color: 'primary.main', fontWeight: 600, fontSize: 14 }}>Manage settings</Typography>
              </Box>
            </Box>
            <Box sx={{ width: '440px', height: '200px' }}></Box>
          </Box>
        </Box>
      )}
      <AlertMessages
        error={error === 'ERROR_CURRENT_SPENDING'}
        messageValue={error && 'This customer has no subscriptions.'}
        message={error === 'ERROR_CURRENT_SPENDING' && 'This customer has no subscriptions.'}
      />
    </Fragment>
  )
}

export default CurrenctStand
