// ** React imports
import React, { useEffect } from 'react'

// ** MUI Imports
import { Grid, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

// ** Components Imports
import HomeCard from './components/cards/Card'


// ** Icons Imports
import UserIcon from 'mdi-material-ui/AccountOutline'
import BillingCard from 'mdi-material-ui/CreditCardOutline'
import AlertIcon from 'mdi-material-ui/BellOutline'
import Settings from 'mdi-material-ui/CogOutline'




const Home = (props) => {



    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <HomeCard
                    icon={<UserIcon sx={{ color: "common.black" }} />}
                    title="Users"
                    items={[
                        { name: "Customer Onboarding", path: "/customers/onboarding-list" },
                        { name: "Customers", path: "/customers/customers-list" }
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <HomeCard
                    icon={<BillingCard sx={{ color: "common.black" }} />}
                    title="Billing"
                    items={[
                        { name: "Billing list", path: "/billing" },
                        { name: "...more", path: "/" }
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <HomeCard
                    icon={<Settings sx={{ color: "common.black" }} />}
                    title="Settings"
                    items={[
                        { name: "Payment Terms", path: "/settings/due-date" },
                        { name: "...more", path: "/" }
                    ]}
                />
            </Grid>
        </Grid>
    )
}

export default Home