// ** React Imports
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  cursor: 'pointer',
  transition: '.25s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10]
  }
}))

const EmptySubCard = props => {
  // ** Hooks
  const { id } = useParams()
  const navigate = useNavigate()

  // ** Functions
  const redirectHandler = () => {
    navigate('/customers/customer-display/purchase-product/' + id)
  }

  return (
    <Grid item xs={12} sm={6} md={4.5} lg={2.5} xl={1.5} sx={{ maxWidth: '190px !important' }}>
      <StyledCard onClick={redirectHandler}>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 240,
            px: 'auto'
          }}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 36 36'>
            <path fill='#34A853' d='M16 16v14h4V20z'></path>
            <path fill='#4285F4' d='M30 16H20l-4 4h14z'></path>
            <path fill='#FBBC05' d='M6 16v4h10l4-4z'></path>
            <path fill='#EA4335' d='M20 16V6h-4v14z'></path>
            <path fill='none' d='M0 0h36v36H0z'></path>
          </svg>
          <Typography sx={{ color: 'common.blue', fontWeight: 600, fontSize: '0.875rem' }}>New Service</Typography>
        </CardContent>
      </StyledCard>
    </Grid>
  )
}

export default EmptySubCard
