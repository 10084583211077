// ** React Imports
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

// Custom Components
import Transaction from '../components/Transaction'
import SelectFilter from '../components/SelectTransactionFilter'
import FallbackSpinner from 'core/components/spinner'

// ** Icon Imports
import CalendarIcon from 'mdi-material-ui/Calendar'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { cleanCustomerInformation, cleanCustomerInvoices, customersSelector } from 'store/customers'
import SynchronizeBilling from '../components/SynchronizeBilling'
import { currencySelector, fetchCurrencyData } from 'store/metaData/currency'

// ** 3rd Party Libraries
import moment from 'moment'
import GenerateMonthlyUsage from '../components/UploadVoiceMonthlyUsage'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  paddingLeft: 3,
  paddingRight: 3
}))

// Styled Card content
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingLeft: '15%',
  paddingRight: '15%',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '15%',
    paddingRight: '15%'
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

// Format Complexe Date

const TransactionsList = () => {
  // ** Hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // ** Selectors
  const { customerInformation, customerInvoices, success, error, loading } = useSelector(customersSelector)

  // ** State
  const [active, setActive] = useState(null)

  // ** Constants
  const sortedInvoices =
    customerInvoices?.length > 0
      ? [...customerInvoices]?.sort((a, b) => {
          // Compare by startDate in descending order
          if (new Date(b.created_at) > new Date(a.created_at)) {
            return 1
          } else if (new Date(a.created_at) - new Date(b.created_at)) {
            return -1
          } else {
            // If startDate values are the same, compare by ID in ascending order
            return b.id - a.id
          }
        })
      : []

  // ** Clean up Functions
  useEffect(() => {
    return () => {
      dispatch(cleanCustomerInformation())
      dispatch(cleanCustomerInvoices())
      dispatch(fetchCurrencyData())
    }
  }, [])

  // ** Functions

  return (
    <StyledCard>
      <CardHeader
        sx={{
          mx: '-1.5rem'
        }}
        title={
          <Box
            sx={{
              display: 'flex',
              mt: '-2rem',
              py: '1rem',
              px: '3rem',
              boxShadow: ' 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box display='flex'>
              <Typography
                variant='h6'
                sx={{ fontWeight: 600, color: 'text.secondary', cursor: 'pointer' }}
                onClick={() => navigate('/billing')}
              >
                Payments
              </Typography>

              <Typography variant='h6' sx={{ fontWeight: 600, color: 'text.secondary' }}>
                &nbsp; {'>'} &nbsp;Invoices
              </Typography>
              <Typography variant='h6' sx={{ fontWeight: 600, color: 'text.secondary' }}>
                &nbsp; {'>'} &nbsp;{customerInformation?.organization}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='end' alignItems='center' gap={5}>
              <SynchronizeBilling />
            </Box>
          </Box>
        }
      />
      <StyledCardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mb: '1rem' }}>
            <SelectFilter
              disabled={true}
              values={['Detailed transaction view', 'Summary view']}
              defaultValue='Detailed transaction view'
            />
            <SelectFilter
              disabled={true}
              values={['All transactions', 'Costs', 'Payments', 'Adjustments', 'Taxes']}
              defaultValue={'All transactions'}
            />
            <Stack direction='row' alignItems='center' gap={0} sx={{ ml: 5 }}>
              <CalendarIcon sx={{ mt: 1, color: 'text.secondary' }} />
              <SelectFilter
                values={[
                  'This month',
                  'Previous month',
                  'Last 3 months',
                  'This year',
                  'Previous year',
                  'All time',
                  'Custom date range'
                ]}
                defaultValue={'Last 3 months'}
              />
            </Stack>
          </Grid>
          {loading !== 'GET_INVOICES'
            ? sortedInvoices?.map(invoice => (
                <Grid key={invoice.id} item xs={12} sx={{ mb: '0.5rem' }}>
                  <Transaction invoice={invoice} active={active} setActive={setActive} />
                </Grid>
              ))
            : null}
          <Grid item xs={12} sx={{ mt: '2rem' }}>
            {loading === 'GET_INVOICES' ? <FallbackSpinner /> : null}
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  )
}

export default TransactionsList
