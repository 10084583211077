// ** React Imports
import React, { useEffect, useLayoutEffect, useState } from 'react'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'

// ** MUI Imports
import { Grid, Typography, Card, CardContent, Button, TextField, Stack, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import PageHeader from 'core/components/page-header'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import Grow from '@mui/material/Grow'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import DeleteIcon from 'mdi-material-ui/Delete'
import PlusIcon from 'mdi-material-ui/Plus'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { createTax, deleteTax, fetchTaxData, taxSelector, editTax, cleanMessages } from 'store/metaData/tax'
import CustomInputs from '../components/CustomInputs'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  paddingLeft: 3,
  paddingRight: 3
}))

// Styled Button Component
const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  marginLeft: 2,
  marginRight: '1rem',
  fontWeight: 600,
  boxShadow: theme.shadows[4],
  color: theme.palette.common.blue,
  backgroundColor: theme.palette.common.white,
  '&:disabled': {
    color: theme.palette.grey[700],
    boxShadow: 'none'
  },
  '&:hover': {
    backgroundColor: theme.palette.common.white
  }
}))

const Tax = () => {
  // ** Redux dispatcher
  const dispatch = useDispatch()

  // ** Array holding selected Tax's ID to edited
  const [edit, setEdit] = useState(null)

  // ** State for draft tax objects to be created or updated
  const [draft, setDraft] = useState(null)
  const [open, setOpen] = useState(false)

  // ** Selectors
  const { taxData, success, error, loading } = useSelector(taxSelector)

  // ** Fetch Tax data
  useEffect(() => {
    dispatch(fetchTaxData())
  }, [])

  useLayoutEffect(() => {
    if (success == 'CREATE' || success == 'UPDATE') {
      setDraft(null)
      setEdit(null)
      setOpen(true)
    }
    setTimeout(() => {
      dispatch(cleanMessages())
    }, 1500)
  }, [success, error])

  // ** Functions
  const addDraftHandler = () => {
    setDraft({ name: '', value: '' })
    setEdit(null)
  }
  const deleteDraftHandler = () => {
    setDraft(null)
  }

  const addTaxHandler = () => {
    if (edit) {
      dispatch(editTax({ id: edit, ...draft }))
    } else {
      dispatch(createTax(draft))
    }
  }

  const deleteTaxHandler = id => {
    dispatch(deleteTax(id))
  }

  const handleCloseMessage = () => {
    setOpen(false)
  }

  // Intialize an empty input to start editing the value
  const editTaxHandler = id => {
    setEdit(id)
  }

  const debounced = useDebouncedCallback(
    event => {
      const { name, value } = event.target
      setDraft(prevState => {
        return { ...prevState, [name]: value }
      })
    },
    500,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  const discardChanges = () => {
    setEdit(null)
    setDraft(null)
  }

  return (
    <Grid container spacing={6}>
      <PageHeader
        title={
          <Typography variant='h5' sx={{ color: 'common.blue', fontWeight: 600 }}>
            Tax List
          </Typography>
        }
      />
      <Grid item xs={12}>
        <StyledCard>
          <CardContent>
            {loading == 'GET' && (
              <Grid xs={12} sx={{ ml: '1rem', mb: 5 }}>
                <CircularProgress size='3rem' />
              </Grid>
            )}
            {loading != 'GET' &&
              taxData.map((tax, index) => (
                <CustomInputs
                  key={index}
                  type='tax'
                  setEdit={setEdit}
                  data={tax}
                  setDraft={setDraft}
                  deleteHandler={deleteTaxHandler}
                />
              ))}
            {draft && !edit ? (
              <Grid item xs={12} sx={{ display: 'flex', mb: 5 }}>
                <Stack direction='row' alignItems='center' gap={3} sx={{ my: 2 }}>
                  <TextField sx={{ mr: 5 }} label='Tax name' size='small' name='name' onChange={debounced} />
                  <TextField
                    sx={{ mr: 5 }}
                    type='number'
                    label='Tax fee'
                    size='small'
                    name='value'
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>
                    }}
                    onChange={debounced}
                  />
                  <IconButton onClick={deleteDraftHandler}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Grid>
            ) : null}
            <Grid xs={12} sx={{ mb: 5 }}>
              <StyledButton variant='text' disabled={draft || edit} onClick={addDraftHandler}>
                <PlusIcon sx={{ mr: 2 }} />
                add tax
              </StyledButton>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <StyledButton
                variant='contained'
                disabled={!(draft?.name && draft?.value)}
                onClick={addTaxHandler}
                startIcon={(loading == 'CREATE' || loading == 'UPDATE') && <CircularProgress size='1rem' />}
              >
                save
              </StyledButton>
              <StyledButton variant='contained' disabled={!(edit || draft)} onClick={discardChanges}>
                discard changes
              </StyledButton>
            </Grid>
          </CardContent>
        </StyledCard>
        {/* <SettingsTable /> */}
      </Grid>
      <Snackbar
        sx={{ mt: '3rem' }}
        open={open}
        onClose={handleCloseMessage}
        message='Tax added successfully'
        autoHideDuration={2000}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Grow}
      />
    </Grid>
  )
}

export default Tax
