// ** React Imports
import React from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'

// ** Redux Imports
import { customersSelector } from 'store/customers'
import { useSelector } from 'react-redux'

// Styled Card component
const Plan = styled(Card, { shouldForwardProp: prop => prop !== 'active' })(({ theme, active }) => ({
  boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30), 0px 1px 3px 1px rgba(66,66,66,.15)',
  border: '1px solid #e0e0e0',
  borderRadius: '6px',
  position: 'realtive',
  marginBottom: '16px',
  cursor: 'pointer',
  ...(active ? { border: `2px solid ${theme.palette.primary.main}` } : {})
}))

const flexible_advantages = [
  'No long-term commitment',
  'Pay each month based on users you have each month, starting Sep 18, 2023',
  'Add or remove as many users as needed'
]

const estimatedPrice = (product, customerInformation) => {
  const currencyId = customerInformation?.currency?.id || null
  const flexiblePrice =
    product?.prices?.find(
      item =>
        item.pricingBookId === parseInt(process.env.REACT_APP_DEFAULT_PRICE_ID) &&
        (item.currencyId == currencyId || item.currencyId === parseInt(process.env.REACT_APP_DEFAULT_CURRENCY_ID)) &&
        item.type === 'flexible'
    )?.value || 0
  return `$ ${flexiblePrice?.toFixed(2)} ${customerInformation?.currency?.iso_code}`
}

const FlexiblePlan = props => {
  // ** Props
  const { product, multiple, active, setActive, setSelectedPlan,setLicensedNumberOfSeats } = props

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)

  // ** Functions
  const selectProductHandler = () => {
    setActive('flexible')
    setSelectedPlan('flexible')
    setLicensedNumberOfSeats(1)
  }
  console.log("----", active)


  return (
    <Plan active={multiple ? active === 'flexible' : false} onClick={selectProductHandler}>
      <Box p='15px 24px 0px 24px'>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {multiple ? (
            <RadioGroup row name='simple-radio' aria-label='simple-radio'>
              <FormControlLabel
                checked={active === 'flexible'}
                control={<Radio />}
                label={<Typography fontWeight={500}>{product?.skuName}</Typography>}
              />
            </RadioGroup>
          ) : (
            <Typography fontWeight={500}>{product?.skuName}</Typography>
          )}
          <Box textAlign='end'>
            <Typography fontWeight={500}>{estimatedPrice(product, customerInformation)} per user/month</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 5, fontSize: 12, color: 'text.secondary' }}>
          <ul>
            {flexible_advantages?.map((el, index) => (
              <li key={index} style={{ margin: '6px -20px 6px -20px' }}>
                {el}
              </li>
            ))}
          </ul>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: theme => `1px solid ${theme.palette.grey[300]}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          p: '15px 24px 15px 24px',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant='body2' fontSize={13} fontWeight={450}>
            Total users
          </Typography>
          <Typography fontSize={14}>1</Typography>
        </Box>
        <Box textAlign='end'>
          <Typography variant='body2' fontSize={13} fontWeight={450}>
            Estimated monthly cost
          </Typography>
          <Typography fontSize={14} fontWeight={450}>
            {estimatedPrice(product, customerInformation)}/month
          </Typography>
        </Box>
      </Box>
    </Plan>
  )
}

export default FlexiblePlan
