// ** React Imports
import React, { Fragment, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'

// ** Icon Import
import DeleteIcon from 'mdi-material-ui/Delete'

const renderInput = (name, value, onChange, error, helperText) => {
  return (
    <TextField
      variant='outlined'
      size='small'
      type='number'
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={error && 'price must be positive'}
      sx={{ width: 150, height: 40 }}
    />
  )
}

const RowPricingForm = props => {
  // ** Props
  const { row, columns, setSelectedProducts, currenciesData, setPrices, prices, setValue, product } = props

  // ** State
  const [errors, setErrors] = useState([])

  // ** Functions
  const findValue = name => {
    if (prices?.length > 0) {
      const type = name?.split('-')?.[0]?.toLowerCase()
      const currencyName = name?.split('-')?.[1]
      const currencyId = currenciesData?.find(item => item.iso_code === currencyName)?.id
      const price = prices?.find(
        item => item.currencyId === currencyId && item.type === type && item.productId === row.id
      )
      return price?.value || 0
    }
    return 0
  }

  const changePrice = event => {
    const { name, value } = event.target
    if (parseFloat(value) >= 0) {
      // setErrors([...errors, name])
      // else setErrors(errors.filter(item => item !== name))
      const type = name?.split('-')?.[0]?.toLowerCase()
      const currencyName = name?.split('-')?.[1]
      const currencyId = currenciesData?.find(item => item.iso_code === currencyName)?.id
      const price = {
        value: parseFloat(value),
        currencyId,
        type,
        productId: row.id
      }
      setPrices(state => {
        const new_array = state?.some(
          item => item.currencyId === currencyId && item.type === type && item.productId === row.id
        )
          ? state.map(item =>
              item.currencyId === currencyId && item.type === type && item.productId === row.id
                ? { ...item, ...price }
                : item
            )
          : [...state, price]
        setValue('prices', new_array)
        return new_array
      })
    }
  }

  const deleteHandler = () => {
    setPrices(state => {
      const newPrices = state?.filter(item => item.productId !== row.id)
      setValue('prices', newPrices)
      return newPrices
    })
    setSelectedProducts && setSelectedProducts(state => state.filter(item => item.id !== row.id))
  }

  return (
    <Fragment>
      <TableRow sx={{ height: 100 }}>
        {!product ? (
          <>
            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
              <Typography>{row?.skuName}</Typography>
            </TableCell>
            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
              <Typography>{row?.skuId}</Typography>
            </TableCell>
          </>
        ) : null}
        {columns.map((item, index) => (
          <TableCell key={index} align='left' sx={{ height: 40, whiteSpace: 'nowrap' }}>
            {renderInput(item, findValue(item), changePrice, errors.includes(item))}
          </TableCell>
        ))}
        <TableCell align='left' width={100}>
          <Box display='flex' aligItems='center' gap={2}>
            <Fab sx={{ width: 35, height: 18 }} color='primary' aria-label='add' onClick={deleteHandler}>
              <DeleteIcon sx={{ fontSize: 18 }} />
            </Fab>
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default RowPricingForm
