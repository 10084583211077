// ** React Imports
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PickersRange from './SelectDateRangePicker'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import ArrowDown from 'mdi-material-ui/MenuDown'

// ** Third Party Libraries
import moment from 'moment'
// ** Redux Imports
import { useDispatch } from 'react-redux'
import { getCustomerInvoice } from 'store/customers'



// Styled Card component
const Item = styled(Typography)(({ theme }) => ({
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: "0.925rem",
    cursor: 'pointer',
    //...(selected ? { backgroundColor: "#eee" } : {}),
    "&:hover": {
        backgroundColor: "#eee"
    }
}))

const SelectFilter = (props) => {

    // ** Hooks
    const { id } = useParams()
    const dispatch = useDispatch()

    // ** Props
    const { filter, values, defaultValue, disabled } = props

    // ** Refs
    const wrapperRef = useRef(null);

    // ** State
    const [value, setValue] = useState(defaultValue)
    const [showList, setShowList] = useState(false)
    const [openDatePicker, setOpenDatePicker] = useState(false)


    // ** Hook which detect clicking outside the popper box
    useEffect(() => {
        // ** Alert if clicked on outside of element
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpenDatePicker(false)
                setValue(null)
                setShowList(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleChange = (event) => {
        if (event.target.value == "Custom date range")
            setOpenDatePicker(true)
        setValue(event.target.value)
    };

    const onClickHandler = (value) => {
        setShowList(false)
        let startDate = ""
        let endDate = ""
        if (value === "Custom date range")
            setOpenDatePicker(true)
        else if (value === "This month") {
            startDate = moment().startOf('month').format('YYYY-MM-DD')
            endDate = moment().endOf('month').format('YYYY-MM-DD');
        }
        else if (value === "Previous month") {
            startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
            endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        }
        else if (value === "Last 3 months") {
            startDate = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
            endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        }
        else if (value === "This year") {
            startDate = moment().startOf('year').format('YYYY-MM-DD')
            endDate = moment().endOf('year').format('YYYY-MM-DD')
        }
        else if (value === "Previous year") {
            startDate = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD')
            endDate = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
        }
        setValue(value)
        if (value !== "Custom date range") {
            dispatch(getCustomerInvoice(
                {
                    id: id,
                    filters: {
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            ))
        }

    }




    return (
        <Fragment>
            <FormControl sx={{ mx: 4, color: "#444", minWidth: 150, position: "relative" }} variant='standard'>
                <TextField
                    variant='standard'
                    size="small"
                    value={value}
                    sx={{ input: { cursor: "default" } }}
                    onClick={() => setShowList(true)}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position='end'>
                            <ArrowDown sx={{ color: "text.secondary" }} />
                        </InputAdornment>,
                        style: {
                            fontSize: "0.925rem"
                        }
                    }} />
            </FormControl>
            {
                showList && defaultValue === "Last 3 months" &&
                <Box
                    ref={wrapperRef}
                    sx={{
                        zIndex: theme => theme.zIndex.appBar - 5,
                        backgroundColor: "common.white",
                        boxShadow: theme => theme.shadows[2],
                        border: theme => `1px solid ${theme.palette.grey[400]}`,
                        position: "absolute",
                        width: 176,
                        height: 'fit-content',
                        py: 3,
                        ml: "2rem"
                    }}
                >
                    {values.map((el, index) => (
                        <Item key={index} onClick={() => onClickHandler(el)}>{el}</Item>
                    ))}
                </Box>
            }
            {openDatePicker &&
                <Box
                    ref={wrapperRef}
                    sx={{
                        zIndex: theme => theme.zIndex.appBar - 5,
                        p: 5,
                        mt: "8rem",
                        backgroundColor: "white",
                        boxShadow: theme => theme.shadows[2],
                        border: theme => `1px solid ${theme.palette.grey[400]}`,
                        position: "absolute",
                        width: 300,
                        height: 160
                    }}
                >
                    <PickersRange setOpenDatePicker={setOpenDatePicker} />
                </Box>}
        </Fragment>
    )
}

export default SelectFilter