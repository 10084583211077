// ** MUI Imports
import Grid from '@mui/material/Grid'

const PageHeader = props => {
  // ** Props
  const { title, subtitle } = props

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Grid item xs={12}>
      {title}
      {subtitle || null}
    </Grid>
  )
}

export default PageHeader
