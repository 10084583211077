// ** React Imports
import React, { Fragment, useState } from 'react'

// ** APIs
import { generateMonthlyUsage } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import CircularProgress from '@mui/material/CircularProgress'

// ** Third Party imports
import moment from 'moment'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { currencySelector } from 'store/metaData/currency'

const GenerateMonthlyUsage = props => {
  // ** Selectors
  const { currenciesData } = useSelector(currencySelector)

  // ** State
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, message: '' })

  // ** Constants
  const fileReader = new FileReader()

  // ** Fucntions
  const handleCloseAlert = () => {
    setMessage({ success: false, error: false, message: '' })
  }

  const csvFileToArray = async string => {
    try {
      setLoading(true)
      const rows = string.split('\n').map(row => row.trim())
      const invoiceData = {}

      if (rows.length >= 10) {
        const array = rows.slice(0, 7).map(i => {
          const [property, value] = i.replace(/"([^"]*)"/g, (match, group) => group.replace(/,/g, '')).split(',')
          invoiceData[property] = value
        })
        const headers = rows[8].split(',')
        const data = rows.slice(9).map(row => {
          const reformattedRow = row.replace(/"([^"]*)"/g, (match, group) => group.replace(/,/g, ''))
          const values = reformattedRow.split(',')
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index]
            return obj
          }, {})
        })
        const originalYear = moment(invoiceData?.['Invoice date'], 'DD MMM YYYY').year()
        const currencyId = currenciesData?.find(item => item.iso_code === invoiceData?.['Currency'])?.id
        const body = data.map(item => {
          const start_date = moment(`${item?.['Start Date']} ${originalYear}`, 'D MMM YYYY').format('YYYY-MM-DD')
          const end_date = moment(`${item?.['End Date']} ${originalYear}`, 'D MMM YYYY').format('YYYY-MM-DD')

          return {
            domain_name: item?.['Domain Name'] || '',
            unit: item?.['Unit'] || '',
            price: parseFloat(item?.['Amount'] || 0),
            start_date: item?.['Start Date'] ? start_date : '',
            end_date: item?.['End Date'] ? end_date : '',
            seats: parseInt(item?.['Quantity'] || 0),
            description: item?.['Description'],
            currency_id: currencyId,
            resource_type: item?.['Resource Type'],
            subscription: item?.['Subscription'] || '',
            day: moment(invoiceData?.['Invoice date'], 'DD MMM YYYY').format('YYYY-MM-DD')
          }
        })
        const response = await generateMonthlyUsage({ data: body })
        setMessage({
          success: true,
          error: false,
          message: 'Monthly generation for this customer was done successfully !'
        })
        setLoading(false)
      }
    } catch (error) {
      console.log('---errorr----', error)
      setLoading(false)
      setMessage({ success: false, error: true, message: 'An error has occured, please try again.' })
    }
  }

  const handleOnChange = event => {
    const file = event.target.files[0]
    fileReader.onload = function (event) {
      const text = event.target.result
      csvFileToArray(text)
    }
    fileReader.readAsText(file)
    event.target.value = null
  }

  return (
    <Fragment>
      <Button
        variant='text'
        component='label'
        disabled={loading}
        endIcon={loading && <CircularProgress size='1rem' sx={{ color: 'white' }} />}
      >
        Upload Voice billing
        <input type='file' hidden accept='.csv' onChange={handleOnChange} />
      </Button>
      <Snackbar
        open={message.message}
        onClose={handleCloseAlert}
        message={message.message}
        autoHideDuration={1500}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Fragment>
  )
}

export default GenerateMonthlyUsage
