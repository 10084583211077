// ** React Imports
import React from 'react'

// ** MUI Imports
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'

// ** Component Imports
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const ProductsAutocomplete = props => {
  // ** Props
  const { productsData, products, productChangeHandler } = props

  // ** Constants
  const allSelected = products?.length === productsData?.length

  // ** Functions

  const handleSelectAll = isSelected => {
    if (isSelected) {
      productChangeHandler('prices_array', products)
    } else {
      productChangeHandler('prices_array', [])
    }
  }

  const onChangeAutocomplete = (event, selectedOptions, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find(option => option.id === 'select-all')) {
        handleSelectAll(!allSelected)
      } else {
        productChangeHandler('prices_array', selectedOptions)
      }
    } else if (reason === 'clear') {
      productChangeHandler('prices_array', [])
    }
  }

  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.id === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {}
    return (
      <li {...props} key={option.id}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.skuName} {option.id !== 'select-all' ? `(${option.skuId})` : ''}
      </li>
    )
  }

  const filterOptions = (options, { inputValue }) => {
    return options.filter(option => {
      // Customize this part to search in multiple fields of each object
      const searchString = `${option.skuName} ${option.skuId}`.toLowerCase()
      return searchString.includes(inputValue.toLowerCase())
    })
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={2}
      size='small'
      onChange={onChangeAutocomplete}
      value={productsData}
      options={[{ skuName: 'Select All', id: 'select-all' }, ...products]}
      getOptionLabel={option => option?.skuName}
      filterOptions={filterOptions}
      sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
      renderOption={optionRenderer}
      renderInput={params => <TextField {...params} label='Search for products by name' />}
    />
  )
}

export default ProductsAutocomplete
