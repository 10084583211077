// ** React Imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import { useMediaQuery } from '@mui/material'

// ** Icons Imports
import Refresh from 'mdi-material-ui/Refresh'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'

// ** Custom component
import AlertMessages from 'pages/Billing/components/AlertMessages'
import SubscriptionCard from './SubscriptionCard'
import EmptySubCard from './EmptySubCard'
import SubscriptionDetails from './SubscriptionDetailsCard'
import FallbackSpinner from 'core/components/spinner'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300],
  marginTop: '-0.5rem',
  width: '120%',
  marginLeft: '-10%'
}))

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}))

const Subscriptions = () => {
  // ** Hooks
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** State
  const [reload, setReload] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedSub, setSelectedSub] = useState({})

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)

  // ** Functions
  const selectSubHandler = sub => {
    if (sub?.id == selectedSub?.id) {
      setSelectedSub({})
    } else {
      setSelectedSub({ ...sub })
    }
  }

  return (
    <StyledCard>
      <CardHeader
        title='Subscriptions'
        action={
          <HeaderContainer>
            <IconButton
              size='small'
              aria-label='reload'
              onClick={() => handleBackDrop()}
              sx={{ mr: 2, color: 'text.secondary' }}
            >
              <Refresh fontSize='small' />
            </IconButton>
          </HeaderContainer>
        }
      />
      <CardContent>
        <StyledDivider />
        <Grid container spacing={4} sx={{ ...(!small ? { py: 2 } : {}) }}>
          <EmptySubCard />
          {!loading &&
            customerInformation?.googleIds?.map(el =>
              el?.subscriptions?.map((sub, index) => (
                <SubscriptionCard
                  key={index}
                  sub={sub}
                  selectSubHandler={selectSubHandler}
                  selectedSub={selectedSub}
                  customerInformation={customerInformation}
                />
              ))
            )}
          {loading && (
            <Grid item xs={12} md={2} sx={{ ...(small ? { mt: '1rem' } : { mt: '4rem' }) }}>
              <FallbackSpinner />
            </Grid>
          )}
          <Grid item xs={12}>
            <Collapse in={selectedSub?.id == undefined ? false : true} timeout='auto' unmountOnExit>
              <SubscriptionDetails
                selectedSub={selectedSub}
                customerInformation={customerInformation}
                selectSubHandler={selectSubHandler}
              />
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
      <AlertMessages
        success={success}
        error={error === 'UPDATE_SUBSCRIPTION'}
        messageValue={success && 'UPDATE_SUBSCRIPTION'}
        message={success ? 'Subscription was updated successfully !' : "An error has occured please try again !"}
      />
    </StyledCard>
  )
}

export default Subscriptions
