// ** React Imports
import React from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import { Typography, Grid, Box } from '@mui/material'

// ** Custom Componenets
import CustomAvatar from 'core/components/mui/avatar'

// ** Images Imports
import HelpCircle from 'mdi-material-ui/CheckCircle'
import { products } from '@fake-db/products'

// ** Third Party Libraries
import parse from 'html-react-parser'

// Styled Img component
const Card = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '3px',
  padding: '15px 5px 15px 5px',
  height: '350px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}))

const Link = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  cursor: 'pointer',
  color: '#3367d6'
}))

const DescriptionBody = styled(Typography)(({ theme }) => ({
  color: '#757575',
  fontSize: '12px',
  lineHeight: '16px',
  marginTop: '16px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '5',
  display: '-webkit-box',
  maxHeight: '100px'
}))

const ProductCard = props => {
  // ** Props
  const { id, name, description, price, icon, purchased, add_on, unit, openPurchaseScreen } = props
  // ** Constants
  const productIcon = name =>
    products.find(el => el.displayName.toLowerCase() === icon?.toLowerCase())?.defaultLogo?.content
  return (
    <Card>
      <Box px={3} display='flex' flexDirection='column' alignItems='start' gap={add_on ? 2 : 13}>
        <Box>
          <Box display='flex' alignItems='center' gap={3}>
            <CustomAvatar src={productIcon(icon) || ''} sx={{ borderRadius: 0 }} />
            <Typography fontSize={14} fontWeight={700}>
              {name}
            </Typography>
          </Box>
          {add_on ? (
            <Box
              sx={{ width: 'fit-content', my: 2, px: 2, py: 0.5, backgroundColor: theme => theme.palette.grey[200] }}
            >
              <Typography
                fontWeight={700}
                fontSize={11}
                sx={{ borderRadius: '3px', color: theme => theme.palette.grey[500] }}
              >
                ADD-ON
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box px={3}>
          <DescriptionBody>{parse(description)}</DescriptionBody>
        </Box>
      </Box>
      <Box px={3}>
        <Box>
          {!isNaN(price) ? (
            <>
              <Typography fontSize={12} variant='body2' mb={1}>
                Starting
              </Typography>
              <Box display='flex' alignItems='center' gap={1} mb={3}>
                <Typography fontSize={18} variant='body2' fontWeight={600}>
                  ${price}
                </Typography>
                <Typography fontSize={12} variant='body2'>
                  /{unit}
                </Typography>
                <Typography fontSize={12} variant='body2'>
                  /month
                </Typography>
              </Box>
            </>
          ) : null}
          {!purchased ? (
            <Box textAlign='end'>
              <Link onClick={() => openPurchaseScreen(id)}>GET STARTED</Link>
            </Box>
          ) : (
            <Box display='flex' alignItems='center' justifyContent='end' gap={2}>
              <Typography fontSize={14} fontWeight={600} sx={{ color: theme => theme.palette.grey[500] }}>
                PURCHASED
              </Typography>
              <HelpCircle sx={{ color: theme => theme.palette.grey[600], fontSize: 20 }} />
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  )
}

export default ProductCard
