// ** React Imports
import React, { forwardRef, Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// ** MUI Lab
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'

// ** Third Party imports
import moment from 'moment'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  customersSelector,
  editSubDiscount,
  editSubPricing,
  updateNonGoogleSkuSubscription,
  editCustomerSubscription
} from 'store/customers'
import { fetchPricingbooksData, pricingBooksSelector } from 'store/metaData/pricingbook'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const SubscriptionEdit = props => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Props
  const { subscription, customerId } = props

  // ** State
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('1')
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [initialEndDate, setInitialEndDate] = useState(new Date())
  const [initialStartDate, setInitialStartDate] = useState(new Date())
  const [discount, setDiscount] = useState(0) // type vendor
  const [discountMargin, setDiscountMargin] = useState(0) // type margin
  const [margin, setMargin] = useState(0)
  const [seats, setSeats] = useState(0)
  const [subscriptionPricingBooks, setSubscriptionPricingBooks] = useState({
    startDate: new Date(),
    endDate: new Date(),
    pricingBookId: null,
    subscriptionId: subscription.subscriptionId
  })
  const [initialSubscriptionPricingBooks, setInitialSubscriptionPricingBooks] = useState({
    startDate: new Date(),
    endDate: new Date(),
    pricingBookId: null,
    subscriptionId: subscription.subscriptionId
  })

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)
  const { pricingBooksData } = useSelector(pricingBooksSelector)

  // ** Constants
  const subVendorDiscount = customerInformation?.discounts.find(
    disc => disc.subscriptionId === subscription.subscriptionId && disc.type === 'vendor'
  )
  const subMarginDiscount = customerInformation?.discounts.find(
    disc => disc.subscriptionId === subscription.subscriptionId && disc.type === 'margin'
  )

  const subMargin = customerInformation?.googleIds
    ?.flatMap(googleId => googleId.subscriptions)
    ?.find(sub => sub.subscriptionId === subscription.subscriptionId)

  const subPricing = customerInformation?.subscriptionPricingBooks?.find(
    disc => disc.subscriptionId == subscription.subscriptionId
  )

  // ** Clean up
  useEffect(() => {
    if (customerInformation?.id) {
      setSeats(
        subscription?.planName === 'ANNUAL'
          ? subscription?.numberOfSeats
          : subscription?.planName === 'FLEXIBLE'
          ? subscription?.licensedNumberOfSeats
          : 0
      )
      if (subVendorDiscount == undefined) {
        setDiscount(0)
        setStartDate(new Date())
        setEndDate(new Date())
      } else {
        setDiscount(subVendorDiscount?.value)
        setInitialStartDate(new Date(moment(subVendorDiscount.startDate).utc(false).format('YYYY/MM/DD')))
        setInitialEndDate(new Date(moment(subVendorDiscount.endDate).utc(false).format('YYYY/MM/DD')))
        setStartDate(new Date(moment(subVendorDiscount.startDate).utc(false).format('YYYY/MM/DD')))
        setEndDate(new Date(moment(subVendorDiscount.endDate).utc(false).format('YYYY/MM/DD')))
        setDiscountMargin(subMarginDiscount?.value || 0)
      }
      if (subMargin?.margin) {
        setMargin(subMargin?.margin || 0)
      }
      if (subPricing?.id) {
        setInitialSubscriptionPricingBooks({
          endDate: new Date(moment(subPricing.endDate).utc(false).format('YYYY/MM/DD')),
          startDate: new Date(moment(subPricing.startDate).utc(false).format('YYYY/MM/DD')),
          pricingBookId: subPricing.pricingBookId,
          subscriptionId: subscription.subscriptionId
        })
        setSubscriptionPricingBooks({
          endDate: new Date(moment(subPricing.endDate).utc(false).format('YYYY/MM/DD')),
          startDate: new Date(moment(subPricing.startDate).utc(false).format('YYYY/MM/DD')),
          pricingBookId: subPricing.pricingBookId,
          subscriptionId: subscription.subscriptionId
        })
      } else {
        setSubscriptionPricingBooks({
          startDate: new Date(),
          endDate: new Date(),
          pricingBookId: null,
          subscriptionId: subscription.subscriptionId
        })
      }
    }
  }, [subscription, customerInformation])

  useEffect(() => {
    if (pricingBooksData?.length < 1) dispatch(fetchPricingbooksData({ status: 'active' }))
  }, [])

  useEffect(() => {
    if (success == 'UPDATE_SUBSCRIPTION' || error == 'UPDATE_SUBSCRIPTION') {
      setOpen(false)
    }
  }, [success, error])

  // ** Fucntions
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setValue('1')
  }
  const onChange = amount => {
    setDiscount(parseFloat(amount))
  }

  const onChangeDiscountMargin = amount => {
    setDiscountMargin(parseFloat(amount))
  }
  const onChangeMargin = amount => {
    setMargin(amount)
  }
  const onChangeSeats = value => {
    setSeats(value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onChangePricingBook = event => {
    setSubscriptionPricingBooks(state => ({ ...state, pricingBookId: event.target.value }))
  }

  const onChangeStartDateHandler = date => {
    setStartDate(date)
    setEndDate(new Date(date).setDate(new Date(date).getDate() + 30))
  }

  const submitDiscount = () => {
    if (value === '2') {
      if (subPricing?.subscriptionId) {
        dispatch(
          editSubPricing({
            id: subPricing?.id,
            startDate: moment(subscriptionPricingBooks.startDate).format('YYYY-MM-DD'),
            endDate: moment(subscriptionPricingBooks.endDate).format('YYYY-MM-DD'),
            subscriptionId: subscriptionPricingBooks.subscriptionId,
            pricingBookId: subscriptionPricingBooks.pricingBookId,
            customerId
          })
        )
      } else if (subscriptionPricingBooks?.pricingBookId) {
        dispatch(
          editSubPricing({
            startDate: moment(subscriptionPricingBooks.startDate).format('YYYY-MM-DD'),
            endDate: moment(subscriptionPricingBooks.endDate).format('YYYY-MM-DD'),
            subscriptionId: subscriptionPricingBooks.subscriptionId,
            pricingBookId: subscriptionPricingBooks.pricingBookId,
            customerId
          })
        )
      }
    } else if (value === '3') {
      const dailyChangesData = {
        subscription_id: subscription?.subscriptionId,
        day: moment().format('YYYY-MM-DD')
      }
      const data = {
        ...subscription,
        ...(subscription?.planName?.includes('ANNUAL')
          ? { numberOfSeats: parseInt(seats) }
          : subscription?.planName?.includes('FLEXIBLE')
          ? { licensedNumberOfSeats: parseInt(seats) }
          : {})
      }
      dispatch(updateNonGoogleSkuSubscription(data, dailyChangesData))
    } else if (value === '4') {
      const updatedSubscription = { ...subscription, margin: parseFloat(margin) }
      dispatch(editCustomerSubscription(customerInformation, updatedSubscription))
    } else {
      const vendorDiscount = !!subVendorDiscount?.id
        ? {
            id: subVendorDiscount?.id,
            value: discount,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            subscriptionId: subscription.subscriptionId,
            skuId: subscription?.skuId,
            type: 'vendor'
          }
        : {
            value: discount,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            skuId: subscription?.skuId,
            subscriptionId: subscription.subscriptionId,
            type: 'vendor'
          }
      const marginDiscount = !!subMarginDiscount?.id
        ? {
            id: subMarginDiscount?.id,
            value: discountMargin,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            subscriptionId: subscription.subscriptionId,
            skuId: subscription?.skuId,
            type: 'margin'
          }
        : {
            value: discountMargin,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            skuId: subscription?.skuId,
            subscriptionId: subscription.subscriptionId,
            type: 'margin'
          }

      dispatch(editSubDiscount({ customerId, discounts: [vendorDiscount, marginDiscount] }))
    }
  }


  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Button
          variant='text'
          onClick={handleClickOpen}
          sx={{
            ml: 'auto',
            boxShadow: 0,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: 'common.white',
              boxShadow: 0
            }
          }}
        >
          Edit
        </Button>
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                minWidth: '60%' // Set your width here
              }
            }
          }}
        >
          <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Edit subscription</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <TabContext value={value}>
                  <TabList
                    sx={{ mt: '0.725rem', '& .MuiButtonBase-root': { textTransform: 'capitalize' } }}
                    onChange={handleChange}
                    aria-label='full width tabs example'
                  >
                    <Tab value='1' label='Discount' />
                    <Tab value='2' label='Pricing book' />
                    {subscription?.is_not_google_product ? <Tab value='3' label='Seats' /> : null}
                    <Tab value='4' label='Margin' />
                  </TabList>
                  <TabPanel value='1'>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3} sx={{ my: 2 }}>
                        <TextField
                          value={discount}
                          label='Discount'
                          size='small'
                          type='number'
                          onChange={e => onChange(e.target.value)}
                          error={discount < 0 || discount > 100}
                          sx={{
                            width: '100%'
                          }}
                          InputProps={{
                            endAdornment: <text>%</text>
                          }}
                        />
                        {(discount < 0 || discount > 100) && (
                          <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                            Discount should be between 0 and 100
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='Start date'
                          inputFormat='MM/DD/YYYY'
                          value={startDate}
                          maxDate={new Date(endDate)}
                          onChange={onChangeStartDateHandler}
                          sx={{ width: '100%' }}
                          slotProps={{ textField: { size: 'small' } }}
                          renderInput={params => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='End date'
                          value={endDate}
                          inputFormat='MM/DD/YYYY'
                          minDate={new Date(startDate)}
                          onChange={newValue => setEndDate(newValue)}
                          sx={{ width: '100%' }}
                          slotProps={{ textField: { size: 'small' } }}
                          renderInput={params => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} sx={{ my: 2 }}>
                        <TextField value='Vendor' label='Type' size='small' disabled />
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} mt={0}>
                      <Grid item xs={12} sm={3} sx={{ my: 2 }}>
                        <TextField
                          value={discountMargin}
                          label='Discount'
                          size='small'
                          type='number'
                          onChange={e => onChangeDiscountMargin(e.target.value)}
                          error={discountMargin < 0 || discountMargin > subscription.margin}
                          sx={{
                            width: '100%'
                          }}
                          InputProps={{
                            endAdornment: <text>%</text>
                          }}
                        />
                        {(discountMargin < 0 || discountMargin > subscription.margin) && (
                          <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                            discount should be less than {subscription?.margin || 0}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='Start date'
                          inputFormat='MM/DD/YYYY'
                          value={startDate}
                          disabled
                          sx={{ width: '100%' }}
                          slotProps={{ textField: { size: 'small' } }}
                          renderInput={params => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='End date'
                          value={endDate}
                          inputFormat='MM/DD/YYYY'
                          disabled
                          sx={{ width: '100%' }}
                          slotProps={{ textField: { size: 'small' } }}
                          renderInput={params => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} sx={{ my: 2 }}>
                        <TextField value='Margin' label='Type' size='small' disabled />
                      </Grid>
                    </Grid>
                    <Grid container spacing={4} mt={1}>
                      <Grid item xs={12} sm={3} sx={{ my: 2 }}>
                        <TextField
                          value={parseFloat(discount || 0) + parseFloat(discountMargin || 0)}
                          label='Total'
                          size='small'
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value='2'>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <FormControl fullWidth size='small'>
                          <InputLabel id='pricing-book-label'>Pricing book</InputLabel>
                          <Select
                            value={subscriptionPricingBooks.pricingBookId}
                            label='Pricing Book'
                            onChange={onChangePricingBook}
                            labelId='pricing-book-label'
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left'
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            <MenuItem value={null} disabled>
                              <em>None</em>
                            </MenuItem>
                            {pricingBooksData?.map(pricing => (
                              <MenuItem key={pricing.id} value={pricing.id}>
                                {pricing.name} {pricing.is_default ? '(default)' : ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='Start date'
                          value={subscriptionPricingBooks.startDate}
                          inputFormat='MM/DD/YYYY'
                          onChange={newValue =>
                            setSubscriptionPricingBooks(state => ({
                              ...state,
                              startDate: newValue,
                              endDate: new Date(newValue).setDate(new Date(newValue).getDate() + 30)
                            }))
                          }
                          renderInput={params => <TextField {...params} />}
                          slotProps={{ textField: { size: 'small' } }}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='End date'
                          value={subscriptionPricingBooks.endDate}
                          inputFormat='MM/DD/YYYY'
                          minDate={new Date(subscriptionPricingBooks.startDate)}
                          onChange={newValue => setSubscriptionPricingBooks(state => ({ ...state, endDate: newValue }))}
                          renderInput={params => <TextField {...params} />}
                          slotProps={{ textField: { size: 'small' } }}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  {subscription?.is_not_google_product ? (
                    <TabPanel value='3'>
                      <Grid container>
                        <Grid item xs={12} md={6} sx={{ my: 2 }}>
                          <TextField
                            value={seats}
                            label='Seats number'
                            size='small'
                            onChange={e => onChangeSeats(e.target.value)}
                            error={seats < 0 || seats > 50000}
                            sx={{
                              width: '100%'
                            }}
                          />
                          {(seats < 0 || seats > 50000) && (
                            <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                              Seats should be between 0 and 100
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : null}
                  <TabPanel value='4'>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <TextField
                          value={margin}
                          label='Margin'
                          size='small'
                          onChange={e => onChangeMargin(e.target.value)}
                          error={margin < 0 || margin > 100}
                          sx={{
                            width: '100%'
                          }}
                          InputProps={{
                            endAdornment: <text>%</text>
                          }}
                        />
                        {(margin < 0 || margin > 100) && (
                          <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                            Margin should be between 0 and 100
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className='dialog-actions-dense'>
            <Button
              sx={{
                color: 'text.secondary',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: theme => `${theme.palette.common.white} !important`,
                  color: 'text.secondary'
                }
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              sx={{
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: 'common.blue'
                },
                '&:disabled': {
                  backgroundColor: theme => theme.palette.grey[700],
                  color: 'common.white'
                }
              }}
              startIcon={
                (loading == 'UPDATE_SUBSCRIPTION' ||
                  loading === 'UPDATE_NON_GOOGLE_SUBSCRIPTION' ||
                  loading === 'UPDATE_CUSTOMER_SUBSCRIPTION') && (
                  <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                )
              }
              onClick={submitDiscount}
              disabled={
                (value === '1' &&
                  subVendorDiscount?.value === discount &&
                  (subMarginDiscount?.value || 0) === discountMargin &&
                  moment(startDate).format('YYYY-MM-DD') === moment(initialStartDate).format('YYYY-MM-DD') &&
                  moment(endDate).format('YYYY-MM-DD') === moment(initialEndDate).format('YYYY-MM-DD')) ||
                (value === '2' &&
                  initialSubscriptionPricingBooks.pricingBookId === subscriptionPricingBooks.pricingBookId &&
                  moment(initialSubscriptionPricingBooks.startDate).format('YYYY-MM-DD') ===
                    moment(subscriptionPricingBooks.startDate).format('YYYY-MM-DD') &&
                  moment(initialSubscriptionPricingBooks.endDate).format('YYYY-MM-DD') ===
                    moment(subscriptionPricingBooks.endDate).format('YYYY-MM-DD')) ||
                (value === '4' && parseInt(subMargin?.margin || 0) === parseInt(margin)) ||
                discount < 0 ||
                discount > 100 ||
                startDate > endDate ||
                loading == 'UPDATE_SUBSCRIPTION' ||
                loading === 'UPDATE_NON_GOOGLE_SUBSCRIPTION' ||
                loading === 'UPDATE_CUSTOMER_SUBSCRIPTION'
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </Fragment>
  )
}

export default SubscriptionEdit
