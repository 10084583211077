// ** React Imports
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import Table from '@mui/material/Table'
import IconButton from '@mui/material/IconButton'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import DeleteIcon from 'mdi-material-ui/CloseCircle'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import moment from 'moment'

// ** Custom Components Imports
import FormInput from '../components/FormInput'
import DeleteRuleDialog from '../components/DeleteRuleDialog'
import SelectForm from '../components/SelectForm'
import DatePickerForm from '../components/DatePickerForm'

// ** Styled Components
import FallbackSpinner from 'core/components/spinner'
import AlertMessages from 'pages/Billing/components/AlertMessages'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector, cleanMessages, getProductsData } from 'store/products'
import { getAdminsGroup, getRuleData, newRule, resetRule, rulesSelector, updateRule } from 'store/rules'

// ** Custom Field Form

const schema = yup.object().shape({
  name: yup.string().required('Name id is required').typeError('Name id is required'),
  product_family: yup.string().required('Product family is required').typeError('Product family is required'),
  // price_plan_id: yup.string().required('Price plan is required').typeError('Price plan is required'),
  // commitment_duration_id: yup
  //   .string()
  //   .required('commitment duration is required')
  //   .typeError('commitment duration is required'),
  // form: yup.date().required('Start date is required'),
  // to: yup.date().required('End date is required'),
  //.min(yup.ref('form'), 'Date must be later than From date'),
  commission_supervisor: yup
    .string()
    .required('Commission supervisor is required')
    .typeError('Commission supervisor is required'),
  commission_sales_person: yup
    .string()
    .required('Commission sales is required')
    .typeError('Commission sales is required')
})

const Container = styled(Grid)(({ theme }) => ({
  marginTop: 5,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

// ** Helpers
const initialValues = {
  name: '',
  from: moment(),
  to: moment().add(1, 'days'),
  status: 'active',
  products: [],
  price_plan: null,
  product_family: 'Workspace',
  group_name: null,
  group_email: '',
  commitment_duration: null,
  engagement_type: null,
  commission_supervisor: 0,
  commission_sales_person: 0,
  details: [],
  deletedDetails: []
}

const RuleForm = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  // ** Selectors
  const { rule, adminsGroup, success, error, loading } = useSelector(rulesSelector)
  const { products } = useSelector(productsSelector)

  // ** State
  const [family, setFamily] = useState('Workspace')

  // ** Hooks
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  // ** Fetch data
  useEffect(() => {
    if (id) {
      dispatch(getRuleData(id))
    }
    dispatch(getAdminsGroup())
    dispatch(getProductsData({ limit: 1000, page: 1, field: '' }))
    return () => {
      dispatch(resetRule())
    }
  }, [id])

  // ** Memoize rule initial data
  useEffect(() => {
    if (rule.id) {
      reset({
        ...rule,
        from: moment(rule.from),
        to: moment(rule.to),
        deletedDetails: []
      })
    }
  }, [rule])

  useEffect(() => {
    if (products?.length > 0 && !id) {
      setValue(
        'products',
        products?.filter(product => !product.is_isv_product && !product.is_premiercloud_product)
      )
    }
  }, [products])
  // ** Functions
  const cancelHandler = () => navigate('/commissions/rules')

  const handleProductFamily = event => {
    if (event.target.value === 'Workspace')
      setValue(
        'products',
        products?.filter(product => !product.is_isv_product && !product.is_premiercloud_product)
      )
    else setValue('products', [])
    setValue('details', [])
    setFamily(event.target.value)
  }

  const onSubmit = (data, e) => {
    const details = watch('products')?.map(prod => ({
      id: Math.random().toString(36).substring(2, 6),
      product_id: prod.id,
      engagement_type: data.engagement_type,
      price_plan: data.price_plan,
      commitment_duration: data.commitment_duration,
      commission_sales_person: parseFloat(data.commission_sales_person || 0),
      commission_supervisor: parseFloat(data.commission_supervisor || 0)
    }))
    setValue('details', [...watch('details'), ...details])
    setValue('commission_sales_person', 0)
    setValue('commission_supervisor', 0)
    setValue('commitment_duration', null)
    setValue('price_plan', null)
    setValue('products', [])
    setValue('engagement_type', null)
  }

  const saveRuleHandler = event => {
    event.stopPropagation()
    trigger()
    if (watch('name')) {
      if (id) {
        const body = {
          name: watch('name'),
          status: watch('status'),
          from: watch('from').format('YYYY-MM-DD'),
          to: watch('to').format('YYYY-MM-DD'),
          product_family: watch('product_family'),
          group_email: watch('group_email'),
          group_name: adminsGroup?.find(el => el.email === watch('group_email'))?.name || '',
          details:
            watch('details')?.map(item => {
              const { id, ...rest } = item
              if (typeof id === 'string') return rest
              else return item
            }) || [],
          ...(watch('deletedDetails')?.length > 0 ? { deletedDetails: watch('deletedDetails') } : {})
        }
        dispatch(updateRule(id, body))
      } else {
        const body = {
          name: watch('name'),
          status: watch('status'),
          from: watch('from').format('YYYY-MM-DD'),
          to: watch('to').format('YYYY-MM-DD'),
          product_family: watch('product_family'),
          group_email: watch('group_email'),
          group_name: adminsGroup?.find(el => el.email === watch('group_email'))?.name || '',
          details:
            watch('details')?.map(item => {
              const { id, ...rest } = item
              if (typeof id === 'string') return rest
              else return item
            }) || []
        }
        dispatch(newRule(body, navigate))
      }
    }
  }

  const resetMessagesHandler = () => dispatch(cleanMessages())

  const deleteItemHandler = id => {
    const details = [...watch('details')]
    setValue(
      'details',
      details.filter(item => item.id !== id)
    )
    setValue('deletedDetails', [...watch('deletedDetails'), id])
  }

  return (
    <Card
      sx={{
        boxShadow: 0,
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        py: 10,
        px: 5
      }}
    >
      {loading == 'GET_RULE' && <FallbackSpinner />}
      {!loading !== 'GET_RULE' && (
        <form onSubmit={handleSubmit(onSubmit)} id='rule-form'>
          <Container container spacing={5}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h5' sx={{ mb: '1rem' }}>
                Rule informations
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                {id && (
                  <Box>
                    <DeleteRuleDialog ruleId={id} />
                  </Box>
                )}
                <Button
                  size='medium'
                  variant='text'
                  sx={{ color: theme => theme.palette.secondary.light, mx: '0.5rem', fontWeight: 600 }}
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading === 'CREATE_RULE' || loading === 'UPDATE_RULE'}
                  startIcon={
                    (loading === 'CREATE_RULE' || loading === 'UPDATE_RULE') && (
                      <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                    )
                  }
                  sx={{
                    borderRadius: '3px',
                    '&:hover': { backgroundColor: 'common.blue' },
                    '&:disabled': { backgroundColor: theme => theme.palette.secondary.light }
                  }}
                  size='medium'
                  variant='contained'
                  onClick={saveRuleHandler}
                >
                  {id ? 'Update Rule' : 'Create Rule'}
                </Button>
              </Box>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} lg={3}>
                <FormInput
                  control={control}
                  errors={errors}
                  fullWidth
                  name='name'
                  placeholder='Rule one...'
                  label='Rule name'
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DatePickerForm control={control} errors={errors} fullWidth name='from' label='From' />
              </Grid>
              <Grid item xs={12} lg={3}>
                <DatePickerForm control={control} errors={errors} fullWidth name='to' label='To' />
              </Grid>
              <Grid item xs={12} lg={3}>
                <SelectForm
                  control={control}
                  errors={errors}
                  fullWidth
                  name='product_family'
                  label='Product Family'
                  options={[
                    { label: 'Workspace', value: 'Workspace' },
                    { label: 'Project', value: 'Project' },
                    { label: 'GCP', value: 'GCP' },
                    { label: 'ISV', value: 'isv' }
                  ]}
                  callBack={handleProductFamily}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <SelectForm
                  control={control}
                  errors={errors}
                  fullWidth
                  name='status'
                  placeholder='status...'
                  label='Status'
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' }
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <SelectForm
                  control={control}
                  errors={errors}
                  fullWidth
                  name='group_email'
                  placeholder='group...'
                  label='Group Email'
                  options={adminsGroup?.map(el => ({ label: el.email, value: el.email })) || []}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={3}>
              <Typography variant='h5'>Product informations</Typography>
            </Grid>
            <Grid item container spacing={4} xs={12}>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <Controller
                    name='products'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        multiple
                        limitTags={2}
                        disableCloseOnSelect
                        value={value ?? []}
                        onChange={(event, values) => onChange(values)}
                        sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
                        options={
                          family === 'GCP' || family === 'Project'
                            ? []
                            : family === 'isv'
                            ? products?.filter(product => product.is_isv_product)
                            : products?.filter(
                                product =>
                                  family === 'Workspace' && !product.is_isv_product && !product.is_premiercloud_product
                              ) || []
                        }
                        getOptionLabel={option => option.skuName}
                        renderInput={params => <TextField {...params} label='Products' />}
                      />
                    )}
                  />
                  {errors.products && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-products'>
                      {errors.products.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SelectForm
                  control={control}
                  errors={errors}
                  fullWidth
                  name='price_plan'
                  label='Price Plan'
                  options={[
                    { label: 'Flexible', value: 'flexible' },
                    { label: 'Annual', value: 'annual' }
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <SelectForm
                  control={control}
                  errors={errors}
                  fullWidth
                  name='commitment_duration'
                  label='Commitment Duration'
                  options={[
                    { label: '12 months', value: 12 },
                    { label: '24 months', value: 24 },
                    { label: '36 months', value: 36 },
                    { label: '60 months', value: 60 }
                  ]}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <SelectForm
                  control={control}
                  errors={errors}
                  fullWidth
                  name='engagement_type'
                  label='Engagement type'
                  options={[
                    { label: 'New', value: 'new' },
                    { label: 'Renewal', value: 'renewal' },
                    { label: 'Upsell', value: 'upsell' }
                  ]}
                />
              </Grid>

              <Grid item xs={12} lg={3}>
                <FormInput
                  control={control}
                  errors={errors}
                  fullWidth
                  name='commission_sales_person'
                  placeholder='10%...'
                  label='Commission sales'
                  type='number'
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormInput
                  control={control}
                  errors={errors}
                  fullWidth
                  name='commission_supervisor'
                  placeholder='10%...'
                  type='number'
                  label='Commission supervisor'
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Button
                  sx={{
                    borderRadius: '3px',
                    '&:hover': { backgroundColor: 'common.blue' },
                    '&:disabled': { backgroundColor: theme => theme.palette.secondary.light }
                  }}
                  size='medium'
                  type='submit'
                  form='rule-form'
                  variant='contained'
                >
                  Add Product
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} mt={8}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Product</TableCell>
                      <TableCell align='left'>Price Plan</TableCell>
                      <TableCell align='left'>Commitment Duration</TableCell>
                      <TableCell align='left'>Engagement Type</TableCell>
                      <TableCell align='left'>Commission Sales</TableCell>
                      <TableCell align='left'>Commission Supervisor</TableCell>
                      <TableCell align='left' />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {watch('details')?.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell sx={{ maxWidth: 150 }}>
                          <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {products?.find(prod => prod.id === item.product_id)?.skuName || '------'}
                          </Typography>
                        </TableCell>
                        <TableCell align='left'>{item.price_plan || '------'}</TableCell>
                        <TableCell align='left'>{item.commitment_duration || '------'}</TableCell>
                        <TableCell align='left'>{item.engagement_type || '------'}</TableCell>
                        <TableCell align='left'>{item.commission_sales_person || '------'}</TableCell>
                        <TableCell align='left'>{item.commission_supervisor || '------'}</TableCell>
                        <TableCell align='center'>
                          <IconButton size='small' onClick={() => deleteItemHandler(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Container>
        </form>
      )}
      <AlertMessages
        success={success}
        error={error}
        messageValue={success === 'UPDATE_RULE' ? 'UPDATE_RULE' : 'CREATE_RULE'}
        message={
          (success === 'UPDATE_RULE' && 'Rule was updated successfully !') ||
          (success === 'CREATE_RULE' && 'Rule was created successfully !') ||
          (error && error.message)
        }
        resetHandler={resetMessagesHandler}
      />
    </Card>
  )
}

export default RuleForm
