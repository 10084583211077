// ** React Imports
import React from 'react'
import { useNavigate } from 'react-router-dom';

// ** MUI Imports
import { styled } from '@mui/material/styles'
import { Grid, Button, useMediaQuery, CircularProgress } from "@mui/material"

// ** Icons Imports
import RefreshIcon from 'mdi-material-ui/Refresh'

// ** Redux Imports
import { useDispatch } from 'react-redux';
import { synchronizeCustomersList } from 'store/customers';



// Styled Card component
const CustomButton = styled(Button)(({ theme }) => ({
    mx: 1,
    borderRadius: "5px !important",
    fontWeight: 600,
    color: "common.white",
    fontSize: "0.825rem"
}))


const SynchronizeCustomers = (props) => {

    // ** Props
    const { loading, pagination } = props
    // ** Hooks
    const dispatch = useDispatch()
    const xsmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

    // ** Fucntions
    const synchronizeHandler = () => {
        dispatch(synchronizeCustomersList(pagination))
    }

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Grid item xs={10} sm={6} sx={{ whiteSpace: "nowrap" }}>
            <CustomButton
                onClick={synchronizeHandler}
                variant='contained'
                startIcon={<RefreshIcon />}
                endIcon={loading === "SYNCHRONIZE_CUSTOMERS" && <CircularProgress size="1rem" sx={{ color: "#fff" }} />}

            >
                Synchronize customers
            </CustomButton>
        </Grid>
    )
}


export default SynchronizeCustomers;