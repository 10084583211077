// ** React Imports
import React from 'react'
import { useNavigate } from 'react-router-dom';

// ** MUI Imports
import { styled } from '@mui/material/styles'
import { Grid, Button, useMediaQuery } from "@mui/material"

// ** Icons Imports
import PlusIcon from 'mdi-material-ui/Plus'



// Styled Card component
const CustomButton = styled(Button)(({ theme }) => ({
    mx: 1,
    borderRadius: "5px !important",
    fontWeight: 600,
    color: "common.white",
    fontSize: "0.825rem"
}))


const AddNewCustomer = () => {

    // ** Hooks
    const navigate = useNavigate()
    const xsmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

    // ** Fucntions
    const redirectHandler = () => navigate("/google-customers/new-customer")

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Grid item xs={10} sm={6} sx={{ whiteSpace: "nowrap" }}>
            <CustomButton
                onClick={redirectHandler}
                variant='contained'
                startIcon={<PlusIcon />}
            >
                New Onboarding
            </CustomButton>
        </Grid>
    )
}


export default AddNewCustomer;