// ** React Imports
import React from 'react'

// ** MUI Imports
import { useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Autocomplete from '@mui/material/Autocomplete'

// ** Third Party Imports
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

// ** Icons
import CloseIcon from '@mui/icons-material/Close'

const AutocompleteForm = props => {
  // ** Props
  const {
    name,
    control,
    label,
    placeholder,
    errors,
    fullWidth,
    size,
    options,
    getOptionLabel,
    multiple,
    disabled,
    defaultValue,
    freeSolo,
    isOptionEqualToValue
  } = props

  // ** Hooks
  const theme = useTheme()

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            freeSolo={freeSolo}
            disabled={disabled}
            multiple={multiple}
            value={value}
            options={options || []}
            onChange={(e, value) => onChange(value)}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            sx={{
              // '& fieldset': { borderRadius: 0 },
              '.MuiAutocomplete-tag': { background: '#fff', border: theme => `1px solid ${theme.palette.grey[300]}` }
            }}
            ChipProps={{
              deleteIcon: (
                <CloseIcon
                  sx={{
                    ...(!disabled ? { color: theme => `${theme.palette.grey[500]} !important` } : {}),
                    fontSize: '18px !important'
                  }}
                />
              ),
              style: {
                ...(!disabled ? { color: theme.palette.grey[600] } : {})
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                size={size || 'medium'}
                label={label}
                placeholder={placeholder}
                error={Boolean(errors?.[name])}
                InputLabelProps={{
                  sx: {
                    color: theme => theme.palette.grey[500],
                    fontSize: '0.875rem'
                  }
                }}
              />
            )}
          />
        )}
      />
      {errors?.[name] && (
        <FormHelperText sx={{ color: 'error.main' }} id={`validation-basic-${name}`}>
          {errors?.[name].message}
        </FormHelperText>
      )}
    </FormControl>
  )
}

AutocompleteForm.propTypes = {
  control: PropTypes.object.isRequired, // Required string prop
  errors: PropTypes.object.isRequired, // Optional string prop
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  size: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.object,
  freeSolo: PropTypes.bool
}

export default AutocompleteForm
