// ** React Imports
import React, { useState, Fragment, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'

// ** Icons Imports
import { products } from '@fake-db/products'
import Reload from 'mdi-material-ui/Reload'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { getProductsData, productsSelector } from 'store/products'
import { customersSelector, fetchBoardedCustomers } from 'store/customers'

// ** 3rd Part Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

// ** APIs
import { recalculateDailyUsage } from 'configs/axios/api_helper'
import CustomAutocomplete from '../components/CustomAutocomplete'
import ProductsAutocomplete from '../components/ProductsAutocomplete'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  [theme.breakpoints.down('md')]: {
    minHeight: 'calc(100vh - 80px)'
  }
}))

// Styled Img component
const Img = styled('img')(({ theme }) => ({
  height: 20,
  width: 20,
  marginLeft: 5
}))

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const renderInput = (name, value, onChange, error, helperText) => {
  console.log('errors', error)
  return (
    <TextField
      variant='outlined'
      size='small'
      type='number'
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      helperText={error && 'price must be positive'}
      // InputProps={{ inputProps: { min: 1 } }}
      sx={{ width: 150 }}
    />
  )
}

const Row = props => {
  // ** Hooks
  const navigate = useNavigate()

  // ** Props
  const { row, key, setCustomerData } = props

  // ** State
  const [errors, setErrors] = useState([])

  // ** Constants
  const priceCad = row.priceCad || 0
  const priceUsd = row.priceUsd || 0
  const priceEur = row.priceEur || 0
  const flexiblePriceCad = row.flexiblePriceCad || 0
  const flexiblePriceUsd = row.flexiblePriceUsd || 0
  const flexiblePriceEur = row.flexiblePriceEur || 0

  // ** Functions

  const changePrice = event => {
    const { name, value } = event.target
    console.log('name', name, value)
    if (value < 0) setErrors([...errors, name])
    else setErrors(errors.filter(item => item !== name))
    setCustomerData(state => ({
      ...state,
      prices_array: state?.prices_array?.map(product =>
        product.skuId === row.skuId ? { ...product, [name]: parseFloat(value) } : product
      )
    }))
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell align='left'>{row.productId}</TableCell>
        <TableCell align='left'>{row.productName}</TableCell>
        <TableCell align='left'>{row.skuId || '------'}</TableCell>
        <TableCell width={250} align='left'>
          {renderInput('priceCad', priceCad, changePrice, errors.includes('priceCad'))}
        </TableCell>
        <TableCell width={250} align='left'>
          {renderInput('priceUsd', priceUsd, changePrice, errors.includes('priceUsd'))}
        </TableCell>
        <TableCell width={250} align='left'>
          {renderInput('priceEur', priceEur, changePrice, errors.includes('priceEur'))}
        </TableCell>
        <TableCell width={250} align='left'>
          {renderInput('flexiblePriceCad', flexiblePriceCad, changePrice, errors.includes('flexiblePriceCad'))}
        </TableCell>
        <TableCell width={250} align='left'>
          {renderInput('flexiblePriceUsd', flexiblePriceUsd, changePrice, errors.includes('flexiblePriceUsd'))}
        </TableCell>
        <TableCell width={250} align='left'>
          {renderInput('flexiblePriceEur', flexiblePriceEur, changePrice, errors.includes('flexiblePriceEur'))}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const Recalculation = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Selectors
  const { boardedCustomers, loading: loadingCustomers } = useSelector(customersSelector)
  const { products } = useSelector(productsSelector)

  // ** State
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [customerData, setCustomerData] = useState({
    customers_id: [],
    prices_array: [],
    startDate: new Date(),
    endDate: new Date(),
    change_price: 'false',
    change_discount: 'false'
  })
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: '' })
  const [paginationFilters, setPaginationFilters] = useState({
    limit: 20,
    page: 1,
    field: ''
  })

  // ** Fetch Tax data
  useEffect(() => {
    dispatch(fetchBoardedCustomers({ limit: 1000, page: 1, organization: '' }))
    dispatch(getProductsData({ limit: 1000, page: 1, field: '' }))
  }, [])

  // ** Functions
  const handleChangePage = (event, newPage) => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPaginationFilters({
      ...paginationFilters,
      page: ++newPage
    })
  }

  const handleChangeRowsPerPage = event => {
    setPaginationFilters({
      ...paginationFilters,
      limit: event.target.value
    })
  }

  const handleCustomerParameter = (name, value) => {
    setCustomerData({ ...customerData, [name]: value })
  }

  const triggerRecalculations = async () => {
    setLoading(true)
    const prices_array = customerData.prices_array?.map(item => ({
      skuId: item.skuId,
      priceCad: item.priceCad,
      priceUsd: item.priceUsd,
      priceEur: item.priceEur,
      flexiblePriceUsd: item.flexiblePriceUsd,
      flexiblePriceCad: item.flexiblePriceCad,
      flexiblePriceEur: item.flexiblePriceEur
    }))
    const customers_id = customerData.customers_id?.map(item => item.id?.toString())
    const negative_numbers = prices_array?.some(
      item =>
        item.priceCad < 0 ||
        item.priceUsd < 0 ||
        item.priceEur < 0 ||
        item.flexiblePriceCad < 0 ||
        item.flexiblePriceUsd < 0 ||
        item.flexiblePriceEur < 0
    )
    if (negative_numbers) {
      setMessage({ success: false, error: true, value: 'Please change the negative prices.' })
      setLoading(false)
    } else {
      const body = {
        ...customerData,
        customers_id,
        prices_array,
        startDate: moment(customerData.startDate).format('YYYY-MM-DD'),
        endDate: moment(customerData.endDate).format('YYYY-MM-DD')
      }
      try {
        const response = await recalculateDailyUsage(body)
        setLoading(false)
        setMessage({ success: true, error: false, value: 'Recalculations was done successfully' })
      } catch (error) {
        setLoading(false)
        setMessage({ success: false, error: true, value: 'There was an error while doing the recalculations' })
        console.log('error', error)
      }
    }
  }

  const handleCloseMessage = () => setMessage({ success: false, error: false, value: '' })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledCard
        sx={{
          position: 'relative',
          '& .customer-column-header': {
            backgroundColor: 'common.white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600
            }
          }
        }}
      >
        <Grid container spacing={4} p={5}>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={12} lg={6}>
              <CustomAutocomplete
                boardedCustomers={boardedCustomers}
                handleCustomerParameter={handleCustomerParameter}
                customerData={customerData}
                loadingCustomers={loadingCustomers}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ProductsAutocomplete
                products={products}
                handleCustomerParameter={handleCustomerParameter}
                customerData={customerData}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={12} lg={6} xl={4}>
              <DesktopDatePicker
                label='Start date'
                inputFormat='MM/DD/YYYY'
                value={customerData.startDate}
                maxDate={new Date(customerData.endDate)}
                onChange={newValue => handleCustomerParameter('startDate', newValue)}
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <DesktopDatePicker
                label='End date'
                inputFormat='MM/DD/YYYY'
                value={customerData.endDate}
                minDate={new Date(customerData.startDate)}
                onChange={newValue => handleCustomerParameter('endDate', newValue)}
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small' } }}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} xl={4}>
              <FormGroup row sx={{ gap: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={event => {
                        if (event.target.checked) handleCustomerParameter('change_price', 'true')
                        else handleCustomerParameter('change_price', 'false')
                      }}
                    />
                  }
                  label='change prices'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={event => {
                        if (event.target.checked) handleCustomerParameter('change_discount', 'true')
                        else handleCustomerParameter('change_discount', 'false')
                      }}
                    />
                  }
                  label='change discounts'
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              size='medium'
              sx={{
                mx: 1,
                borderRadius: '5px !important',
                fontWeight: 600,
                color: 'common.white',
                fontSize: '0.825rem',
                whiteSpace: 'nowrap'
              }}
              onClick={triggerRecalculations}
              startIcon={loading && <CircularProgress sx={{ color: 'common.white' }} size='1rem' />}
              endIcon={<Reload />}
            >
              Generate Recalculations
            </Button>
          </Grid>
        </Grid>
        {customerData.prices_array?.length > 0 && (
          <Box sx={{ position: 'relative' }}>
            <TableContainer component={Paper}>
              <Table aria-label='collapsible table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Subscription ID</TableCell>
                    <TableCell align='left'>Product Name</TableCell>
                    <TableCell align='left'>SKU ID</TableCell>
                    <TableCell align='left'>Price CAD</TableCell>
                    <TableCell align='left'>Price USD</TableCell>
                    <TableCell align='left'>Price EUR</TableCell>
                    <TableCell align='left'>Flexible CAD</TableCell>
                    <TableCell align='left'>Flexible USD</TableCell>
                    <TableCell align='left'>Flexible EUR</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerData?.prices_array?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(row => (
                    <Row key={row.id} row={row} setCustomerData={setCustomerData} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        <Snackbar
          sx={{ mt: '3rem' }}
          open={message.success || message.error}
          onClose={handleCloseMessage}
          autoHideDuration={1500}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
          message={<Box sx={{ textAlign: 'center' }}>{message.value}</Box>}
        />
      </StyledCard>
    </LocalizationProvider>
  )
}

export default Recalculation
