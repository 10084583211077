// ** React Imports
import React, { forwardRef, Fragment, useEffect, useState } from 'react'

// ** axios Import
import { checkBilledDays } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

// ** Third Party imports
import moment from 'moment'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, editSubDiscount, generateInvoice } from 'store/customers'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const GenerateInvoiceModal = props => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Props
  const { customers } = props
  // ** State
  const [open, setOpen] = useState(false)
  const [endDate, setEndDate] = useState(new Date().setDate(new Date().getDate() + 1))
  const [startDate, setStartDate] = useState(new Date())
  const [type, setType] = useState('rear')
  const [message, setMessage] = useState({ status: '', invoice: {}, item: {}, loading: false })

  // ** Selectors
  const { success, error, loading } = useSelector(customersSelector)

  useEffect(() => {
    if (
      success == 'GENERATE_INVOICE' ||
      error == 'GENERATE_INVOICE' ||
      error == 'ADVANCE_INVOICE' ||
      error == 'DAILY_USAGE'
    ) {
      setMessage({ status: '', invoice: {}, item: {}, loading: false })
      setOpen(false)
    }
  }, [success, error])

  // ** Fucntions
  const handleClickOpen = () => setOpen(true)
  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick' && 'escapeKeyDown') return
    setOpen(false)
  }

  const closeHandler = () => {
    setOpen(false)
    setMessage({ status: '', invoice: {}, item: {}, loading: false })
  }
  const generateInvoiceHandler = async () => {
    if (customers.length > 0 && message.status === '') {
      setMessage({ ...message, loading: true })
      try {
        const query = `?from=${moment(startDate).format('YYYY-MM-DD')}&to=${moment(endDate).format(
          'YYYY-MM-DD'
        )}&type=${type}&customerId=${customers[0]}`
        const { data: validateData } = await checkBilledDays(query)
        if (!validateData?.error) {
          setMessage({ ...message, loading: false })
          dispatch(
            generateInvoice({
              invoice: {
                customers: customers,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
              },
              type: type
            })
          )
        } else if (validateData?.error) {
          setMessage({
            status: validateData.status,
            invoice: validateData.invoice,
            item: validateData.item,
            loading: false
          })
        }
      } catch (error) {
        setMessage({ ...message, loading: false })
      }
    }
    if (customers.length > 0 && message.status === 'warning') {
      dispatch(
        generateInvoice({
          invoice: {
            customers: customers,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD')
          },
          type: type
        })
      )
    }
  }

  const onChangeInAdvance = () => {
    setStartDate(new Date().setDate(new Date().getDate() + 1))
    setEndDate(new Date().setDate(new Date().getDate() + 30))
    setType('advance')
  }

  const onChangeStartDateHandler = date => {
    setStartDate(date)
    setEndDate(new Date(date).setDate(new Date(date).getDate() + 29))
  }

  return (
    <Fragment>
      <Button
        disabled={customers.length < 1}
        variant='text'
        onClick={handleClickOpen}
        sx={{
          ml: 'auto',
          boxShadow: 0,
          '&:disabled': {
            color: theme => theme.palette.grey[700]
          }
        }}
      >
        Generate Invoice
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              minWidth: '40%' // Set your width here
            }
          }
        }}
      >
        <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
          {message.status === '' && (
            <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Generate Invoice</Typography>
          )}
          {message.status === 'warning' && (
            <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Important Notice</Typography>
          )}
          {message.status === 'danger' && (
            <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Critical Notice</Typography>
          )}
        </DialogTitle>
        <DialogContent>
          {!message.loading && !loading && message.status === '' && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography>Select billing type</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', mt: '-1rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={type === 'rear'} onClick={() => setType('rear')} />
                  <Typography sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>Rear</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={type === 'advance'} onClick={onChangeInAdvance} />
                  <Typography sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>in Advance</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography>Select billing range</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label='Start date'
                    inputFormat='MM/DD/YYYY'
                    value={startDate}
                    minDate={type === 'advance' ? new Date().setDate(new Date().getDate() + 1) : null}
                    onChange={onChangeStartDateHandler}
                    sx={{ width: '100%' }}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label='End date'
                    inputFormat='MM/DD/YYYY'
                    value={endDate}
                    minDate={type === 'advance' ? new Date().setDate(new Date().getDate() + 1) : null}
                    onChange={newValue => setEndDate(newValue)}
                    sx={{ width: '100%' }}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}
          {!message.loading && !loading && message.status === 'warning' && message.invoice?.status === 'draft' && (
            <Grid item xs={12}>
              <Typography>
                Please note that some dates included in the requested invoice generation period have already been
                covered in a previous invoice dating from{' '}
                {moment(new Date(message.invoice?.issue_date)).format('MMMM Do, YYYY')} ,{' '}
                {moment(new Date(message.invoice?.due_date)).format('MMMM Do, YYYY')}.<br />
                Generating the new invoice will result in overriding the charges for those dates on the previous
                invoice.
                <br />
                <br />
                Are you sure you want to proceed with generating the new invoice?
                {/* This day {moment(new Date(message.item?.day)).format("DD MMM, YYYY")} has been covered in the invoice
                                ({moment(new Date(message.invoice?.issue_date)).format("DD MMM, YYYY")} - {moment(new Date(message.invoice?.due_date)).format("DD MMM, YYYY")}) would you like to continue ? */}
              </Typography>
            </Grid>
          )}
          {!message.loading && !loading && message.status === 'warning' && message.invoice?.status === 'paid' && (
            <Grid item xs={12}>
              <Typography>
                Please note that some dates included in the requested invoice generation period have already been
                covered in a previous invoice dating from{' '}
                {moment(new Date(message.invoice?.issue_date)).format('MMMM Do, YYYY')} ,{' '}
                {moment(new Date(message.invoice?.due_date)).format('MMMM Do, YYYY')}&nbsp; which has been already paid.
                <br />
                Generating a new invoice for those dates will indicate the already paid period on the new invoice. It is
                recommended to verify the previous invoice and ensure that it accurately reflects the charges for the
                relevant period before proceeding with generating a new invoice to avoid any confusion for the customer.
                <br />
                <br />
                Are you sure you want to proceed with generating the new invoice?
              </Typography>
            </Grid>
          )}
          {!message.loading && !loading && message.status === 'danger' && (
            <Grid item xs={12}>
              <Typography>
                Please be aware that some dates included in the requested invoice generation period have already been
                covered in a previous invoice dating from{' '}
                {moment(new Date(message.invoice?.issue_date)).format('MMMM Do, YYYY')} ,{' '}
                {moment(new Date(message.invoice?.due_date)).format('MMMM Do, YYYY')}&nbsp; which has been already sent
                to the customer.
                <br />
                It is not possible to generate a new invoice for those dates without first changing the status of the
                previous invoice to "Draft" or "Paid".
              </Typography>
            </Grid>
          )}
          {(message.loading || loading) && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CircularProgress size='4rem' />
            </Grid>
          )}
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button
            sx={{
              color: 'text.secondary',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.white} !important`,
                color: 'text.secondary'
              }
            }}
            onClick={closeHandler}
          >
            Cancel
          </Button>
          {message.status !== 'danger' && (
            <Button
              variant='outlined'
              sx={{
                backgroundColor: 'common.blue',
                color: 'common.white',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: theme => `${theme.palette.common.blue} !important`,
                  color: 'common.white'
                },
                '&:disabled': {
                  backgroundColor: theme => theme.palette.grey[700],
                  borderColor: theme => theme.palette.grey[700]
                }
              }}
              onClick={generateInvoiceHandler}
              disabled={loading == 'GENERATE_INVOICE' || message.loading}
            >
              {(message.status === '' && 'Generate') ||
                (message.status === 'warning' && 'Continue') ||
                (message.status === 'danger' && 'Cancel')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default GenerateInvoiceModal
