// ** React Imports
import React, { useState, useEffect } from 'react'

// ** APIs
import { createBulkPricingBookSUbscription } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Autocomplete from '@mui/material/Autocomplete'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Grow from '@mui/material/Grow'
import DatePicker from '@mui/lab/DatePicker'

// ** Custom Componenets
import CustomAutocomplete from '../components/CustomAutocomplete'
import ProductsAutocomplete from '../components/ProductsAutocomplete'

// ** Third Party Libraries
import moment from 'moment'

// ** Icons Imports
import CheckIcon from 'mdi-material-ui/Check'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, fetchBoardedCustomers } from 'store/customers'
import { fetchPricingbooksData, pricingBooksSelector } from 'store/metaData/pricingbook'
import { getProductsData, productsSelector } from 'store/products'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  [theme.breakpoints.down('md')]: {
    minHeight: 'calc(100vh - 80px)'
  }
}))

// Styled Img component
const Img = styled('img')(({ theme }) => ({
  height: 20,
  width: 20,
  marginLeft: 5
}))

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const BulkCustomersPricingBooks = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Selectors
  const { boardedCustomers, loading: loadingCustomers } = useSelector(customersSelector)
  const { pricingBooksData } = useSelector(pricingBooksSelector)
  const { products } = useSelector(productsSelector)

  // ** State
  const [data, setData] = useState({
    customers: [],
    products: [],
    pricingBookId: '',
    startDate: new Date(),
    endDate: new Date()
  })
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: '' })

  // ** Fetch Tax data
  useEffect(() => {
    dispatch(fetchBoardedCustomers({ limit: 1000, page: 1, organization: '' }))
    dispatch(fetchPricingbooksData({ limit: 1000, page: 1 }))
    dispatch(getProductsData({ limit: 1000, page: 1, field: '' }))
  }, [])

  // ** Functions
  const optionRenderer = (props, option, { selected }) => {
    return (
      <li {...props} key={option.id}>
        {option?.name ? `${option?.name} ${option?.is_default ? '(default)' : ''}` : ''}
      </li>
    )
  }
  const pricingBookHandler = (event, option) => {
    setData(state => ({ ...state, pricingBookId: option?.id || '' }))
  }

  const handleCloseMessage = () => setMessage({ success: false, error: false, value: '' })

  const applyPricingsHandler = async () => {
    let pricings = []
    for (const customer of data?.customers) {
      for (const product of data?.products) {
        const subscriptions = customer?.googleIds
          ?.map(item => item.subscriptions)
          ?.flat()
          ?.filter(sub => sub.skuId === product.skuId)
        const customerPricings = subscriptions?.map(sub => ({
          customerId: customer.id,
          pricingBookId: data.pricingBookId,
          subscriptionId: sub.subscriptionId,
          startDate: moment(data.startDate).format('MM-DD-YYYY'),
          endDate: moment(data.endDate).format('MM-DD-YYYY')
        }))
        pricings = [...pricings, ...customerPricings]
      }
    }
    if (pricings?.length > 0) {
      try {
        setLoading(true)
        const response = await createBulkPricingBookSUbscription({ data: pricings })
        setLoading(false)
        setMessage({ success: true, error: false, value: 'Pricing was set seccessfully !' })
      } catch (error) {
        setMessage({ success: false, error: true, value: 'There was an error, please try again.' })
        setLoading(false)
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledCard
        sx={{
          position: 'relative',
          '& .customer-column-header': {
            backgroundColor: 'common.white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600
            }
          }
        }}
      >
        <Grid container spacing={4} p={5}>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={12} lg={6}>
              <CustomAutocomplete
                boardedCustomers={boardedCustomers}
                loadingCustomers={loadingCustomers}
                setData={setData}
                data={data}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ProductsAutocomplete products={products} setData={setData} data={data} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                options={pricingBooksData ?? []}
                getOptionLabel={option =>
                  option?.name ? `${option?.name} ${option?.is_default ? '(default)' : ''}` : ''
                }
                size='small'
                onChange={pricingBookHandler}
                renderOption={optionRenderer}
                isOptionEqualToValue={(option, value) => option?.id === value?.id || value === ''}
                sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
                renderInput={params => <TextField {...params} label='Search for pricing books' />}
              />
            </Grid>
            <Grid item container spacing={2} xs={12} lg={6}>
              <Grid item xs={6}>
                <DatePicker
                  label='Start date'
                  value={data.startDate}
                  onChange={newValue => setData(state => ({ ...state, startDate: newValue }))}
                  maxDate={new Date(data.endDate)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size='small'
                      sx={{
                        width: '100%'
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label='End date'
                  value={data.endDate}
                  onChange={newValue => setData(state => ({ ...state, endDate: newValue }))}
                  minDate={new Date(data.startDate)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size='small'
                      sx={{
                        width: '100%'
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              size='medium'
              sx={{
                mx: 1,
                borderRadius: '5px !important',
                fontWeight: 600,
                color: 'common.white',
                fontSize: '0.825rem',
                whiteSpace: 'nowrap'
              }}
              disabled={data?.customers.length < 1 || !data?.pricingBookId}
              onClick={applyPricingsHandler}
              startIcon={loading && <CircularProgress sx={{ color: 'common.white' }} size='1rem' />}
              endIcon={<CheckIcon />}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          sx={{ mt: '3rem' }}
          open={message.success || message.error}
          onClose={handleCloseMessage}
          autoHideDuration={1500}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
          message={<Box sx={{ textAlign: 'center' }}>{message.value}</Box>}
        />
      </StyledCard>
    </LocalizationProvider>
  )
}

export default BulkCustomersPricingBooks
