// ** React Imports
import React, { Fragment, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

// ** Moment Imports
import moment from 'moment'

const PricingInformation = props => {
  // ** Props
  const { subscription, iso_code } = props
  // ** State
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <Typography
        onClick={handleClickOpen}
        sx={{
          fontWeight: 600,
          color: 'common.blue',
          fontSize: '0.875rem',
          cursor: 'pointer',
          whiteSpace: 'nowrap'
        }}
      >
        See payment plan
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' sx={{ fontSize: '1.25rem !important' }}>
          Pricing information
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' sx={{ px: 5 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '1.25rem' }}>
                  {subscription?.planName === 'ANNUAL_YEARLY_PAY' && 'Annual Plan (Monthly Payment)'}
                  {subscription?.planName === 'ANNUAL' && 'Annual Plan (Monthly Payment)'}
                  {subscription?.planName === 'FLEXIBLE' && 'Flexible Plan'}
                  {subscription?.planName === 'FREE' && 'Free Plan'}
                  {subscription?.planName === 'TRIAL' && 'Trial Plan'}
                </Typography>
                <Typography sx={{ fontSize: '0.925rem', mt: 2 }}>
                  {(subscription?.planName === 'ANNUAL_YEARLY_PAY' || subscription?.planName === 'ANNUAL') &&
                    'Commit to a 12 month Service plan. You will be billed at the end of the month based on your committed number of licenses. Additional licenses may be added to the commitment at any time.'}
                  {subscription?.planName === 'FLEXIBLE' &&
                    'Add and delete users at any time. You will be billed at the end of the month for the actual number of accounts in service during the month.'}
                  {subscription?.planName === 'FREE' && 'Plan in which user gets service free of cost.'}
                  {subscription?.planName === 'TRIAL' &&
                    'Commit to a 12 month Service plan. You will be billed at the end of the month based on your committed number of licenses. Additional licenses may be added to the commitment at any time.'}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 5 }}>
                {subscription?.discount && !(subscription?.planName === 'FREE') && (
                  <>
                    <Typography sx={{ fontWeight: 600, fontSize: '0.825rem', textTransform: 'uppercase', mb: 2 }}>
                      until {moment(new Date(subscription?.discount?.endDate)).format('MMM DD, YYYY')}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ fontWeight: 600 }}>
                        {iso_code === 'EUR' ? 'EUR €' : `${iso_code} $`}
                        {iso_code === 'USD'
                          ? (subscription?.product?.priceUsd -
                            (subscription?.product?.priceUsd * subscription?.discount?.value) / 100)?.toFixed(2)
                          : iso_code === 'EUR'
                          ? (subscription?.product?.priceEur -
                            (subscription?.product?.priceEur * subscription?.discount?.value) / 100)?.toFixed(2)
                          : (subscription?.product?.priceCad -
                            (subscription?.product?.priceCad * subscription?.discount?.value) / 100)?.toFixed(2)}
                      </Typography>
                      <Typography sx={{ fontSize: '0.925rem', ml: '0.5rem' }}> /license/month</Typography>
                    </Box>
                    <Typography sx={{ fontSize: '0.825rem', color: 'success.main', mb: 3 }}>
                      {subscription?.discount?.value}% discount
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: '0.825rem', textTransform: 'uppercase', mb: 2 }}>
                      after {moment(new Date(subscription?.discount?.endDate)).format('MMM DD, YYYY')}
                    </Typography>
                    <Box sx={{ display: 'flex', mb: 3 }}>
                      <Typography sx={{ fontWeight: 600, mb: 1 }}>
                        {iso_code === 'EUR' ? 'EUR €' : `${iso_code} $`}
                        {iso_code === 'USD'
                          ? subscription?.product?.priceUsd
                          : iso_code === 'EUR'
                          ? subscription?.product?.priceEur
                          : subscription?.product?.priceCad}
                      </Typography>
                      <Typography sx={{ fontSize: '0.925rem', ml: '0.5rem' }}> /license/month</Typography>
                    </Box>
                  </>
                )}
                {!subscription?.discount && !(subscription?.planName === 'FREE') && (
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {iso_code === 'EUR' ? '€' : '$'}
                      {iso_code === 'EUR'
                        ? subscription?.product?.priceEur || 0
                        : iso_code === 'USD'
                        ? subscription?.product?.priceUsd || 0
                        : subscription?.product?.priceCad || 0}{' '}
                      {iso_code || 'CAD'}
                    </Typography>
                    <Typography sx={{ mx: 2 }}>/user/month</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default PricingInformation
