// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'

// ** Moment Imports
import moment from 'moment'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'

// Styled Card component
const Plan = styled(Card, { shouldForwardProp: prop => prop !== 'active' })(({ theme, active }) => ({
  boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30), 0px 1px 3px 1px rgba(66,66,66,.15)',
  border: '1px solid #e0e0e0',
  borderRadius: '6px',
  position: 'realtive',
  marginBottom: '16px',
  cursor: 'pointer',
  ...(active ? { border: `2px solid ${theme.palette.primary.main}` } : {})
}))

const annual_monthly_advantages = [
  'Requires 12 months contract, subject to closeout charge for early termination.',
  'Pay each month based on total licenses committed upfront, starting Oct 18, 2023',
  'Add licenses as needed, remove licenses only when you renew the contract',
  'On completion of your contract period, your subscription will renew as per your renewal settings.'
]

const estimatedPrice = (product, customerInformation, licences) => {
  const currencyId = customerInformation?.currency?.id || null
  const annualPrice =
    product?.prices?.find(
      item =>
        item.pricingBookId === parseInt(process.env.REACT_APP_DEFAULT_PRICE_ID) &&
        (item.currencyId == currencyId || item.currencyId === parseInt(process.env.REACT_APP_DEFAULT_CURRENCY_ID)) &&
        item.type === 'annual'
    )?.value || 0
  return `$ ${(annualPrice * (licences || 1))?.toFixed(2)} ${customerInformation?.currency?.iso_code}`
}

const AnnualPlan = props => {
  // ** Props
  const { product, multiple, active, setActive, setSelectedPlan, setLicensedNumberOfSeats } = props

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)

  // ** State
  const [licences, setLicences] = useState(1)
  console.log('---', product)

  const columns = [
    {
      flex: 0.175,
      field: 'name',
      headerName: 'Date',
      renderCell: params => (
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{`Starting ${moment().format('MMM DD, YYYY')}`}</Typography>
      )
    },
    {
      flex: 0.25,
      field: 'price',
      headerName: 'Price (actual charges will be in your account currency)',
      renderCell: params => {
        return (
          <Box display='flex' alignItems='center' gap={1}>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {estimatedPrice(product, customerInformation)}
              per user/month
            </Typography>
          </Box>
        )
      }
    }
  ]

  // ** Functions
  const changeTotalPrice = event => {
    setLicensedNumberOfSeats(event.target.value)
    setLicences(event.target.value)
  }

  const selectOfferHandler = () => {
    setActive('annual')
    setSelectedPlan('annual')
  }

  return (
    <Plan active={multiple ? active === 'annual' : false} onClick={selectOfferHandler}>
      <Box p='15px 24px 0px 24px'>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {multiple ? (
            <RadioGroup row name='simple-radio' aria-label='simple-radio'>
              <FormControlLabel
                checked={active === 'annual'}
                control={<Radio />}
                label={<Typography fontWeight={500}>{product?.skuName}</Typography>}
              />
            </RadioGroup>
          ) : (
            <Typography fontWeight={500}>{product?.skuName}</Typography>
          )}

          <Box textAlign='end'>
            <Typography fontWeight={500}>{estimatedPrice(product, customerInformation)} per user/month</Typography>
            <Typography variant='subtitle2' fontSize={12} fontWeight={450}>
              {estimatedPrice(product, customerInformation)} per licences for 12 months
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 5, fontSize: 12, color: 'text.secondary' }}>
          <ul>
            {annual_monthly_advantages?.map((el, index) => (
              <li key={index} style={{ margin: '6px -20px 6px -20px' }}>
                {el}
              </li>
            ))}
          </ul>
        </Box>
        <Typography fontSize={14} fontWeight={450}>
          You are receiving a discount on the standard price.
        </Typography>
        <Box>
          <DataGrid
            autoHeight
            headerHeight={41}
            rowHeight={41}
            disableColumnMenu
            disableSelectionOnClick
            hideFooterPagination
            rows={[{ id: 1, name: 'name', price: 'price' }]}
            columns={columns}
            sx={{
              mt: 3,
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit'
              },
              '& .MuiDataGrid-row:last-child': {
                '& .MuiDataGrid-cell': {
                  borderBottom: '0px !important'
                }
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid #E0E0E0',
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 500,
                  fontSize: 13,
                  textTransform: 'capitalize'
                }
              },
              '& .MuiDataGrid-main': { border: '1px solid #E0E0E0' }
            }}
          />
        </Box>
      </Box>
      {!multiple || (multiple && active === 'annual') ? (
        <Box
          sx={{
            borderTop: theme => `1px solid ${theme.palette.grey[300]}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            p: '15px 24px 15px 24px',
            mb: 2
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='body2' fontSize={13} fontWeight={450}>
              Total licences
            </Typography>
            <TextField
              variant='outlined'
              size='small'
              type='number'
              name='licences'
              placeholder='Enter licence'
              value={licences}
              onChange={changeTotalPrice}
              InputProps={{ inputProps: { min: 1, max: 50000 }, style: { borderRadius: '5px', height: 30 } }}
              sx={{ width: 180 }}
            />
            {licences < 0 || licences > 50000 ? (
              <Typography fontWeight={550} fontSize={12} color='error.main'>
                {(licences < 0 && 'Must be at least 1') || (licences > 50000 && "You can't add more than 50000 users")}
              </Typography>
            ) : null}
          </Box>
          <Box textAlign='end'>
            <Typography variant='body2' fontSize={13} fontWeight={450}>
              Estimated monthly cost
            </Typography>
            <Typography fontSize={14} fontWeight={450}>
              {estimatedPrice(product, customerInformation, licences)}/month
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Plan>
  )
}

export default AnnualPlan
