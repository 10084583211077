// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import InputAdornment from '@mui/material/InputAdornment'
import { DataGrid } from '@mui/x-data-grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// ** Redux Imports
import { customersSelector, getCustomersUsages } from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

// ** Icon Imports
import SearchIcon from 'mdi-material-ui/Magnify'
import PayCommissionDialog from '../components/PayCommissionDialog'
import { getPayoutsData, rulesSelector } from 'store/rules'

// ** Data

const months = [
  'January',
  'Februray',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const PayoutsTable = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** State
  const [selectionModel, setSelectionModel] = useState([])

  // ** Selectors
  const { payouts, pagination, success, error, loading } = useSelector(rulesSelector)

  // ** Fetch Tax data
  useEffect(() => {
    const currentYear = moment().year()
    const from = moment(`${currentYear}-01-01`).format('YYYY-MM-DD')
    const to = moment(`${currentYear}-12-01`).format('YYYY-MM-DD')
    dispatch(getPayoutsData(from, to))
  }, [])

  // ** Functions
  const monthPayout = (sum, month) => {
    const entity = sum?.find(d => moment(d.date).format('MMMM') === month) || null
    const payout = parseFloat(entity?.sum || 0)?.toFixed(2)
    return payout
  }

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'name',
      headerName: 'Sales person',
      renderCell: params => (
        <Typography
          noWrap
          variant='body2'
          color='black'
          sx={{ ...(params.row.name !== 'Total' ? { color: 'common.blue', cursor: 'pointer' } : {}) }}
        >
          {params.row.name}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'commission',
      headerName: 'Cumulative',
      renderCell: params =>
        params.row?.name !== 'Total' ? (
          <Box>
            <Typography noWrap variant='body2' color='black'>
              Paid:{' '}
              <span style={{ color: 'green' }}>
                {params.row.sum?.reduce((acc, { sum, status }) => acc + (status === 'paid' ? sum : 0), 0)?.toFixed(2)}$
              </span>
            </Typography>

            <Typography noWrap variant='body2' color='black'>
              Open:{' '}
              <span style={{ color: 'red' }}>
                {params.row.sum?.reduce((acc, { sum, status }) => acc + (status === 'unpaid' ? sum : 0), 0)?.toFixed(2)}
                $
              </span>
            </Typography>
            <Typography noWrap variant='body2' color='black'>
              Total: <span> {params.row.sum?.reduce((acc, { sum, status }) => acc + (sum || 0), 0)?.toFixed(2)}$</span>
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography noWrap variant='body2' color='black'>
              Paid:{' '}
              <span style={{ color: 'green' }}>
                {payouts
                  .reduce(
                    (acc, { sum }) =>
                      acc + sum?.reduce((acc, { sum, status }) => acc + (status === 'paid' ? sum : 0), 0),
                    0
                  )
                  .toFixed(2)}
                $
              </span>
            </Typography>

            <Typography noWrap variant='body2' color='black'>
              Open:{' '}
              <span style={{ color: 'red' }}>
                {payouts
                  .reduce(
                    (acc, { sum }) =>
                      acc + sum?.reduce((acc, { sum, status }) => acc + (status === 'unpaid' ? sum : 0), 0),
                    0
                  )
                  .toFixed(2)}
                $
              </span>
            </Typography>
            <Typography noWrap variant='body2' color='black'>
              Total:{' '}
              <span>
                {payouts
                  .reduce((acc, { sum }) => acc + sum?.reduce((acc, { sum, status }) => acc + sum, 0), 0)
                  .toFixed(2)}
                $
              </span>
            </Typography>
          </Box>
        )
    },

    ...months.map((month, index) => ({
      flex: 0.175,
      minWidth: 100,
      field: month,
      headerName: month,
      renderCell: params =>
        params.row?.name !== 'Total' ? (
          <Typography noWrap variant='body2' color='black'>
            ${monthPayout(params.row.sum, month)}
          </Typography>
        ) : (
          <Typography noWrap variant='body2' color='black'>
            $
            {payouts
              .reduce((acc, { sum }) => {
                const entity = sum?.find(d => moment(d.date).format('MMMM') === month) || null
                const payout = parseFloat(entity?.sum || 0)
                return acc + (isNaN(payout) ? 0 : payout)
              }, 0)
              .toFixed(2)}
          </Typography>
        )
    }))
  ]

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Commisions Payouts</Typography>
            <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>| Get all Sales commissions</Typography>
          </Box>
        }
      />
      <Box p={5} display='flex' justifyContent='space-between' alignItems='center'>
        <TextField
          placeholder='Search for agents'
          size='small'
          // onChange={debounced}
          sx={{ width: 350 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ color: 'common.blue' }} />
              </InputAdornment>
            )
          }}
        />
        <PayCommissionDialog agents={selectionModel} />
      </Box>
      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={payouts || []}
        getRowId={row => row.id}
        columns={columns}
        checkboxSelection
        isRowSelectable={params => params.row.name !== 'Total'}
        selectionModel={selectionModel}
        onSelectionModelChange={selection => {
          setSelectionModel(selection)
        }}
        rowHeight={80}
        // loading={loading}
        // pagination
        //pageSize={20}
        // rowCount={pagination?.total || 0}
        // page={pagination?.currentPage ? pagination?.currentPage - 1 : 0}
        paginationMode='server'
        // rowsPerPageOptions={[0, 5, 10, 20, 50]}
        // onPageSizeChange={handleChangeRowsPerPage}
        // onPageChange={handleChangePage}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default PayoutsTable
