// ** React Import
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import { DataGrid } from '@mui/x-data-grid'

// ** Redux Imports
import { customersSelector, getCustomerInvoiceTemplates } from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector } from 'store/products'

// ** Helper
const removeWord = (str, word) => {
  // Create a regular expression that matches the word exactly
  const regex = new RegExp(`\\b${word}\\b`, 'gi')
  return str.replace(regex, '').trim()
}

const InvoiceTemplateTable = props => {
  // ** Props
  const { setAction, setInvoiceId, setType, setStatus, setSubs } = props

  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()

  // ** Selectors
  const { invoiceTemplates, success, error, loading } = useSelector(customersSelector)
  const { products } = useSelector(productsSelector)

  // ** Fetch Data
  useEffect(() => {
    dispatch(getCustomerInvoiceTemplates(id))
  }, [id])

  const getSkuList = skuList =>
    products
      ?.filter(item => skuList?.includes(item.skuId))
      ?.map(item => item.skuName)
      ?.map(str => removeWord(str, 'Google Workspace'))
      ?.join(', ') || '----'

  // ** Constants
  const columns = [
    {
      flex: 0.175,
      minWidth: 140,
      field: 'skuList',
      headerName: 'Skus',
      renderCell: params => (
        <Typography
          variant='body2'
          sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: 'black' }}
        >
          {getSkuList(params.row.skuList)}
        </Typography>
      )
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: 'type',
      headerName: 'Type'
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'status',
      headerName: 'Status'
    }
  ]

  // ** Functions
  const onClickRow = ({ row }) => {
    const { status, type, skuList, id } = row
    setStatus(status)
    setInvoiceId(id)
    setType(type)
    setSubs(products?.filter(item => skuList?.includes(item.skuId)))
    setAction('add')
  }

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Invoice Templates</Typography>
          </Box>
        }
        action={
          <Button
            color='primary'
            variant='text'
            size='medium'
            onClick={() => setAction('add')}
            sx={{ borderRadius: 0 }}
          >
            Add Template
          </Button>
        }
      />
      <Box my={3} />
      <DataGrid
        //scroll
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={loading ? [] : invoiceTemplates || []}
        columns={columns}
        loading={loading}
        pagination
        onRowClick={onClickRow}
        rowCount={invoiceTemplates?.length || 0}
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default InvoiceTemplateTable
