// ** Axios
import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** API Imports
import { createMetadata, editMetadata, getMetaDataData, getTaxData } from 'configs/axios/api_helper'

export const initialState = {
  loading: false,
  paymentMethodsData: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const paymentMethodSlice = createSlice({
  name: 'paymentMethodsData',
  initialState,
  reducers: {
    addPaymentMethod: (state, action) => {
      state.paymentMethodsData.push(action.payload)
      state.success = 'CREATE'
      state.loading = false
    },
    updatePaymentMethod: (state, action) => {
      state.paymentMethodsData = state.paymentMethodsData.map(paymentMethod =>
        paymentMethod.id.toString() === action.payload.id.toString()
          ? { paymentMethod, ...action.payload }
          : paymentMethod
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removePaymentMethod: (state, action) => {
      state.paymentMethodsData = state.paymentMethodsData.filter(payment_method => payment_method.id !== action.payload)
      state.success = 'DELETE'
      state.loading = false
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getPaymentMethodsDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.paymentMethodsData = data
      state.loading = false
      state.pagination = filters
      state.success = 'GET'
    },
    getPaymentMethodsDataFailure: state => {
      state.loading = false
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addPaymentMethod,
  updatePaymentMethod,
  removePaymentMethod,
  startLoading,
  resetMessages,
  getPaymentMethodsDataFailure,
  getPaymentMethodsDataSuccess
} = paymentMethodSlice.actions

// export user selector to get the slice in any component
export const paymentMethodSelector = state => state.paymentMethodsData

// export The reducer
const paymentMethodsReducer = paymentMethodSlice.reducer
export default paymentMethodsReducer

// Actions
export const fetchPaymentMethodsData = () => async dispatch => {
  try {
    dispatch(startLoading('GET'))
    const { data } = await getMetaDataData('type/payment_method')
    dispatch(getPaymentMethodsDataSuccess(data))
  } catch (error) {
    dispatch(getPaymentMethodsDataFailure())
  }
}

export const createPaymentMethod = payemntMethod => async dispatch => {
  try {
    dispatch(startLoading('CREATE'))
    const data = await createMetadata({ ...payemntMethod, type: 'payment_method' })
    dispatch(addPaymentMethod(data))
  } catch (error) {
    dispatch(getPaymentMethodsDataFailure())
  }
}

export const deletePaymentMethod = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE'))
    const data = await editMetadata(id, { status: 'archived' })
    dispatch(removePaymentMethod(id))
  } catch (error) {
    dispatch(getPaymentMethodsDataFailure())
  }
}

export const editPaymentMethod = paymentMethod => async dispatch => {
  try {
    const { id, ...rest } = paymentMethod
    dispatch(startLoading('UPDATE'))
    const data = await editMetadata(id, { ...rest })
    dispatch(updatePaymentMethod(paymentMethod))
  } catch (error) {
    dispatch(getPaymentMethodsDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
