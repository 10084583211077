// ** React Imports
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// ** 3rd Party Libraries
import { useDebouncedCallback } from "use-debounce"

// ** MUI Imports
import { Grid, Typography, Card, CardHeader, CardContent, Box, Button, TextField, Stack, IconButton } from "@mui/material"
import { styled } from '@mui/material/styles'
import PageHeader from 'core/components/page-header'
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Grow from '@mui/material/Grow'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import DeleteIcon from 'mdi-material-ui/Delete'
import PlusIcon from 'mdi-material-ui/Plus'
import EditIcon from 'mdi-material-ui/Pencil'


// ** Components Imports
import SettingsTable from '../components/SettingsTable'
import SettingsAddModal from '../components/SettingsAddModal'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { cleanMessages, createDueDate, deleteDueDate, dueDateSelector, editDueDate, fetchDueDateData } from 'store/metaData/dueDate'
import CustomInputs from '../components/CustomInputs'


// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    border: `solid 1px ${theme.palette.grey[300]}`,
    paddingLeft: 3,
    paddingRight: 3
}))

// Styled Button Component
const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'uppercase',
    marginLeft: 2,
    marginRight: "1rem",
    fontWeight: 600,
    boxShadow: theme.shadows[4],
    color: theme.palette.common.blue,
    backgroundColor: theme.palette.common.white,
    "&:disabled": {
        color: theme.palette.grey[700],
        boxShadow: "none"
    },
    '&:hover': {
        backgroundColor: theme.palette.common.white,
    },
}))


const DueDate = () => {

    // ** Redux dispatcher
    const dispatch = useDispatch()

    // ** Array holding selected Tax's ID to edited
    const [edit, setEdit] = useState(null)

    // ** State for draft tax objects to be created or updated
    const [draft, setDraft] = useState(null)
    const [open, setOpen] = useState(false)

    // ** Selectors
    const { dueDateData, success, error, loading } = useSelector(dueDateSelector);

    // ** Fetch Tax data
    useEffect(() => {
        dispatch(fetchDueDateData());
    }, []);


    useLayoutEffect(() => {
        if (success == "CREATE" || success == "UPDATE") {
            setDraft(null)
            setEdit(null)
            setOpen(true)
            setTimeout(() => {
                dispatch(cleanMessages())
            }, 1500)
        }
    }, [success, error])

    // ** Functions
    const addDraftHandler = () => {
        setDraft({ name: "", value: "" })
    }
    const deleteDraftHandler = () => {
        setDraft(null)
    }

    const addDueDateHandler = () => {
        if (edit) {
            dispatch(editDueDate({ id: edit, ...draft }))
        } else {
            dispatch(createDueDate(draft))
        }
    }

    const deleteHandler = (id) => {
        dispatch(deleteDueDate(id))
    }

    const handleCloseMessage = () => {
        setOpen(false)
    }

    // Intialize an empty input to start editing the value
    const editDueDateHandler = (id) => {
        setEdit(id)
    }


    const debounced = useDebouncedCallback(
        (event) => {
            const { name, value } = event.target;
            setDraft((prevState) => {
                return { ...prevState, [name]: value };
            });
        },
        500,
        // The maximum time func is allowed to be delayed before it's invoked:
        { maxWait: 3000 }
    );


    return (
        <Grid container spacing={6}>
            <PageHeader
                title={
                    <Typography variant='h5' sx={{ color: "common.blue", fontWeight: 600 }}>
                        Payment Terms List
                    </Typography>
                }
            />
            <Grid item xs={12}>
                <StyledCard>
                    {/* <CardHeader
                    title={
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='h6' sx={{ fontWeight: 600, mr: 5 }}>
                                Settings List
                            </Typography>
                            {/* <SettingsAddModal /> 
                        </Box>
                    }
                    /> */}
                    <CardContent>
                        {loading == "GET" &&
                            <Grid xs={12} sx={{ ml: "1rem", mb: 5 }}>
                                <CircularProgress size="3rem" />
                            </Grid>}
                        {loading != "GET" && dueDateData.map((dueDate, index) => (
                            <CustomInputs key={index} type="Payment Term" setEdit={setEdit} data={dueDate} setDraft={setDraft} deleteHandler={deleteHandler} />

                        ))}
                        {(draft && !edit) ?
                            <Grid item xs={12} sx={{ display: "flex", mb: 5 }}>
                                <Stack direction="row" alignItems="center" gap={3} sx={{ my: 2 }}>
                                    <TextField
                                        sx={{ mr: 5 }}
                                        label="Payment term"
                                        size="small"
                                        name="name"
                                        onChange={debounced}
                                    />
                                    <TextField
                                        sx={{ mr: 5 }}
                                        type="number"
                                        label='Value'
                                        size="small"
                                        name="value"
                                        onChange={debounced}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>Days</InputAdornment>
                                        }}
                                    />
                                    <IconButton onClick={deleteDraftHandler}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            </Grid>
                            : null
                        }
                        <Grid xs={12} sx={{ mb: 5 }}>
                            <StyledButton variant="text" disabled={(draft || edit)} onClick={addDraftHandler}>
                                <PlusIcon sx={{ mr: 2 }} />
                                add payment terms
                            </StyledButton>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                            <StyledButton
                                variant="contained"
                                disabled={!(draft?.name && draft?.value)}
                                onClick={addDueDateHandler}
                                startIcon={(loading == "CREATE" || loading == "UPDATE") && <CircularProgress size="1rem" />}
                            >
                                save
                            </StyledButton>
                            <StyledButton variant="contained" disabled={!(edit || draft)} onClick={() => setEdit(null)}>
                                discard changes
                            </StyledButton>
                        </Grid>
                    </CardContent>
                </StyledCard>
                {/* <SettingsTable /> */}
            </Grid>
            <Snackbar
                sx={{ mt: "3rem" }}
                open={open}
                onClose={handleCloseMessage}
                message='Due Date added successfully'
                autoHideDuration={1500}
                key={"top" + "right"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Grow}
            />
        </Grid >
    )
}


export default DueDate