// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress';

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { deleteProduct, productsSelector } from 'store/products'


const DeleteProductModal = (props) => {

    // ** Props
    const { productId } = props
    // ** Hooks 
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // **Selectors
    const { success, error, loading } = useSelector(productsSelector);

    // ** State
    const [open, setOpen] = useState(false)


    // Check hooks
    useEffect(() => {
        if (success == "DELETE_PRODUCT" || error == "DELETE_PRODUCT") {
            setOpen(false)
            navigate('/subscriptions')
        }
    }, [success, error])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    // ** Functions
    const deleteInvoiceHandler = (e) => {
        e.stopPropagation()
        dispatch(deleteProduct(productId))
    }

    return (
        <Fragment>
            <Button
                size='large'
                variant='text'
                color="error"
                sx={{ fontWeight: "600" }}
                onClick={handleClickOpen}
            >
                Delete
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Are you sure you want to delete this product permenantly ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Clicking Delete will lead to deleting the product permenantly. If you don't want to proceed with this action you can click Cancel
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button disabled={loading ? true : false} onClick={handleClose} size="small" variant="contained" color="inherit">Cancel</Button>
                    <Button
                        disabled={loading ? true : false}
                        onClick={deleteInvoiceHandler}
                        startIcon={(loading == "DELETE_PRODUCT" && <CircularProgress sx={{ color: 'common.white' }} size="1rem" />)}
                        variant="contained"
                        size="small"
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DeleteProductModal