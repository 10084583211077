// ** React Imports
import React from 'react'

// ** MUI Imports
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

// ** 3rd Party Libraries
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

const CheckboxGroupForm = props => {
  // ** Props
  const { control, name, options, label, watch, errors, fullWidth } = props

  // ** Functions
  const onChangeHandler = (checked, value, onChange) => {
    if (!checked) {
      const newValue = watch(name)?.filter(el => el !== value) || []
      onChange(newValue)
      return
    }
    onChange([...watch(name), value])
  }

  return (
    <>
      {label ? <FormLabel component='legend'>{label}</FormLabel> : null}

      <FormGroup row sx={{ gap: 10 }}>
        {options?.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    checked={value?.includes(item.value)}
                    onChange={e => onChangeHandler(e.target.checked, item.value, onChange)}
                  />
                )}
              />
            }
            label={item.label}
          />
        ))}
      </FormGroup>
    </>
  )
}

CheckboxGroupForm.propTypes = {
  control: PropTypes.object.isRequired, // Required string prop
  errors: PropTypes.func, // Optional string prop
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool
}

export default CheckboxGroupForm
