// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { DataGrid } from '@mui/x-data-grid'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { getOpportunitiesData, getSalesAgentsData, rulesSelector } from 'store/rules'

// ** Third Party Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'


const Opportunities = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** State
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('')
  const [agentId, setAgentId] = useState(null)

  // ** Selectors
  const { opportunities, salesAgents, pagination, loading, error } = useSelector(rulesSelector)

  // ** Get customer invoices
  useEffect(() => {
    dispatch(getOpportunitiesData(agentId, status))
    dispatch(getSalesAgentsData())
  }, [status, agentId])

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'status',
      headerName: 'Opportunity Status',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.status}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'opportunityOwner',
      headerName: 'Owner',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.salesAgent ? params.row.salesAgent?.firstname + ' ' + params.row.salesAgent?.lastname : '-----'}
        </Typography>
      )
    },

    // {
    //   flex: 0.125,
    //   minWidth: 250,
    //   field: 'id',
    //   headerName: 'ID',
    //   renderCell: params => (
    //     <Typography noWrap variant='body2' color='black'>
    //       {params.row.id}
    //     </Typography>
    //   )
    // },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'customerId',
      headerName: 'Customer',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.customer?.organization || '------'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'skuId',
      headerName: 'SKU ID',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.skuId || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'skuName',
      headerName: 'SKU Name',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.skuName || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'opportunityPrice',
      headerName: 'Opportunity Price',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          ${params.row.opportunityPrice.toFixed(2)}
        </Typography>
      )
    },

    {
      flex: 0.125,
      minWidth: 250,
      field: 'opportunityType',
      headerName: 'Type',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.opportunityType || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'numberOfSeats',
      headerName: 'Number of seats',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.numberOfSeats || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'commitmentPeriod',
      headerName: 'Commitment Period',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.commitmentPeriod
            ? Math.round(params.row.commitmentPeriod / 12) +
              (Math.round(params.row.commitmentPeriod / 12) > 1 ? ' years' : ' year')
            : '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'commitmentType',
      headerName: 'Commitment Type',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.commitmentType || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'paymentPeriod',
      headerName: 'Payment Period',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.paymentPeriod || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'discountMarginValue',
      headerName: 'Discount Margin Value',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.discountMarginValue || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'discountVendorValue',
      headerName: 'Discount Vendor Value',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.discountVendorValue}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'discountStartDate',
      headerName: 'Discount Start Date',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.discountStartDate ? moment(params.row.discountEndDate).format('YYYY-MM-DD') : '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'discountEndDate',
      headerName: 'Discount End Date',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.discountEndDate ? moment(params.row.discountEndDate).format('YYYY-MM-DD') : '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'freshbooksId',
      headerName: 'Freshbooks',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.freshbooksId || '-----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'googleId',
      headerName: 'Google ID',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.googleId || '-----'}
        </Typography>
      )
    }
  ]

  // ** Functions
  const handleChangePage = newPage => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPage(++newPage)
  }

  const handleChangeRowsPerPage = rows => {
    setPage(1)
    setLimit(rows)
  }

  const onChangeAgents = id => {
    setAgentId(id)
  }

  const onChangeStatus = event => {
    setStatus(event.target.value)
  }

  const debounced = useDebouncedCallback(
    event => {
      const { value } = event.target
      setSearch(value)
    },
    400,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Opprtunities</Typography>
          </Box>
        }
      />
      <Box p={5} display='flex' alignItems='center' gap={5}>
        <Box width={350}>
          <FormControl fullWidth size='small'>
            <InputLabel id='pricing-book-label'>Status</InputLabel>
            <Select label='Status' onChange={onChangeStatus} labelId='validation-basic-select'>
              <MenuItem value={null}>
                <em>None</em>
              </MenuItem>
              <MenuItem value='verified'>Verified</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box width={350}>
          <Autocomplete
            fullWidth
            onChange={(event, values) => onChangeAgents(values?.id)}
            options={salesAgents}
            getOptionLabel={option => `${option.firstname} ${option.lastname} (${option.email})`}
            renderInput={params => <TextField {...params} label='Agents' size='small' />}
          />
        </Box>
      </Box>
      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={loading ? [] : opportunities || []}
        getRowId={row => row.id}
        columns={columns}
        loading={loading}
        pagination
        pageSize={limit || 0}
        rowCount={pagination?.total || 0}
        page={pagination?.currentPage ? pagination?.currentPage - 1 : 0}
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default Opportunities
