// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

// ** Icons Imports
import ContactsIcon from 'mdi-material-ui/AccountGroup'

// ** Components Imports
import ContactsForm from '../forms/ContactsForm'


// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    border: `solid 1px ${theme.palette.grey[300]}`
}))
const ContactsInformations = () => {

    return (
        <StyledCard>
            <CardHeader
                title={
                    <Stack direction="row" alignItems="center" gap={4} sx={{ my: 2 }}>
                        <ContactsIcon sx={{color: 'common.black'}} />
                        <Typography variant='h6'>
                            Contacts Informations
                        </Typography>
                    </Stack>
                }
            />
            <Divider sx={{ borderBottomWidth: 2, color: theme => theme.palette.grey[300], mt: "-0.5rem" }} />
            <CardContent>
                <ContactsForm />
            </CardContent>
        </StyledCard>
    )
}

export default ContactsInformations
