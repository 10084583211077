// ** React Imports
import React from 'react'
import { Link } from 'react-router-dom'

// ** MUI Imports
import { Grid, Typography, Card, CardHeader, CardContent, Stack, Button, Box, useMediaQuery } from "@mui/material"

// ** Component Imports
import PageHeader from 'core/components/page-header'
import CustomersTable from '../components/CustomersTable'

// ** Icon Imports 
import DatabaseIcon from "mdi-material-ui/DatabaseSearchOutline"
import NoteIcon from "mdi-material-ui/CalendarMultipleCheck"
import CalendarIcon from "mdi-material-ui/CalendarMonth"


const Customers = () => {

    const medium = useMediaQuery(theme => theme.breakpoints.down('md'))

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title="Introduction Partner Sales Rebuilding"
                    />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body2'>
                                    You can now set mark uo or mark down rules, along with effectives timeframes for customer Google Cloud Subscriptions
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ ...(!medium && { display: 'flex', justifyContent: "end" }) }}>
                                <Box>
                                    <Stack direction="row" alignItems="center" gap={2} sx={{ my: 1 }}>
                                        <DatabaseIcon sx={{ color: "common.blue" }} />
                                        <Typography variant='body2'>
                                            Configure Big Query export
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" gap={2} sx={{ my: 1 }}>
                                        <NoteIcon sx={{ color: "common.blue" }} />
                                        <Typography variant='body2'>
                                            Configure Markup or markdown rules
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" gap={2} sx={{ my: 1 }}>
                                        <CalendarIcon sx={{ color: "common.blue" }} />
                                        <Typography variant='body2'>
                                            Define timeframes for rules
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button variant="contained" sx={{ borderRadius: 0.5 }}>Configure Big Query Dataset</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <CustomersTable />
            </Grid>
        </Grid>
    )
}


export default Customers