// ** React Import
import React, { useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import { DataGrid } from '@mui/x-data-grid'

// ** Redux Imports
import { customersSelector } from 'store/customers'
import { useSelector } from 'react-redux'
import { useGetBoardedCustomersQuery } from 'store/customers/apiCaching'

// ** Custom Components
import ReassignGoogleId from '../modals/ReassignGoogleId'

const GoogleIdsInformation = () => {
  // ** State
  const [selectionModel, setSelectionModel] = useState([])
  const [alert, setAlert] = useState({ open: false, success: false, error: false, message: '' })

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)

  // ** Queries Cache
  const { data: boardedCustomers } = useGetBoardedCustomersQuery({ limit: 1000, page: 1 })

  // ** Constants
  const columns = [
    {
      flex: 0.175,
      minWidth: 140,
      field: 'googleId',
      headerName: 'Google ID',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.googleId}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'organization',
      headerName: 'Organization',
      renderCell: params => (
        <Typography noWrap variant='body2' sx={{ color: 'common.blue', cursor: 'pointer' }}>
          {params.row.domain}
        </Typography>
      )
    }
  ]

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Google Ids</Typography>
            <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>
              | Display all available google ids list
            </Typography>
          </Box>
        }
        action={<ReassignGoogleId selectedIds={selectionModel} boardedCustomers={boardedCustomers} />}
      />
      <Box my={3} />
      <DataGrid
        //scroll
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={loading ? [] : customerInformation?.googleIds || []}
        getRowId={row => row.domain}
        columns={columns}
        loading={loading}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={selection => {
          setSelectionModel(selection)
        }}
        pagination
        rowCount={customerInformation?.googleIds?.length || 0}
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default GoogleIdsInformation
