// ** REACT Imports
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import { Divider, CardHeader, Card, Typography, Grid, Box, Button, useMediaQuery, LinearProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TextField from '@mui/material/TextField'

// ** Icons Imports
import ArrowLeftIcon from 'mdi-material-ui/ArrowLeft'
import ChevronRightIcon from 'mdi-material-ui/ChevronRight'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanCustomerInformation,
  customersSelector,
  getBoardedCustomer,
  getCustomerDailyChanges
} from 'store/customers'

// ** 3rd Party Libraries
import moment from 'moment'
import ProductsAutocomplete from '../components/forms/ProductsAutocomplete'
import { getProductsData, productsSelector } from 'store/products'

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '5px !important',
  textTransform: 'capitalize !important',
  border: `2px solid ${theme.palette.grey[300]}`,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: `${theme.palette.grey[100]} !important`,
    border: `2px solid ${theme.palette.grey[300]}`
  },
  [theme.breakpoints.down('sm')]: {
    width: 'fit-content'
  }
}))

const CustomerDailyChanges = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** Selectors
  const { customerInformation, customerDailyChanges, pagination, loading } = useSelector(customersSelector)
  const { products } = useSelector(productsSelector)

  // ** State
  const [paginationFilters, setPaginationFilters] = useState({
    limit: 20,
    page: 1,
    skuId: [],
    date: null,
    customerId: id
  })

  // ** Fetch customer
  useEffect(() => {
    if (id) {
      dispatch(getBoardedCustomer(id))
      dispatch(getProductsData({ limit: 1000, page: 1, field: '' }))
    }
    return () => {
      dispatch(cleanCustomerInformation())
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getCustomerDailyChanges(paginationFilters))
    }
  }, [paginationFilters])

  // ** Constants
  const columns = [
    {
      flex: 0.175,
      minWidth: 150,
      field: 'subscriptionId',
      headerName: 'Subscription ID',
      renderCell: params => (
        <Typography noWrap variant='body2' sx={{ color: 'common.blue', cursor: 'pointer' }}>
          {params.row.subscription_id}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 80,
      field: 'domain',
      headerName: 'Doamin',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.customer_domain}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 80,
      field: 'cloudId',
      headerName: 'Cloud ID',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.customer_cloud_id}
        </Typography>
      )
    },
    {
      flex: 0.125,
      width: 80,
      headerName: 'Day',
      field: 'day',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {moment(new Date(params.row.day)).format('YYYY-MM-DD') ?? '----'}
        </Typography>
      )
    },
    {
      flex: 0.175,
      minWidth: 180,
      field: 'sku_name',
      headerName: 'SKU Name',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.sku_name ?? '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      field: 'dailyUsages.seats',
      minWidth: 80,
      headerName: 'Starting seats',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row?.dailyUsage?.seats ?? 0}
        </Typography>
      )
    },
    {
      flex: 0.125,
      field: 'seats',
      minWidth: 80,
      headerName: 'Changed seats',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.seats ?? 0}
        </Typography>
      )
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: 'plan',
      headerName: 'Plan Name',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {typeof params.row.plan === 'string' ? params.row.plan : params.row.plan?.planName ?? '-----'}
        </Typography>
      )
    }
  ]

  // ** Functions
  const handleNavigation = () => navigate('/customers/customer-display/' + id)

  const handleChangePage = newPage => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPaginationFilters({
      ...paginationFilters,
      page: ++newPage
    })
  }

  const handleChangeRowsPerPage = limit => {
    setPaginationFilters({
      ...paginationFilters,
      limit
    })
  }

  const productChangeHandler = (name, value) => {
    setPaginationFilters({ ...paginationFilters, skuId: value.map(item => item.skuId) })
  }

  const onChangeDateHandler = value => {
    if (!value) setPaginationFilters({ ...paginationFilters, date: null })
    else setPaginationFilters({ ...paginationFilters, date: moment(value).utc(false).format('YYYY-MM-DD') })
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card
          sx={{
            height: 'calc(100vh - 100px)',
            boxShadow: 0,
            border: theme => `solid 1px ${theme.palette.grey[300]}`,
            overflow: 'auto'
          }}
        >
          <CardHeader
            sx={{ mb: '-1rem' }}
            title={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!small && <ArrowLeftIcon sx={{ mr: '1rem', cursor: 'pointer' }} onClick={handleNavigation} />}
                <Typography variant='h5'>Customers - Daily changes</Typography>
                <ChevronRightIcon sx={{ ml: '0.5rem', fontSize: '2.225rem' }} />
                <StyledButton variant='outlined'>{customerInformation?.organization || '----'}</StyledButton>
              </Box>
            }
          />
          <Divider sx={{ color: theme => theme.palette.grey[300] }} />
          <Grid item container xs={12}>
            <Grid item xs={12} md={4} px={2} py={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  clearable
                  label='Day'
                  value={paginationFilters.date}
                  maxDate={new Date()}
                  onChange={onChangeDateHandler}
                  renderInput={params => <TextField {...params} size='small' fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4} px={2} py={3}>
              <ProductsAutocomplete
                products={products}
                productChangeHandler={productChangeHandler}
                productsData={paginationFilters.subscriptionId}
              />
            </Grid>
          </Grid>
          <DataGrid
            autoHeight
            rows={loading ? [] : customerDailyChanges || []}
            columns={columns}
            loading={loading}
            pagination
            paginationMode='server'
            rowsPerPageOptions={[5, 10, 20, 50]}
            pageSize={pagination?.limit || 0}
            rowCount={pagination?.total || 0}
            page={pagination?.currentPage ? pagination?.currentPage - 1 : 0}
            onPageSizeChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            components={{
              LoadingOverlay: LinearProgress
            }}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default CustomerDailyChanges
