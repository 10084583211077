// ** React Imports
import React, { forwardRef, useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress';



// ** Third Party Imports
// import toast from 'react-hot-toast'
// import { useForm, Controller } from 'react-hook-form'
// import { useDebouncedCallback } from "use-debounce"

// ** Icons Imports
import ChevronDown from 'mdi-material-ui/ChevronDown'
import ChevronUp from 'mdi-material-ui/ChevronUp'
import AccountIcon from 'mdi-material-ui/AccountCircle'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'


// ** Redux Imports 
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, manageContacts } from 'store/customers'
import ContactCard from './ContactCard'



const ContactsCard = (props) => {

    // ** Selectors 
    const { customerInformation } = useSelector(customersSelector);

    return (

        <Card sx={{
            boxShadow: 0,
            border: theme => `solid 1px ${theme.palette.grey[300]}`,
            mt: "1rem"
        }}>
            <CardHeader
                title='Contacts'
            />
            <Grid container>
                {customerInformation?.contacts?.map(contact =>
                    <ContactCard key={contact.id} contact={contact} />
                )}
            </Grid>
        </Card >

    )
}

export default ContactsCard
