// ** React Imports
import React from 'react'
import { Navigate, redirect } from 'react-router-dom'

// ** Authentifications
import Login from 'pages/auth'
import PrivacyPolicy from 'pages/auth/PrivacyPolicy'

// Home
import Dashboard from '../pages/Home'

// Customers
//import CreateCustomer from "pages/Customers/components/forms/CreateCustomer"
import Customers from 'pages/Customers/containers/Customers'
import CustomerDisplay from 'pages/Customers/containers/CustomerDisplay'
import CustomersOnboarding from 'pages/Customers/containers/CustomersOnboarding'
import PurchaseNonGoogleSku from 'pages/Customers/containers/PurchaseNonGoogleSku'
import CustomerDailyChanges from 'pages/Customers/containers/CustomerDailyChanges'

// Billing
import Billing from 'pages/Billing'
import Recalculation from 'pages/Billing/containers/Recalculation'
import BillingCustomers from 'pages/Billing/containers/BillingCustomers'
import BillingComparator from 'pages/Billing/containers/BillingComparator'
import CheckDailyUsages from 'pages/Billing/containers/CheckDailyUsages'
import BillingFreshbooksComparator from 'pages/Billing/containers/BillingFreshbooksComparator'

// Settings
import Tax from 'pages/Settings/containers/Tax'
import Currency from 'pages/Settings/containers/Currency'
import PaymentMethod from 'pages/Settings/containers/PaymentMethods'
import DueDate from 'pages/Settings/containers/DueDate'
import CommitmentDuration from 'pages/Settings/containers/CommitmentDuration'
import PricePlan from 'pages/Settings/containers/PricePlan'
import EngagementType from 'pages/Settings/containers/EngagementType'

// Subscriptions
import SubscriptionsList from 'pages/Subscriptions/containers/SubscriptionsList'
import SubscriptionForm from 'pages/Subscriptions/containers/SubscriptionForm'
import PricingBooksList from 'pages/Subscriptions/containers/PricingBookList'
import PricingBookForm from 'pages/Subscriptions/containers/PricingBookForm'
import BulkCustomersPricingBooks from 'pages/Subscriptions/containers/BulkCustomersPricingBooks'

// ** Rules
import RulesList from 'pages/Commissions/Rules/containers/RulesList'
import RuleForm from 'pages/Commissions/Rules/containers/RuleForm'
import Opportunities from 'pages/Commissions/Opportunities/containers/Opportunities'
import PayoutsTable from 'pages/Commissions/Payouts/containers/PayoutsTable'
import Deals from 'pages/Commissions/Deals/containers/DeaslList'

const authProtectedRoutes = [
  { path: '/google-customers/new-customer', component: CustomerDisplay },
  { path: '/customers/customers-list', component: Customers },
  { path: '/google-customers/customer-display/:id', component: CustomerDisplay },
  { path: '/customers/customer-display/:id', component: CustomerDisplay },
  { path: '/customers/customer-display/purchase-product/:id', component: PurchaseNonGoogleSku },
  { path: '/customers/customer-display/daily-changes/:id', component: CustomerDailyChanges },
  { path: '/customers/onboarding-list', component: CustomersOnboarding },
  { path: '/settings/tax', component: Tax },
  { path: '/settings/currency', component: Currency },
  { path: '/settings/payment-methods', component: PaymentMethod },
  { path: '/settings/due-date', component: DueDate },
  { path: '/settings/commitment-duration', component: CommitmentDuration },
  { path: '/settings/price-plan', component: PricePlan },
  { path: '/settings/engagement-type', component: EngagementType },
  { path: '/billing', component: Billing },
  { path: '/billing/:id', component: Billing },
  { path: '/billing/recalculation', component: Recalculation },
  { path: '/billing/generation', component: BillingCustomers },
  { path: '/billing/comparator', component: BillingComparator },
  { path: '/billing/freshbooks/comparator', component: BillingFreshbooksComparator },
  { path: '/billing/check-daily-usages', component: CheckDailyUsages },
  { path: '/commissions/payouts/', component: PayoutsTable },
  { path: '/commissions/opportunities/', component: Opportunities },
  { path: '/commissions/rules/', component: RulesList },
  { path: '/commissions/rules/:id', component: RuleForm },
  { path: '/commissions/rules/new-rule', component: RuleForm },
  // { path: '/commissions/deals', component: Deals },
  { path: '/subscriptions/:id', component: SubscriptionForm },
  { path: '/subscriptions/new-subscription', component: SubscriptionForm },
  { path: '/subscriptions', component: SubscriptionsList },
  { path: '/pricing-books/:id', component: PricingBookForm },
  { path: '/pricing-books/new-pricing', component: PricingBookForm },
  { path: '/pricing-books', component: PricingBooksList },
  { path: '/pricing-books/customers', component: BulkCustomersPricingBooks },
  { path: '/dashboard', component: Dashboard },
  { path: '/', exact: true, component: () => <Navigate to='/dashboard' /> },
  { path: '*', component: () => <Navigate to='/dashboard' /> }
]

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/privacy-policy', component: PrivacyPolicy }
]

export { authProtectedRoutes, publicRoutes }
