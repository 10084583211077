// ** REACT Imports
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import { Divider, CardHeader, Card, Typography, Grid, Box, Button, useMediaQuery } from '@mui/material'

// ** Icons Imports
import ArrowLeftIcon from 'mdi-material-ui/ArrowLeft'
import ChevronRightIcon from 'mdi-material-ui/ChevronRight'

// ** Custom Component Imports
import PurchaseContainer from '../components/purchase-products'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { cleanCustomerInformation, customersSelector, getBoardedCustomer } from 'store/customers'

// ** 3rd Party Libraries
import PerfectScrollbar from 'react-perfect-scrollbar'

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '5px !important',
  textTransform: 'capitalize !important',
  border: `2px solid ${theme.palette.grey[300]}`,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: `${theme.palette.grey[100]} !important`,
    border: `2px solid ${theme.palette.grey[300]}`
  },
  [theme.breakpoints.down('sm')]: {
    width: 'fit-content'
  }
}))

const PurchaseNonGoogleSku = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** Check wether to display boarded or onboarding customers
  const customersType = pathname.split('/')[1]

  // ** Fetch customer
  useEffect(() => {
    if (id) {
      dispatch(getBoardedCustomer(id))
    }
    return () => {
      dispatch(cleanCustomerInformation())
    }
  }, [])

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)

  // ** Functions
  const handleNavigation = () => navigate('/customers/customer-display/' + id)

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <PerfectScrollbar>
          <Card
            sx={{
              maxHeight: 'calc(100vh - 100px)',
              boxShadow: 0,
              border: theme => `solid 1px ${theme.palette.grey[300]}`
            }}
          >
            <CardHeader
              sx={{ mb: '-1rem' }}
              title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {!small && <ArrowLeftIcon sx={{ mr: '1rem', cursor: 'pointer' }} onClick={handleNavigation} />}
                  <Typography variant='h5'>
                    {customersType == 'google-cutomers' ? 'Google Customers' : 'Customers'}
                  </Typography>
                  <ChevronRightIcon sx={{ ml: '0.5rem', fontSize: '2.225rem' }} />
                  <StyledButton variant='outlined'>{customerInformation?.organization || '----'}</StyledButton>
                </Box>
              }
            />
            <Divider sx={{ color: theme => theme.palette.grey[300] }} />
            <PurchaseContainer />
          </Card>
        </PerfectScrollbar>
      </Grid>
    </Grid>
  )
}

export default PurchaseNonGoogleSku
