// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import { Snackbar, Alert, Grow, Box } from '@mui/material'

// ** Redux Imports
import { useDispatch } from 'react-redux'
import { cleanMessages } from 'store/customers'

const AlertMessages = props => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** State
  const [open, setOpen] = useState(true)

  // ** Props
  const { message, success, error, messageValue, resetHandler } = props

  // ** Clean up
  useEffect(() => {
    if ((success && success == messageValue) || error) {
      setOpen(true)
      setTimeout(() => {
        dispatch(cleanMessages())
        resetHandler && resetHandler()
      }, 2000)
    }
  }, [success, error])

  // ** Functions
  const handleCloseMessage = () => {
    dispatch(cleanMessages())
    setOpen(false)
  }

  return (
    <Fragment>
      {success && success == messageValue && (
        <Snackbar
          sx={{ mt: '3rem' }}
          open={open}
          onClose={handleCloseMessage}
          autoHideDuration={2000}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
          message={<Box sx={{ textAlign: 'center' }}>{message || 'Action was done successfully'}</Box>}
        />
      )}
      {error && (
        <Snackbar
          sx={{ mt: '3rem' }}
          open={open}
          onClose={handleCloseMessage}
          autoHideDuration={2000}
          key={'top' + 'right'}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={Grow}
        >
          <Alert elevation={1} variant='filled' onClose={handleCloseMessage} sx={{ backgroundColor: 'error.light' }}>
            {message || 'An error has occured please try again !'}
          </Alert>
        </Snackbar>
      )}
    </Fragment>
  )
}

export default AlertMessages
