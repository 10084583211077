// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'
import { currencySelector } from 'store/metaData/currency'

// ** 3rd party libraries
import moment from 'moment'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'

// ** Custom Components
import InvoiceTableRow from './InvoiceTableRow'

// Styled Divider component
const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: '100%',
  borderTop: `0.5px solid ${theme.palette.grey[300]}`,
  '& .MuiTableCell-head': {
    textTransform: 'capitalize',
    fontWeight: 600,
    color: 'text.secondary'
  },
  '& .MuiTableCell-root': {
    fontSize: '0.825rem'
  }
}))

const splitIntervals = inputArray => {
  const resultArray = []

  inputArray.forEach(item => {
    if (item.intervals?.length > 1) {
      item.intervals.forEach(interval => {
        console.log('---item---', interval)
        const from = moment(interval.from)
        const to = moment(interval.to)
        const newItem = {
          ...item,
          day: interval.from,
          days: item.days?.filter(date => {
            const currentDate = moment(date)
            return currentDate.isBetween(from, to, null, '[]')
          }),
          intervals: [interval]
        }
        resultArray.push(newItem)
      })
    } else {
      resultArray.push(item)
    }
  })

  // Sort Array by sku name & day at the same time
  resultArray.sort((a, b) => {
    // Compare sku_name
    const nameComparison = a.sku_name.localeCompare(b.sku_name)

    // If sku_name is the same, compare by day
    if (nameComparison === 0) {
      return a.day.localeCompare(b.day)
    }

    return nameComparison
  })
  return resultArray
}

const TransactionsTable = props => {
  // ** Props
  const { invoiceDetails, type } = props

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** Variables
  let rows =
    type === 'premierCloud' ? splitIntervals(invoiceDetails)?.filter(item => !!item.seats) || [] : invoiceDetails

  let subtotalAmount = 0
  const currencyId = 2
  // currenciesData?.find(item => item.iso_code === 'CAD')?.id || null

  // ** Functions
  // Calculte rows balance
  const calculateRowsBalance = () => {
    if (type === 'premierCloud') {
      subtotalAmount = invoiceDetails
        ?.reduce(
          (acc, { prices, quantity }) =>
            acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0) * (quantity || 1),
          0
        )
        ?.toFixed(2)
      return `$${(subtotalAmount * 0.8)?.toFixed(2)}`
    } else {
      const total = invoiceDetails?.reduce((acc, { price }) => acc + price, 0)?.toFixed(2)
      return `$${total}`
    }
  }

  // Calculate ending balance
  const calculateEndingBalance = () => {
    if (type === 'premierCloud') {
      const totalTax = customerInformation?.taxes?.reduce((acc, { tax: { value } }) => acc + parseInt(value), 0) || 0
      const subtotalAmount = invoiceDetails
        ?.reduce(
          (acc, { prices, quantity }) =>
            acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0) * (quantity || 1),
          0
        )
        ?.toFixed(2)
      const taxedAmount =
        invoiceDetails?.reduce(
          (acc, { prices, quantity }) =>
            acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0) * (quantity || 1),
          0
        ) *
        (parseInt(totalTax) / 100)
      const transactionAmount =
        (invoiceDetails?.reduce(
          (acc, { prices, quantity }) =>
            acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0) * (quantity || 1),
          0
        ) +
          parseInt(taxedAmount)) *
        (parseInt(customerInformation?.paymentMethod?.value || 0) / 100)

      const total = (parseFloat(subtotalAmount) + parseFloat(transactionAmount) + parseFloat(taxedAmount)).toFixed(2)
      if (customerInformation?.currency?.iso_code === 'EUR') {
        return `€ ${total * 0.8}`
      }
      return `$${(total * 0.8)?.toFixed(2)}`
    } else {
      const total = invoiceDetails?.reduce((acc, { price }) => acc + price, 0)?.toFixed(2)
      return `$${total}`
    }
  }

  return (
    <TableContainer sx={{ minWidth: '100%' }} component={Paper}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align='right'>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', fontWeight: 600 }}>
                Ending balance: &nbsp; &nbsp;{calculateEndingBalance()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: 'text.secondary' }}>Date</TableCell>
            <TableCell sx={{ color: 'text.secondary' }}>Description</TableCell>
            <TableCell sx={{ color: 'text.secondary' }} align='right'>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <InvoiceTableRow key={row.id} row={row} type={type} />
          ))}
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', fontWeight: 600, my: 2 }}>
                Starting balance {type === 'premierCloud' ? '(-20%)' : ''}
                CAD:&nbsp; &nbsp;
                {calculateRowsBalance()}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export default React.memo(TransactionsTable)
