// ** React Imports
import React from 'react'

// ** MUI IMports
import { Typography, Grid, Box } from '@mui/material'

// ** Images Imports
import google_workspace_text from 'assets/images/google_workspace_text.png'
import workspace_category_banner_image from 'assets/images/workspace_category_banner_image.png'
import get_more_services_best_plan from 'assets/images/get_more_services_best_plan.png'

const EmptyProducts = () => {
  return (
    <Box display='flex' height='100%' flexDirection='column' justifyContent='space-between'>
      <Grid item xs={12} display='flex' aligItems='start' justifyContent='space-between'>
        <Box p={2}>
          <img alt='google_workspace' src={google_workspace_text} />
          <Typography fontSize={15} variant='body2' fontWeight={500}>
            Everything you need to get anything done, now in one place. Choose your edition.
          </Typography>
        </Box>
        <Box>
          <img alt='banner_image' src={workspace_category_banner_image} />
        </Box>
      </Grid>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <Box width='fit-content' display='flex' flexDirection='column' alignItems='center' gap={5}>
          <img alt='best_plane' src={get_more_services_best_plan} />
          <Typography fontSIze={14} fontWeight={500} variant='body2' maxWidth='350px' textAlign='center'>
            You're getting the best out of Google. We will notify you when new subscriptions become available.
          </Typography>
        </Box>
      </Grid>
    </Box>
  )
}

export default EmptyProducts
