// ** React Imports
import React from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// ** Components Imports
import CustomAvatar from 'core/components/mui/avatar'

// ** moment Import
import moment from 'moment'

// ** Data
import { products } from '@fake-db/products'

// ** Redux Imports
import { customersSelector } from 'store/customers'
import { useSelector } from 'react-redux'

const Card = styled(Box)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  borderRadius: '6px',
  position: 'realtive',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start'
}))

const Container = styled(Box)(({ theme }) => ({
  padding: '20px 24px 20px 24px',
  display: 'flex',
  alignItems: 'start',
  gap: 20
}))

const returnPrice = (product, customerInformation, type) => {
  const currencyId = customerInformation?.currency?.id || null
  const annualPrice =
    product?.prices?.find(
      item =>
        item.pricingBookId === parseInt(process.env.REACT_APP_DEFAULT_PRICE_ID) &&
        (item.currencyId == currencyId || item.currencyId === parseInt(process.env.REACT_APP_DEFAULT_CURRENCY_ID)) &&
        item.type === (type || 'annual')
    )?.value || 0
  return `$ ${annualPrice?.toFixed(2)} ${customerInformation?.currency?.iso_code}`
}

const Checkout = props => {
  // ** Props
  const { product, selectedPlan } = props

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)

  // ** Constants
  const productIcon = name =>
    products.find(el => el.displayName.toLowerCase() === name?.toLowerCase())?.defaultLogo?.content


  return (
    <Grid item container xs={12} display='flex' flexDirection='column' gap={3}>
      <Typography fontSize={20} fontWeight={450}>
        Checkout
      </Typography>
      <Card>
        <Container>
          <CustomAvatar
            sx={{ borderRadius: 0, height: 20, width: 20, mt: 1 }}
            src={productIcon(product?.productName)}
          />
          <Box>
            <Typography fontSize={14} fontWeight={450}>
              {product?.skuName}
            </Typography>
            <Box display='flex' alignItems='center' gap={1}>
              <Typography variant='body2' fontSize={13} fontWeight={450} textTransform='capitalize'>
                {selectedPlan?.toLowerCase() === 'annual' ? 'COMMITMENT' : 'FLEXIBLE'} <span>plan</span>
              </Typography>
              {selectedPlan === 'annual' ? (
                <Typography variant='body2' fontSize={13} fontWeight={450}>
                  yearly payment
                </Typography>
              ) : null}
            </Box>
            <Typography variant='body2' fontSize={12}>
              Paid service starts on {moment(new Date()).add('day', 1).format('MMM DD, YYYY')}
            </Typography>
          </Box>
        </Container>
        {selectedPlan?.toUpperCase() === 'FLEXIBLE' ? (
          <Box p='20px 24px 20px 24px' textAlign='end'>
            <Typography varaint='body1' fontSize={14}>
              Price based on user count
            </Typography>
            <Typography variant='body2' fontSize={12}>
              {returnPrice(product, customerInformation, selectedPlan)} per user/month
            </Typography>
          </Box>
        ) : null}
        {selectedPlan?.toUpperCase() === 'ANNUAL' ? (
          <Box p='20px 24px 20px 24px' textAlign='end'>
            <Typography varaint='body1' fontSize={14}>
              {returnPrice(product, customerInformation, selectedPlan) +
                ' ' +
                (customerInformation?.currency?.iso_code || 'CAD') +
                '/month (1 license)'}
            </Typography>
          </Box>
        ) : null}
      </Card>
    </Grid>
  )
}

export default Checkout
