// ** React Imports
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import PropTypes from 'prop-types'

// ** Custom Components Imports
import FormInput from '../components/FormInput'
import DeleteProductModal from '../components/DeleteProductDialog'

// ** Styled Components
import FallbackSpinner from 'core/components/spinner'
import AlertMessages from 'pages/Billing/components/AlertMessages'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  newProduct,
  productsSelector,
  getProductData,
  updateProduct,
  resetProduct,
  cleanMessages
} from 'store/products'
import TableFormPricing from '../components/TableFormPricing'
import TableProductPrices from '../components/TableProductPrices'
import { editPricingBook, fetchPricingbooksData, pricingBooksSelector } from 'store/metaData/pricingbook'
import CheckboxGroupForm from '../components/CheckboxGroupForm'

// ** Custom Field Form

const schema = yup.object().shape({
  productId: yup.string().required('Product id is required'),
  productName: yup.string().required('Product name is required'),
  skuId: yup.string().required('Product sku id is required'),
  skuName: yup.string().required('Product sku name is required'),
  skuDescription: yup.string().required('Product sku description is required')
})

const Container = styled(Grid)(({ theme }) => ({
  marginTop: 5,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

// ** Helpers
const findEditedObjects = (originalArray, editedArray) => {
  const editedObjects = []

  for (const editedObj of editedArray) {
    const matchingOriginalObj = originalArray.find(originalObj => originalObj.id === editedObj.id)

    if (!matchingOriginalObj) {
      // If there's no matching object in the original array, consider it edited
      editedObjects.push(editedObj)
    } else {
      // Compare properties to find edited values
      for (const key in editedObj) {
        if (editedObj[key] !== matchingOriginalObj[key]) {
          editedObjects.push(editedObj)
          break // Stop comparing if an edit is found
        }
      }
    }
  }

  return editedObjects
}
const findMissingObjects = (array1, array2) => {
  // Create a map of unique object identifiers from array2
  const objectMap = new Map()
  for (const obj of array2) {
    if (!!obj.id) objectMap.set(obj.id, true) // Assuming objects have an "id" property
  }

  // Filter array1 to get objects that are missing in array2
  const missingObjects = array1.filter(obj => !objectMap.has(obj.id))

  return missingObjects
}
const initialValues = {
  productId: '',
  skuId: '',
  productName: '',
  skuName: '',
  skuDescription: '',
  prices: [],
  type: []
}

const SubscriptionForm = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  // ** State
  const [previousPrices, setPreviousPrices] = useState([])
  const [pricingBookId, setPricingBookId] = useState(null)

  // ** Selectors
  const { product, success, error, loading } = useSelector(productsSelector)
  const { pricingBooksData } = useSelector(pricingBooksSelector)

  // ** Hooks
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  // ** Fetch product
  useEffect(() => {
    if (id) {
      dispatch(getProductData(id))
    }
    dispatch(fetchPricingbooksData())
    return () => {
      dispatch(resetProduct())
    }
  }, [id])

  const setValues = useMemo(() => {
    if (id && product?.id && Object.keys(product).length > 0) {
      dispatch(fetchPricingbooksData())
      // Iterate over the product properties and set them in the form
      Object.keys(initialValues).forEach(key => {
        if (key === 'type') {
          setValue('type', [
            ...(product?.is_isv_product ? ['is_isv_product'] : []),
            ...(product?.is_premiercloud_product ? ['is_premiercloud_product'] : [])
          ])
        } else if (key !== 'prices') setValue(key, product[key] ?? []) // exclude prices property because it will get in the following handler
      })
    }
  }, [product])

  const previousPricesHandler = useMemo(() => {
    if (pricingBooksData?.length > 0 && id) {
      const data = pricingBooksData?.find(item => item.is_default)
      setPricingBookId(data?.id)
      const old_prices = data?.prices?.filter(item => item?.productId?.toString() === id.toString())
      setPreviousPrices(old_prices || [])
      setValue('prices', old_prices ?? [])
    } else {
      const data = pricingBooksData?.find(item => item.is_default)
      setPricingBookId(data?.id)
    }
  }, [pricingBooksData])

  // ** Functions
  const cancelHandler = () => navigate('/subscriptions')

  const onSubmit = (data, e) => {
    const new_prices = findEditedObjects(previousPrices, data?.prices)?.map(item => {
      const { updatedAt, createAt, ...rest } = item
      return rest
    })
    const deleted_prices = findMissingObjects(previousPrices, data.prices)?.map(item => item.id) || []
    const { prices, type, ...rest } = data
    const pricing_body = {
      ...(pricingBookId ? { id: pricingBookId } : {}),
      ...(new_prices?.length > 0 ? (pricingBookId ? { prices: new_prices } : { prices: data.prices }) : {}),
      ...(pricingBookId && deleted_prices?.length > 0 ? { deletedPrices: deleted_prices } : {})
    }
    const types = {
      is_premiercloud_product: data.type?.includes('is_premiercloud_product'),
      is_isv_product: data.type?.includes('is_isv_product')
    }

    if (id) {
      dispatch(
        updateProduct(
          id,
          { ...rest, ...types },
          pricingBookId && (new_prices?.length > 0 || deleted_prices?.length > 0) ? pricing_body : null
        )
      )
    } else {
      dispatch(newProduct({ product: { ...rest, ...types }, navigate, pricing_body }))
    }
  }

  const resetMessagesHandler = () => dispatch(cleanMessages())

  return (
    <Card
      sx={{
        boxShadow: 0,
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        py: 10,
        px: 5
      }}
    >
      {loading == 'GET_PRODUCT' && <FallbackSpinner />}
      {!loading !== 'GET_PRODUCT' && (
        <form onSubmit={handleSubmit(onSubmit)} id='subscription-form'>
          <Container container spacing={5}>
            <Grid item xs={12}>
              <Typography variant='h5' sx={{ mb: '1rem' }}>
                Subscription informations
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormInput
                control={control}
                errors={errors}
                fullWidth
                name='productId'
                placeholder='Google-drive-storage'
                label='Product Id'
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormInput
                control={control}
                errors={errors}
                fullWidth
                name='productName'
                placeholder='Google Workspace'
                label='Product name'
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormInput
                control={control}
                errors={errors}
                fullWidth
                name='skuId'
                placeholder='1010101010'
                label='Sku id'
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormInput
                control={control}
                errors={errors}
                fullWidth
                name='skuName'
                placeholder='Google Workspace Business Standard'
                label='Sku name'
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <FormInput
                control={control}
                errors={errors}
                fullWidth
                name='skuDescription'
                placeholder=''
                label='Sku Description'
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' sx={{ mt: '1rem' }}>
                Type
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CheckboxGroupForm
                control={control}
                watch={watch}
                name='type'
                options={[
                  { label: 'Premier Cloud Product', value: 'is_premiercloud_product' },
                  { label: 'ISV Product', value: 'is_isv_product' }
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TableProductPrices setValue={setValue} previousPrices={previousPrices} product={product} />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: `${id ? 'space-between' : 'end'}` }}>
              {id && (
                <Box>
                  <DeleteProductModal productId={id} />
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Button
                  size='medium'
                  variant='text'
                  sx={{ color: theme => theme.palette.secondary.light, mx: '0.5rem', fontWeight: 600 }}
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loading === 'CREATE_PRODUCT' || loading === 'UPDATE_PRODUCT'}
                  startIcon={
                    (loading === 'CREATE_PRODUCT' || loading === 'UPDATE_PRODUCT') && (
                      <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                    )
                  }
                  sx={{
                    borderRadius: '3px',
                    '&:hover': { backgroundColor: 'common.blue' },
                    '&:disabled': { backgroundColor: theme => theme.palette.secondary.light }
                  }}
                  size='medium'
                  type='submit'
                  form='subscription-form'
                  variant='contained'
                >
                  {id ? 'Update' : 'Create'}
                </Button>
              </Box>
            </Grid>
          </Container>
        </form>
      )}
      <AlertMessages
        success={success}
        error={error}
        messageValue={success === 'UPDATE_PRODUCT' ? 'UPDATE_PRODUCT' : 'CREATE_PRODUCT'}
        message={
          (success === 'UPDATE_PRODUCT' && 'Subscription was updated successfully !') ||
          (success === 'CREATE_PRODUCT' && 'Subscription was created successfully !') ||
          (error && error.message)
        }
        resetHandler={resetMessagesHandler}
      />
    </Card>
  )
}

export default SubscriptionForm
