// ** Icon imports
import HomeOutline from '@mui/icons-material/InsertChartOutlined'
import BillingCard from 'mdi-material-ui/CreditCardOutline'
import Account from 'mdi-material-ui/ClipboardAccountOutline'
import Settings from 'mdi-material-ui/CogOutline'
import Subscriptions from 'mdi-material-ui/Google'
import Pricingbook from 'mdi-material-ui/CashMultiple'
import ChecklistIcon from '@mui/icons-material/CheckOutlined'
import MovingIcon from '@mui/icons-material/Moving'

const navigation = () => {
  return [
    {
      title: 'Customers',
      icon: Account,
      path: '/customers',
      children: [
        {
          title: 'Customers Onboarding',
          path: '/customers/onboarding-list',
          // badgeContent: '5',
          badgeColor: 'error'
        },
        {
          title: 'Customers List',
          path: '/customers/customers-list'
        }
      ]
    },
    {
      title: 'Dashboard',
      icon: HomeOutline,
      path: '/dashboard'
    },
    {
      title: 'Subscriptions',
      icon: Subscriptions,
      path: '/subscriptions'
    },
    {
      title: 'Commissions',
      icon: ChecklistIcon,
      path: '/pricing-books',
      children: [
        {
          openInNewTab: true,
          title: 'Rules',
          path: '/commissions/rules'
        },
        {
          openInNewTab: true,
          title: 'Opprtunities',
          path: '/commissions/opportunities'
        },
        // {
        //   openInNewTab: true,
        //   title: 'Deals',
        //   path: '/commissions/deals'
        // },
        {
          openInNewTab: true,
          title: 'Payouts',
          path: '/commissions/payouts'
        }
      ]
    },
    {
      title: 'Pricing Books',
      icon: Pricingbook,
      path: '/pricing-books',
      children: [
        {
          openInNewTab: true,
          title: 'Pricing Books',
          path: '/pricing-books'
        },
        {
          openInNewTab: true,
          title: 'Customers Pricing Books',
          path: '/pricing-books/customers'
        }
      ]
    },
    {
      title: 'Billing',
      icon: BillingCard,
      path: '/billing',
      children: [
        {
          openInNewTab: true,
          title: 'Billing',
          path: '/billing'
        },
        {
          openInNewTab: true,
          title: 'Recalculation',
          path: '/billing/recalculation'
        },
        {
          openInNewTab: true,
          title: 'Billing Reconciliation',
          path: '/billing/comparator'
        },
        {
          openInNewTab: true,
          title: 'Billing Freshbooks Reconciliation',
          path: '/billing/freshbooks/comparator'
        },
        {
          openInNewTab: true,
          title: 'Billing Generation',
          path: '/billing/generation'
        },
        {
          openInNewTab: true,
          title: 'Billing Daily Usages',
          path: '/billing/check-daily-usages'
        }
      ]
    },
    {
      title: 'Settings',
      icon: Settings,
      path: '/settings',
      children: [
        {
          openInNewTab: true,
          title: 'Tax',
          path: '/settings/tax'
        },
        {
          openInNewTab: true,
          title: 'Currency',
          path: '/settings/currency'
        },
        {
          openInNewTab: true,
          title: 'Payment Methods',
          path: '/settings/payment-methods'
        },
        {
          openInNewTab: true,
          title: 'Payment Terms',
          path: '/settings/due-date'
        },
        {
          openInNewTab: true,
          title: 'Commitment Duration',
          path: '/settings/commitment-duration'
        },
        {
          openInNewTab: true,
          title: 'Price Plan',
          path: '/settings/price-plan'
        },
        {
          openInNewTab: true,
          title: 'Engagement Type',
          path: '/settings/engagement-type'
        }
      ]
    }
  ]
}

export default navigation
