// React Imports
import React, { forwardRef, useState } from 'react'

// MUI Imports
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const SkusDialog = props => {
  // ** Props
  const { active, customerSkus, freshBooksSkus } = props

  // States
  const [open, setOpen] = useState(false)

  const handleClickOpen = event => {
    event.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      <Typography noWrap variant='body2' color='black' sx={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        {active === 1 ? customerSkus : freshBooksSkus}
      </Typography>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth='lg'
        fullWidth
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>Compare SKUs</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' sx={{ display: 'flex', gap: 10 }}>
            <Box>
              <Typography variant='body1' fontWeight={500}>
                App Skus :
              </Typography>
              <ul>
                {customerSkus
                  ?.split(',')
                  ?.map(item => item?.trim())
                  ?.filter((item, index) => customerSkus.indexOf(item) === index)
                  ?.filter(item => item)
                  ?.map((item, index) => (
                    <li key={index} style={{ color: 'black' }}>
                      {item}
                    </li>
                  ))}
              </ul>
            </Box>
            <Box>
              <Typography variant='body1' fontWeight={500}>
                Freshbooks Skus :
              </Typography>
              <ul>
                {freshBooksSkus
                  ?.split(',')
                  ?.map(item => item?.trim())
                  ?.filter((item, index) => freshBooksSkus.indexOf(item) === index)
                  ?.filter(item => item)
                  ?.map((item, index) => (
                    <li key={index} style={{ color: 'black' }}>
                      {item}
                    </li>
                  ))}
              </ul>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='text'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SkusDialog
