// ** React Imports
import React from 'react'

// ** MUI Imports
import { Grid } from '@mui/material'

// ** Custom Components
import ContactInformations from '../cards/ContactInformations'
import GoogleWorkspaceLicensing from '../cards/GoogleWorkspaceLicensing'
import Subscriptions from '../cards/Subscriptions'
import OrderHistory from '../cards/OrderHistory'


const Overview = (props) => {



    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <ContactInformations setValue={props.setValue}/>
            </Grid>
            <Grid item xs={12}>
                <GoogleWorkspaceLicensing />
            </Grid>
            <Grid item xs={12}>
                <Subscriptions />
            </Grid>
            <Grid item xs={12}>
                <OrderHistory />
            </Grid>
        </Grid >
    )
}


export default Overview