// ** Axios
import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** API Imports
import { createMetadata, editMetadata, getMetaDataData, getTaxData } from 'configs/axios/api_helper'

export const initialState = {
  loading: null,
  taxData: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const taxSlice = createSlice({
  name: 'taxData',
  initialState,
  reducers: {
    addTax: (state, action) => {
      state.taxData.push(action.payload)
      ;(state.success = 'CREATE'), (state.loading = null)
    },
    updateTax: (state, action) => {
      state.taxData = state.taxData.map(tax =>
        tax.id.toString() === action.payload.id.toString() ? { tax, ...action.payload } : tax
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removeTax: (state, action) => {
      state.taxData = state.taxData.filter(tax => tax.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getTaxDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.taxData = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getTaxDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const { addTax, updateTax, removeTax, startLoading, resetMessages, getTaxDataFailure, getTaxDataSuccess } =
  taxSlice.actions

// export user selector to get the slice in any component
export const taxSelector = state => state.taxData

// export The reducer
const taxReducer = taxSlice.reducer
export default taxReducer

// Actions
export const fetchTaxData = () => async dispatch => {
  try {
    dispatch(startLoading('GET'))
    const { data } = await getMetaDataData('type/tax')
    dispatch(getTaxDataSuccess(data ?? []))
  } catch (error) {
    dispatch(getTaxDataFailure())
  }
}

export const createTax = tax => async dispatch => {
  try {
    dispatch(startLoading('CREATE'))
    const data = await createMetadata({ ...tax, type: 'tax' })
    dispatch(addTax(data))
  } catch (error) {
    dispatch(getTaxDataFailure())
  }
}

export const deleteTax = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE'))
    const data = await editMetadata(id, { status: 'archived' })
    dispatch(removeTax(id))
  } catch (error) {
    dispatch(getTaxDataFailure())
  }
}

export const editTax = tax => async dispatch => {
  try {
    const { id, ...rest } = tax
    dispatch(startLoading('UPDATE'))
    const data = await editMetadata(id, { ...rest })
    dispatch(updateTax(tax))
  } catch (error) {
    dispatch(getTaxDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
