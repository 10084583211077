// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

// ** 3rd Party Libraries
import moment from 'moment'
import { currencySelector } from 'store/metaData/currency'

const InvoiceTableRow = props => {
  // ** Props
  const { row, type } = props

  // ** Selectors
  const { customerInformation, invoiceInformation } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** Constants
  const currency = currenciesData?.find(item => item.iso_code === 'CAD') || null

  // Calculate single row
  const calculateSingleRow = (prices, quantity) => {
    const customerCurrencyId = 2
    const currencySymbol = currency?.iso_code === 'EUR' ? '€' : '$'
    const price = (prices?.find(item => item?.currencyId === customerCurrencyId)?.totalValue || 0) * (quantity || 1)
    if (price < 0) return '-' + currencySymbol + ((price || 0) * -1)?.toFixed(2)
    else return currencySymbol + (price || 0)?.toFixed(2)
  }

  // Format date
  const formatDate = intervals => {
    return intervals.map(period => {
      const start = moment(period.from, 'YYYY-MM-DD')
      const end = moment(period.to, 'YYYY-MM-DD')
      if (start.format('D') == end.format('D') && start.format('M') == end.format('M')) {
        return moment(start).format('MMM DD, YYYY')
      } else if (start.format('D') != end.format('D') && start.format('M') == end.format('M')) {
        return moment(start).format('MMM DD') + ' - ' + moment(end).format('DD, YYYY')
      } else if (start.format('M') != end.format('M')) {
        return moment(start).format('MMM DD') + ' - ' + moment(end).format('MMM DD, YYYY')
      } else return ''
    })
  }

  const formatDateGoogle = (start_date, end_date) => {
    const start = moment(start_date, 'YYYY-MM-DD')
    const end = moment(end_date, 'YYYY-MM-DD')
    if (start.format('D') == end.format('D') && start.format('M') == end.format('M')) {
      return moment(start).format('MMM DD, YYYY')
    } else if (start.format('D') != end.format('D') && start.format('M') == end.format('M')) {
      return moment(start).format('MMM DD') + ' - ' + moment(end).format('DD, YYYY')
    } else if (start.format('M') != end.format('M')) {
      return moment(start).format('MMM DD') + ' - ' + moment(end).format('MMM DD, YYYY')
    } else return ''
  }

  return (
    <TableRow key={row.id} sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
      <TableCell component='th' scope='row'>
        {type === 'premierCloud'
          ? formatDate(row.intervals).join(' / ') ?? ''
          : formatDateGoogle(row.start_date, row.end_date)}
      </TableCell>
      <TableCell>
        {(type === 'premierCloud' ? row.sku_name : row.subscription) +
          ': ' +
          (row.licence_type?.toLowerCase()?.includes('annual')
            ? 'Commitment for ' + row.seats + ' seats'
            : 'Usage for ' + row.seats)}
        {/* <br />
        {'Domain: ' + findDomain(row.subscription_id)}
        <br />
        {coverPeriod(row.intervals)}
        <br />
        {formatDiscountDate(row.discount?.[0], row.sku_id, row?.licence_type)} */}
      </TableCell>
      <TableCell align='right'>
        {type === 'premierCloud' ? calculateSingleRow(row.prices, row.quantity) : row.price}
      </TableCell>
    </TableRow>
  )
}

export default React.memo(InvoiceTableRow)
