// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress';

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/Delete'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, manageContacts } from 'store/customers'


const DeleteContactModal = (props) => {

    // ** Props
    const { contact } = props
    // ** Hooks 
    const dispatch = useDispatch()

    // **Selectors
    const { success, error, loading } = useSelector(customersSelector);

    // ** State
    const [open, setOpen] = useState(false)

    // Check hooks
    useEffect(() => {
        if (success == "UPDATE_CONTACT" || error == "UPDATE_CONTACT") {
            setOpen(false)
        }
    }, [success, error])

    // ** Functions
    const handleClickOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    // ** Functions
    const deleteContactHandler = () => {
        dispatch(manageContacts({ id: contact?.customerId, deletedContacts: [contact?.id] }))
    }

    return (
        <Fragment>
            <IconButton
                size="small"
                onClick={handleClickOpen}
            >
                <DeleteIcon sx={{ fontSize: "1rem" }} />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Areyou sure to delete this contact</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Clicking Delete will lead to deleting the contact permenantly. If you don't want to proceed with this action you can click Cancel

                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-actions-dense' sx={{ mt: "1rem" }}>
                    <Button
                        disabled={loading ? true : false}
                        onClick={handleClose}
                        size="small"
                        variant="text"
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading ? true : false}
                        onClick={deleteContactHandler}
                        startIcon={(loading == "UPDATE_CONTACT" && <CircularProgress sx={{ color: 'common.white' }} size="1rem" />)}
                        variant="text"
                        size="small"
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default DeleteContactModal