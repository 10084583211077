// ** REACT Imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import { Typography, Grid, Box, useMediaQuery } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

// ** Custom Components
import ProductsList from './components/ProductsList'

// ** Third Party Libraries
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Hooks
import { useDispatch, useSelector } from 'react-redux'
import { getProductsData, productsSelector } from 'store/products'
import { customersSelector } from 'store/customers'

const Link = styled(Typography, { shouldForwardProp: prop => prop !== 'active' })(({ theme, active }) => ({
  fontSize: 16,
  fontWeight: 500,
  cursor: 'pointer',
  '&:hover': { color: theme.palette.secondary.main, fontWeight: 700 },
  padding: '14px 28px',
  ...(active ? { color: theme.palette.secondary.main, fontWeight: 700 } : {})
}))

const Container = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 100px)',
  [theme.breakpoints.down('md')]: {
    height: '100%'
  },
  padding: '0 0px 5px 15px',
  display: 'flex',
  flexDirection: 'column',
  gap: 15
}))

// const categories = {
//   workspace: GoogleWorkspace,
//   workspaceAddOns: GoogleWorkspaceAddOns,
//   googleVoice: () => <></>,
//   googleIdentity: () => <></>,
//   deviceAndBrowser: () => <></>,
//   googleWorkspaceForEducation: () => <></>
// }

const categories = [{ name: 'All products' }, { name: 'Premier Cloud products' }, { name: 'ISV products' }]

const PurchaseContainer = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** State
  const [activeCategory, setActiveCategory] = useState({ name: 'All products' })
  const [categoryName, setCategoryName] = useState('All products')

  // ** Selectors
  const { products, loading } = useSelector(productsSelector)
  const { loading: customerInformationLoading } = useSelector(customersSelector)

  // ** Constants
  const filteredProducts =
    activeCategory.name === 'All products'
      ? products?.filter(item => item.is_isv_product || item.is_premiercloud_product)
      : activeCategory.name === 'Premier Cloud products'
      ? products?.filter(item => item.is_premiercloud_product)
      : activeCategory.name === 'ISV products'
      ? products?.filter(item => item.is_isv_product)
      : []

  // ** Queries
  useEffect(() => {
    dispatch(getProductsData({ limit: 1000, field: '' }))
  }, [])

  // ** Functions
  const changeCategory = category => {
    setCategoryName(category?.name)
    setActiveCategory(category)
  }

  return (
    <Container>
      <Box sx={{ width: '100%', height: 10 }}>
        <LinearProgress sx={{ ...(loading || customerInformationLoading ? {} : { display: 'none' }) }} />
      </Box>
      <Grid
        container
        sx={{
          position: 'relative',
          ...(loading || customerInformationLoading ? { opacity: 0.5, pointerEvents: 'none' } : {})
        }}
      >
        <Grid
          item
          sm={12}
          md={5}
          lg={4}
          xl={3}
          sx={{
            border: theme => `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '3px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Box p='14px 28px' m='14px 0px 8px 0px'>
              <Typography fontSize={14} fontWeight={700} color='#9e9e9e'>
                CATEGORIES
              </Typography>
            </Box>
            <PerfectScrollbar>
              <Box display='flex' flexDirection='column' gap={1.5}>
                {categories.map((item, index) => (
                  <Link key={index} active={activeCategory?.name === item.name} onClick={() => changeCategory(item)}>
                    {item?.name}
                  </Link>
                ))}
              </Box>
            </PerfectScrollbar>
          </Box>
          <Box
            height='fit-content'
            p='14px 28px'
            sx={{ borderTop: theme => `1px solid ${theme.palette.grey[300]}` }}
            display='flex'
            flexDirection='column'
            gap={2}
          >
            <Typography fontWeight={700} fontSize={14}>
              Discover third-party services
            </Typography>
            <Typography fontSize={14}>
              Browse and install third-party apps that integrate with and enhance Google Workspace
            </Typography>
            <Typography fontSize={14} fontWeight={600} sx={{ textDecoration: 'underline' }} color='secondary.main'>
              Explore Google Workspace Marketplace
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} lg={8} xl={9} spacing={6} sx={{ px: small ? 0 : 5 }}>
          <PerfectScrollbar>
            <Box height='620px'>
              <ProductsList
                skusData={filteredProducts || []}
                activeCategory={activeCategory}
                categoryName={categoryName}
              />
            </Box>
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PurchaseContainer
