// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/Delete'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, deleteInvoice } from 'store/customers'
import moment from 'moment'

const DeleteInvoiceModal = props => {
  // ** Props
  const { invoice, customerId } = props
  // ** Hooks
  const dispatch = useDispatch()

  // **Selectors
  const { success, error, loading } = useSelector(customersSelector)

  // ** State
  const [open, setOpen] = useState(false)

  // Check hooks
  useEffect(() => {
    if (success == 'REMOVE_INVOICE' || error == 'REMOVE_INVOICE') {
      setOpen(false)
    }
  }, [success, error])

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  // ** Functions
  const deleteInvoiceHandler = e => {
    e.stopPropagation()
    dispatch(deleteInvoice(customerId, invoice?.id, moment(invoice?.due_date).utc(false).format('YYYY-MM-DD')))
  }

  return (
    <Fragment>
      <IconButton size='small' sx={{ color: 'common.black', ml: '1rem' }} onClick={handleClickOpen}>
        <DeleteIcon sx={{ fontSize: '1.25rem' }} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Are you sure you want to delete this invoice ?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Clicking Delete will lead to deleting the invoice permenantly. If you don't want to proceed with this action
            you can click Cancel
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button
            disabled={loading ? true : false}
            onClick={handleClose}
            size='small'
            variant='contained'
            color='inherit'
          >
            Cancel
          </Button>
          <Button
            disabled={loading ? true : false}
            onClick={deleteInvoiceHandler}
            startIcon={loading == 'REMOVE_INVOICE' && <CircularProgress sx={{ color: 'common.white' }} size='1rem' />}
            variant='contained'
            size='small'
            color='error'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default DeleteInvoiceModal
