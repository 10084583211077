// ** React Imports
import React, { useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector, getProductsData } from 'store/products'
import {
  customersSelector,
  addCustomerInvoiceTemplate,
  updateCustomerInvoiceTemplate,
  deleteCustomerInvoiceTemplate
} from 'store/customers'

// ** Custom Components
import InvoiceTemplateTable from '../tables/InvoiceTemplateTable'

const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  gap: 20,
  minHeight: 'calc(100vh - 300px)'
}))

const InvoiceTemplate = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Selectors
  const { products } = useSelector(productsSelector)
  const { customerInformation, loading } = useSelector(customersSelector)

  // ** State
  const [action, setAction] = useState('listing')
  const [type, setType] = useState('workspace')
  const [subs, setSubs] = useState([])
  const [status, setStatus] = useState('active')
  const [invoiceId, setInvoiceId] = useState(null)

  // ** Fetch data
  useEffect(() => {
    dispatch(getProductsData({ limit: 1000, page: 1, field: '' }))
  }, [])

  // ** Constants
  const availableProducts = products
    ?.filter(product =>
      customerInformation?.googleIds?.some(googleId =>
        googleId?.subscriptions?.some(sub => sub.product?.id === product.id)
      )
    )
    ?.filter(product => {
      if (type === 'gcp' || type === 'project') return false
      else if (type === 'workspace') return !product.is_isv_product && !product.is_premiercloud_product
      else if (type === 'isv') return product.is_isv_product
      else if (type === 'premier cloud') return product.is_premiercloud_product
      else return false
    })

  // ** Functions
  const onChangeType = event => setType(event.target.value)
  const onChangeStatus = event => setStatus(event.target.value)
  const onChangeSubscription = selected => {
    setSubs(selected)
  }

  const backHandler = () => {
    setStatus('active')
    setType('workspace')
    setSubs([])
    setInvoiceId(null)
    setAction('listing')
  }

  const deleteHandler = () => {
    if (invoiceId) {
      dispatch(
        deleteCustomerInvoiceTemplate(invoiceId, () => {
          setAction('listing')
        })
      )
    }
  }

  const onSubmit = () => {
    const body = {
      status: status,
      type: type,
      customerId: customerInformation.id,
      skuList: subs?.map(item => item.skuId) || []
    }
    if (invoiceId) {
      dispatch(
        updateCustomerInvoiceTemplate(invoiceId, body, () => {
          setAction('listing')
        })
      )
    } else
      dispatch(
        addCustomerInvoiceTemplate(body, () => {
          setAction('listing')
        })
      )
  }

  return (
    <Grid container>
      {action === 'listing' ? (
        <Grid item xs={12}>
          <InvoiceTemplateTable
            setAction={setAction}
            setInvoiceId={setInvoiceId}
            setStatus={setStatus}
            setSubs={setSubs}
            setType={setType}
          />
        </Grid>
      ) : null}
      {action === 'add' ? (
        <>
          <Grid display='flex' justifyContent='end' gap={5} item xs={12}>
            {invoiceId ? (
              <Button color='error' variant='text' size='medium' onClick={deleteHandler} sx={{ borderRadius: 0 }}>
                Delete
              </Button>
            ) : null}
            <Button color='secondary' variant='text' size='medium' onClick={backHandler} sx={{ borderRadius: 0 }}>
              Back
            </Button>
            <Button
              color='primary'
              variant='contained'
              size='medium'
              onClick={onSubmit}
              sx={{ borderRadius: 0 }}
              endIcon={
                loading === 'ADD_INVOICE_TEMPLATE' || loading === 'UPDATE_INVOICE_TEMPLATE' ? (
                  <CircularProgress sx={{ color: 'white' }} size={15} />
                ) : null
              }
            >
              Create Template
            </Button>
          </Grid>
          <Container item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography fontSize='1rem' fontWeight={600}>
                Types :
              </Typography>
              <Select value={type} onChange={onChangeType} sx={{ width: 550, borderRadius: 0 }} size='medium'>
                {[
                  { label: 'Workspace', value: 'workspace' },
                  { label: 'Google voice', value: 'google voice' },
                  { label: 'Project', value: 'project' },
                  { label: 'GCP', value: 'gcp' },
                  { label: 'Premier Cloud', value: 'premier cloud' },
                  { label: 'ISV', value: 'isv' }
                ].map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography fontSize='1rem' fontWeight={600}>
                Subscriptions :
              </Typography>
              <Autocomplete
                multiple
                limitTags={2}
                disableCloseOnSelect
                value={subs || []}
                onChange={(event, values) => onChangeSubscription(values)}
                sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
                options={availableProducts || []}
                getOptionLabel={option => option.skuName}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Subscriptions'
                    sx={{
                      width: 550,
                      [`& fieldset`]: {
                        borderRadius: 0
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
              <Typography fontSize='1rem' fontWeight={600}>
                Status :
              </Typography>
              <Select value={status} onChange={onChangeStatus} sx={{ width: 550, borderRadius: 0 }} size='medium'>
                {[
                  { label: 'Active', value: 'active' },
                  { label: 'inActive', value: 'inactive' }
                ].map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Container>
        </>
      ) : null}
    </Grid>
  )
}

export default InvoiceTemplate
