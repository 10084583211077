// ** React Imports
import React from 'react'

// ** MUI Imports
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'

// ** Component Imports
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const CustomAutocomplete = props => {
  // ** Props
  const { data, boardedCustomers, setData, loadingCustomers } = props

  // ** Constants
  const allSelected = boardedCustomers?.length === data?.customers?.length

  // ** Functions

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setData(state => ({ ...state, customers: boardedCustomers }))
    } else {
      setData(state => ({ ...state, customers: [] }))
    }
  }

  const onChangeAutocomplete = (event, selectedOptions, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (!!selectedOptions.find(option => option.id === 'select-all')) {
        handleSelectAll(!allSelected)
      } else {
        setData(state => ({ ...state, customers: selectedOptions }))
      }
    } else if (reason === 'clear') {
      setData(state => ({ ...state, customers: [] }))
    }
  }

  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.id === 'select-all' // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {}
    return (
      <li {...props} key={option.id}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.organization}
      </li>
    )
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={2}
      loading={loadingCustomers}
      disabled={loadingCustomers}
      onChange={onChangeAutocomplete}
      value={data?.customers || []}
      options={[{ organization: 'Select All', id: 'select-all' }, ...boardedCustomers]}
      getOptionLabel={option => option?.organization}
      size='small'
      sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
      renderOption={optionRenderer}
      renderInput={params => (
        <TextField
          {...params}
          label='Search for customers by organization or domain'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingCustomers ? <CircularProgress color='primary' size={20} sx={{ mr: 10 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default CustomAutocomplete
