// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import Backdrop from '@mui/material/Backdrop'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'

// ** Icon Imports
import Refresh from 'mdi-material-ui/Refresh'
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'

// ** Custom Component
import OrderHistoryTable from '../tables/OrderHistoryTable'


// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    border: `solid 1px ${theme.palette.grey[300]}`
}))

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300],
  marginTop: "-0.5rem",
  width: "120%",
  marginLeft: "-10%"
}))


const OrderHistory = () => {
  // ** States
  const [reload, setReload] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [visibility, setVisibility] = useState(true)

  const handleBackDrop = () => {
    setReload(true)
    setTimeout(() => {
      setReload(false)
    }, 2000)
  }

  return (
    <Fade in={visibility} timeout={300}>
      <StyledCard sx={{ position: 'relative' }}>
        <CardHeader
          title='Order History'
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                size='small'
                aria-label='collapse'
                sx={{ mr: 2, color: 'text.secondary' }}
                onClick={() => setCollapsed(!collapsed)}
              >
                {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
              </IconButton>
              <IconButton
                size='small'
                aria-label='reload'
                onClick={() => handleBackDrop()}
                sx={{ mr: 2, color: 'text.secondary' }}
              >
                <Refresh fontSize='small' />
              </IconButton>
            </Box>
          }
        />
        <Collapse in={collapsed}>
          <CardContent sx={{ml: "-1rem"}}>
          <StyledDivider />
            <Typography variant='body2'>
              {/* <OrderHistoryTable /> */}
            </Typography>
          </CardContent>

          <Backdrop
            open={reload}
            sx={{
              position: 'absolute',
              color: theme => theme.palette.common.white,
              zIndex: theme => theme.zIndex.mobileStepper - 1
            }}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </Collapse>
      </StyledCard>
    </Fade>
  )
}

export default OrderHistory
