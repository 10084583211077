// ** React Imports
import React, { forwardRef, useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import CardHeader from '@mui/material/CardHeader'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress';



// ** Third Party Imports
import toast from 'react-hot-toast'
import { useForm, Controller } from 'react-hook-form'

// ** Icons Imports


// ** Redux Imports 
import { useDispatch, useSelector } from 'react-redux'
import { cleanMessages, customersSelector, manageContacts } from 'store/customers'





const defaultValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
}

const Container = styled(Grid)(({ theme }) => ({
    marginTop: "2rem",
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
    },
    [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
    }
}))



const ContactsForm = (props) => {

    // ** 
    const { hide } = props

    // ** Redux dispatcher
    const dispatch = useDispatch()

    // ** Selectors 
    const { customerInformation, success, error, loading } = useSelector(customersSelector);


    // ** Clean up after success
    useEffect(() => {
        if (success == "UPDATE") {
            reset({ ...defaultValues })
            setTimeout(() => {
                dispatch(cleanMessages())
                hide(false)
            }, 1500)
        }
    }, [success, error])


    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset
    } = useForm({ defaultValues })

    const onSubmit = (data, e) => {
        // Create New contact
        dispatch(manageContacts({ id: customerInformation.id, contacts: data }))
    }




    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Container container spacing={5}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name='firstname'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <TextField
                                    value={value}
                                    label='First Name'
                                    onChange={onChange}
                                    placeholder='Carter'
                                    error={Boolean(errors.firstname)}
                                    aria-describedby='validation-basic-firstname'
                                    InputLabelProps={{
                                        sx: {
                                            color: theme => theme.palette.grey[500],
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.firstname && (
                            <FormHelperText sx={{ color: 'error.main' }} >
                                This field is required
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name='lastname'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <TextField
                                    value={value}
                                    label='Last Name'
                                    onChange={onChange}
                                    placeholder='Carter'
                                    error={Boolean(errors.lastname)}
                                    aria-describedby='validation-basic-lastname'
                                    InputLabelProps={{
                                        sx: {
                                            color: theme => theme.palette.grey[500],
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.lastname && (
                            <FormHelperText sx={{ color: 'error.main' }} >
                                This field is required
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name='phone'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <TextField
                                    value={value}
                                    label='Phone'
                                    onChange={onChange}
                                    placeholder='+1 859 778 578'
                                    error={Boolean(errors.phone)}
                                    aria-describedby='validation-basic-phone'
                                    InputLabelProps={{
                                        sx: {
                                            color: theme => theme.palette.grey[500],
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.phone && (
                            <FormHelperText sx={{ color: 'error.main' }} >
                                This field is required
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Controller
                            name='email'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <TextField
                                    value={value}
                                    label='Email'
                                    onChange={onChange}
                                    placeholder='jhon.doe@email.com'
                                    error={Boolean(errors.email)}
                                    aria-describedby='validation-basic-email'
                                    InputLabelProps={{
                                        sx: {
                                            color: theme => theme.palette.grey[500],
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.email && (
                            <FormHelperText sx={{ color: 'error.main' }} >
                                This field is required
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        color="inherit"
                        sx={{ mx: 2 }}
                        size='medium'
                        type='submit'
                        variant='contained'
                        onClick={(e) => { e.preventDefault(); hide(false) }}>
                        Cancel
                    </Button>
                    <Button
                        startIcon={(loading == "UPDATE_CONTACT") && <CircularProgress sx={{ color: 'common.white' }} size="1rem" />}
                        size='medium'
                        type='submit'
                        variant='contained'>
                        Create
                    </Button>

                </Grid>
            </Container>
        </form>
    )
}

export default ContactsForm
