// ** React Imports
import React from 'react'

// ** MUI Imports
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// ** Third Party Imports
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

const DatePickerForm = props => {
  // ** Props
  const { name, control, label, placeholder, errors, fullWidth, minDate, maxDate, size } = props
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth={fullWidth}>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <DesktopDatePicker
              label={label}
              value={value ?? ''}
              onChange={onChange}
              minDate={minDate ? new Date(minDate) : null}
              maxDate={maxDate ? new Date(maxDate) : null}
              error={Boolean(errors?.[name])}
              sx={{ width: '100%' }}
              slotProps={{ textField: { size: size || 'medium' } }}
              renderInput={params => <TextField {...params} fullWidth={fullWidth} />}
            />
            // <DatePicker
            //   label={label}
            //   value={value ?? ''}
            //   onChange={onChange}
            //   minDate={minDate ? new Date(minDate) : null}
            //   maxDate={maxDate ? new Date(maxDate) : null}
            //   error={Boolean(errors?.[name])}
            //   renderInput={params => <TextField {...params} size={size || 'medium'} fullWidth={fullWidth} />}
            // />
          )}
        />
        {errors?.[name] && (
          <FormHelperText sx={{ color: 'error.main' }} id={`validation-basic-${name}`}>
            {errors?.[name].message}
          </FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  )
}

DatePickerForm.propTypes = {
  control: PropTypes.func.isRequired, // Required string prop
  errors: PropTypes.func.isRequired, // Optional string prop
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
}

export default DatePickerForm
