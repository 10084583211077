import axios from 'axios'
//apply base url for axios
const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASEURL
})
axiosApi.defaults.headers.Accept = 'application/json'
axiosApi.defaults.headers.post['Content-Type'] = 'application/json'
axiosApi.interceptors.request.use(
  config => {
    return config
  },
  err => Promise.reject(err)
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response)
    .catch(error => error)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => response.data)
}

export async function uploadFile(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then(response => response.data)
}

const cloudFunctionApi = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_URL
})

cloudFunctionApi.defaults.headers.Accept = 'application/json'
cloudFunctionApi.defaults.headers.post['Content-Type'] = 'application/json'
cloudFunctionApi.interceptors.request.use(
  config => {
    return config
  },
  err => Promise.reject(err)
)

export async function cloudGet(url, config = {}) {
  return await cloudFunctionApi.get(url, { ...config }).then(response => response)
}
