// ** React Imports
import React, { useState } from 'react'
import Papa from 'papaparse'

// ** MUI Imports
import { Grid, Button, useMediaQuery, CircularProgress } from '@mui/material'

// ** Icons Imports
import ExportIcon from 'mdi-material-ui/TrayArrowDown'

// ** APIs
import { exportCustomers, exportCustomersToSheet, exportSubscriptions } from 'configs/axios/api_helper'

const ActionButtons = () => {
  // ** State
  const [loading, setLoading] = useState(false)
  // ** Hooks
  const xsmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // ** Functions
  const exportSubscriptionsHandler = async type => {
    setLoading(type)
    try {
      // Make an API call to fetch data
      const response = await exportSubscriptions()
      window.open(response.data.spreadsheetUrl, '_blank')
      setLoading(false)
    } catch (error) {
      console.error('Error fetching or exporting data:', error)
      setLoading(false)
    }
    
    // setLoading(type)
    // try {
    //   // Make an API call to fetch data
    //   const response = type === 'customers' ? await exportCustomers() : await exportSubscriptions()
    //   const data = response?.data
    //   if (data) {
    //     // Convert data to CSV format
    //     const csv = Papa.unparse(data)

    //     // Create a dynamic anchor element to trigger the download
    //     const blob = new Blob([csv], { type: 'text/csv' })
    //     const link = document.createElement('a')
    //     link.href = URL.createObjectURL(blob)
    //     link.download = type === 'customers' ? 'customers.csv' : 'subscriptions.csv'
    //     link.click()
    //   }
    //   setLoading(false)
    // } catch (error) {
    //   console.error('Error fetching or exporting data:', error)
    //   setLoading(false)
    // }
  }

  const exportCustomersHandler = async type => {
    setLoading(type)
    try {
      // Make an API call to fetch data
      const response = await exportCustomersToSheet()
      window.open(response.data.spreadsheetUrl, '_blank')
      setLoading(false)
    } catch (error) {
      console.error('Error fetching or exporting data:', error)
      setLoading(false)
    }
  }

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Grid container spacing={2}>
      <Grid item xs={2} sm={6}>
        <Button
          variant='text'
          sx={{ fontWeight: 600, fontSize: '0.825rem', whiteSpace: 'nowrap' }}
          startIcon={<ExportIcon />}
          onClick={() => exportCustomersHandler('customers')}
          disabled={loading}
          endIcon={
            loading === 'customers' && (
              <CircularProgress size='1rem' sx={{ color: theme => theme.palette.secondary.main }} />
            )
          }
        >
          {!xsmall && 'Export Customers'}
        </Button>
      </Grid>
      <Grid item xs={10} sm={6} sx={{ whiteSpace: 'nowrap' }}>
        <Button
          variant='text'
          sx={{ fontWeight: 600, fontSize: '0.825rem', whiteSpace: 'nowrap' }}
          startIcon={<ExportIcon />}
          onClick={() => exportSubscriptionsHandler('subscriptions')}
          disabled={loading}
          endIcon={
            loading === 'subscriptions' && (
              <CircularProgress size='1rem' sx={{ color: theme => theme.palette.secondary.main }} />
            )
          }
        >
          {!xsmall && 'Export Subscriptions'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ActionButtons
