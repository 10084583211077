// ** React Imports
import React, { forwardRef, Fragment, useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import CircularProgress from '@mui/material/CircularProgress'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'

// ** Icons Imports
import SearchIcon from 'mdi-material-ui/Magnify'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'
import { getFreshbooksSuggestions } from 'configs/axios/api_helper'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const FreshbooksOrganization = props => {
  // ** Props
  const { createdOrganization } = props

  // ** Hooks
  const inputRef = useRef()
  const { pathname } = useLocation()
  const customersType = pathname.split('/')[1]
  const page = pathname.split('/')[2]

  // ** State
  const [open, setOpen] = useState(false)
  const [organizationSuggestions, setOrganizationSuggestions] = useState([])
  const [freshbookSuggestion, setFreshbooksSuggestion] = useState(null)
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [loading, setLoading] = useState(false)

  // ** Selectors
  const { customerInformation, success, error } = useSelector(customersSelector)

  // ** Fetch for customer organization on first load
  useEffect(() => {
    const fetchOrganizations = async () => {
      if (customerInformation?.organization) {
        const response = await getFreshbooksSuggestions(customerInformation.organization)
        if (response?.status === 200) {
          const { data } = response
          if (!!data) setOrganizationSuggestions(data)
          if (data.length > 0) {
            setFreshbooksSuggestion({ id: data[0]?.id, organization: data[0]?.organization })
            props.setFreshBooksId({ id: data[0]?.id, organization: data[0]?.organization })
          }
        }
      }
    }

    if (customersType === 'customers' && page === 'customer-display') {
      setFreshbooksSuggestion({
        id: customerInformation?.freshbooksId,
        organization: customerInformation?.organization
      })
    } else if (customersType === 'google-customers' && page === 'customer-display') {
      fetchOrganizations()
    }
  }, [customerInformation])

  useEffect(() => {
    if (createdOrganization?.id && createdOrganization?.id !== selectedOrg?.id) {
      setSelectedOrg({ ...createdOrganization })
    }
  }, [createdOrganization])

  // ** Fucntions

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => {
    props.setFreshBooksId(selectedOrg)
    setOpen(false)
  }

  const handleListItemClick = org => {
    setSelectedOrg({ ...org })
  }
  const handleCancel = () => {
    inputRef.current.value = ''
    setOpen(false)
  }
  const debounced = useDebouncedCallback(
    async event => {
      const { name, value } = event.target
      if (value) {
        const response = await getFreshbooksSuggestions(value)
        if (response?.status === 200) {
          const { data } = response
          if (!!data) setOrganizationSuggestions(data)
        }
      } else getFreshbooksSuggestions('"') // Send " instead of empty string
    },
    500,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <Fragment>
      <Button
        variant='outlined'
        onClick={handleClickOpen}
        sx={{
          borderRadius: '0.5rem !important',
          textTransform: 'capitalize !important',
          border: theme => `1px solid ${theme.palette.grey[300]}`,
          color: 'common.black',
          '&:hover': {
            backgroundColor: theme => `${theme.palette.grey[50]} !important`,
            border: theme => `1px solid ${theme.palette.grey[300]}`
          }
        }}
      >
        {(selectedOrg?.organization !== undefined && selectedOrg?.organization) ||
          freshbookSuggestion?.organization ||
          '------'}
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '40%' // Set your width here
            }
          }
        }}
      >
        <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Search for Freshbooks organization</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                placeholder='Search customer or domain'
                size='small'
                sx={{ width: '100%', my: '1rem' }}
                onChange={debounced}
                ref={inputRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon sx={{ color: 'common.blue' }} />
                    </InputAdornment>
                  ),
                  endAdornment: <Fragment>{loading ? <CircularProgress color='inherit' size={20} /> : null}</Fragment>
                }}
              />
            </Grid>
            {organizationSuggestions?.map((organization, index) => (
              <Grid key={index} item xs={12}>
                <List>
                  <ListItem disablePadding sx={{ my: '-0.5rem' }}>
                    <ListItemButton
                      selected={selectedOrg?.id === organization?.id}
                      onClick={() =>
                        handleListItemClick({ id: organization?.id, organization: organization.organization })
                      }
                    >
                      <ListItemText
                        primary={<Typography>{organization?.organization + ' - ' + organization.pCountry}</Typography>}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Grid>
            ))}
            {organizationSuggestions.length < 1 && (
              <Typography variant='body1' sx={{ px: 5, mt: 5 }}>
                No organization found. Please type your organization name in the search field below
              </Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button sx={{ fontWeight: 600, color: 'secondary.light' }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={{ fontWeight: 600 }} onClick={handleClose} disabled={!selectedOrg?.id}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default FreshbooksOrganization
