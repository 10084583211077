// ** React Imports
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchPricingbooksData } from 'store/metaData/pricingbook'
import { currencySelector, fetchCurrencyData } from 'store/metaData/currency'

// ** Custom Components Imports
import RowPricingForm from './RowPricingForm'
import ProductsAutocompleteForm from './ProductsAutocompleteForm'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/CloseCircle'

const defaultRow = { pricingBookId: '', type: '', currencyId: null, value: 0 }

const combinedArrays = (array1, array2) => {
  const combinedArray = []
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      const combinedElement = `${array1[i]}-${array2[j]}`
      combinedArray.push(combinedElement)
    }
  }
  return combinedArray
}
const TableFormProducts = props => {
  // ** Props
  const { setValue, previousPrices, oldProducts, products } = props
  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()

  // ** Selectors
  const { currenciesData } = useSelector(currencySelector)

  // ** State
  const [prices, setPrices] = useState([])
  const [currencies, setCurrencies] = useState(['CAD', 'USD'])
  const [types, setTypes] = useState(['commitment', 'flexible'])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [columns, setColumns] = useState(['COMMITMENT-CAD', 'COMMITMENT-USD', 'FLEXIBLE-CAD', 'FLEXIBLE-USD'])

  // ** THE FOLLOWING IS A BAD CODE !!!!! WHY USE TOO MANY USEEFFECTS..
  // ** Fetch Data
  useEffect(() => {
    dispatch(fetchPricingbooksData())
    dispatch(fetchCurrencyData())
  }, [])

  // ** Reset Pricings
  useEffect(() => {
    if (id)
      setSelectedProducts(
        oldProducts?.length > 0 ? products.filter(item => oldProducts?.some(el => el.productId === item.id)) : []
      )
    else setSelectedProducts(products)
  }, [products])

  useEffect(() => {
    if (previousPrices?.length > 0) setPrices([...previousPrices])
  }, [previousPrices])

  // ** Functions
  const onChangeSelect = event => {
    const { value, name } = event.target
    if (name === 'type') {
      setTypes(value)
      setColumns(
        combinedArrays(
          value.map(item => item.toUpperCase()),
          currencies
        )
      )
    } else if (name === 'currency') {
      setCurrencies(value)
      setColumns(combinedArrays(types, value))
    }
  }
  const deleteItem = (value, name) => {
    if (name === 'type') {
      const array = types.filter(item => item !== value)
      setTypes(array)
      setColumns(
        combinedArrays(
          array.map(item => item.toUpperCase()),
          currencies
        )
      )
      /// setPrices(state => state?.filter(item => item.type !== value))
    } else if (name === 'currency') {
      const array = currencies.filter(item => item !== value)
      setCurrencies(array)
      setColumns(combinedArrays(types, array))
      const currencyId = currenciesData?.find(item => item.iso_code === value)?.id
      // setPrices(state => {
      //   const new_array = state?.filter(item => item.currencyId !== currencyId)
      //   setValue('prices', new_array)
      //   return new_array
      // })
    }
  }

  const selectProductsHandler = products => {
    setSelectedProducts(products)
    setPrices(state => state?.filter(item => products?.some(el => el.id === item.productId)))
    setPrices(state => {
      const newPrices = state?.filter(item => products?.some(el => el.id === item.productId))
      setValue('prices', newPrices)
      return newPrices
    })
  }

  return (
    <Grid item xs={12} spacing={2} sx={{ position: 'relative', my: 5, minHeight: 400 }}>
      <Grid item xs={12} mb={5}>
        <Typography variant='h5'>Products: </Typography>
      </Grid>
      <Grid container spacing={4} item xs={12}>
        <Grid item xs={12} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='multiple-type-label'>Type</InputLabel>
            <Select
              multiple
              label='Type'
              id='multiple-type'
              name='type'
              size='small'
              onChange={onChangeSelect}
              value={types}
              labelId='multiple-type-label'
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={value}
                      sx={{ mx: 0.75, my: 1, backgroundColor: '#00000014' }}
                      //Stop ropagation to no open the select
                      deleteIcon={<DeleteIcon onMouseDown={event => event.stopPropagation()} />}
                      onDelete={() => deleteItem(value, 'type')}
                    />
                  ))}
                </Box>
              )}
            >
              <MenuItem value='flexible'>FLEXIBLE</MenuItem>
              <MenuItem value='commitment'>COMMITMENT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='multiple-currency-label'>Currency</InputLabel>
            <Select
              multiple
              label='Currency'
              name='currency'
              id='multiple-currency'
              size='small'
              value={currencies}
              onChange={onChangeSelect}
              labelId='multiple-currency-label'
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={value}
                      sx={{ mx: 0.75, my: 1, backgroundColor: '#00000014' }}
                      //Stop propagation to no open the select
                      deleteIcon={<DeleteIcon onMouseDown={event => event.stopPropagation()} />}
                      onDelete={() => deleteItem(value, 'currency')}
                    />
                  ))}
                </Box>
              )}
            >
              {currenciesData.map(item => (
                <MenuItem key={item.id} value={item.iso_code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ProductsAutocompleteForm
            products={products}
            selectProductsHandler={selectProductsHandler}
            selectedProducts={selectedProducts}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={8}>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell>Product name</TableCell>
                <TableCell>SKU ID</TableCell>
                {columns.map((item, index) => (
                  <TableCell key={index} align='left'>
                    {item}
                  </TableCell>
                ))}
                <TableCell align='left'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts?.map((row, index) => (
                <RowPricingForm
                  key={row.id}
                  row={row}
                  setSelectedProducts={setSelectedProducts}
                  columns={columns}
                  currencies={currencies}
                  types={types}
                  currenciesData={currenciesData}
                  setPrices={setPrices}
                  prices={prices}
                  setValue={setValue}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default TableFormProducts
