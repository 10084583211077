// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** API Imports
import {
  getAllProducts,
  createProduct,
  updateProduct as updateProductApi,
  getProduct,
  removeProduct,
  updatePricingBookApi
} from 'configs/axios/api_helper'

export const initialState = {
  products: [],
  product: {},
  loading: false,
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const productsSlice = createSlice({
  name: 'productsData',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    addProduct: (state, action) => {
      state.products.push(action.payload)
      ;(state.success = 'CREATE_PRODUCT'), (state.loading = false)
    },
    editProduct: (state, action) => {
      state.success = 'UPDATE_PRODUCT'
      state.product = { ...state.product, ...action.payload }
      state.loading = null
    },
    getProductsSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.products = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET_PRODUCTS'
    },
    getProductSuccess: (state, action) => {
      state.product = action.payload
      state.loading = false
      state.success = 'GET_PRODUCT'
    },
    cleanProduct: state => {
      state.product = {}
    },
    deleteProductSuccess: (state, action) => {
      state.success = 'DELETE_PRODUCT'
      state.products = state.products.filter(product => product.id !== action.payload)
      state.loading = false
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    },
    setErrorMessage: (state, action) => {
      state.loading = null
      state.error = action.payload
      state.success = ''
    }
  }
})

// Actions generated from the slice
const {
  addProduct,
  editProduct,
  getProductsSuccess,
  getProductSuccess,
  startLoading,
  resetMessages,
  cleanProduct,
  setErrorMessage,
  deleteProductSuccess
} = productsSlice.actions

// export user selector to get the slice in any component
export const productsSelector = state => state.productsData

// export The reducer
const productsReducer = productsSlice.reducer
export default productsReducer

// Actions
export const getProductsData =
  ({ limit, page, field } = {}) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_PRODUCTS'))
      const { data } = await getAllProducts({ limit, page, field })
      dispatch(getProductsSuccess(data))
    } catch (error) {
      dispatch(setErrorMessage(error))
    }
  }

export const newProduct =
  ({ product, navigate, pricing_body }) =>
  async dispatch => {
    try {
      dispatch(startLoading('CREATE_PRODUCT'))
      const data = await createProduct(product)
      dispatch(addProduct(data))
      const { id } = pricing_body
      const prices = pricing_body?.prices?.map(item => ({ ...item, productId: data.id }))
      const response = await updatePricingBookApi(id, { prices })
      setTimeout(() => {
        navigate('/subscriptions')
      }, 1500)
    } catch (error) {
      dispatch(
        setErrorMessage({
          status: error?.response?.status || 500,
          message: error?.response?.data?.message || 'An error has occured'
        })
      )
    }
  }

export const getProductData = id => async dispatch => {
  try {
    dispatch(startLoading('GET_PRODUCTS'))
    const { data } = await getProduct(id)
    dispatch(getProductSuccess(data))
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const updateProduct = (id, product, pricingBook) => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_PRODUCT'))
    const data = await updateProductApi(id, product)
    if (pricingBook) {
      const { id, ...rest } = pricingBook
      const response = await await updatePricingBookApi(id, { ...rest })
    }
    dispatch(editProduct(product))
  } catch (error) {
    dispatch(setErrorMessage())
  }
}

export const deleteProduct = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE_PRODUCT'))
    const data = await removeProduct(id)
    dispatch(deleteProductSuccess(id))
  } catch (error) {
    dispatch(setErrorMessage())
  }
}

export const resetProduct = () => dispatch => {
  dispatch(cleanProduct())
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
