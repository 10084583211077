// ** React Imports
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

// ** Icons Imports
import PencilIcon from 'mdi-material-ui/PencilOutline'
import RoleIcon from 'mdi-material-ui/AccountSupervisorOutline'
import EmailIcon from 'mdi-material-ui/EmailOutline'
import DomainIcon from 'mdi-material-ui/Domain'
import PhoneIcon from 'mdi-material-ui/Phone'
import WrenchIcon from 'mdi-material-ui/WrenchOutline'
import CogIcon from 'mdi-material-ui/CogOutline'
import GoogleIcon from 'mdi-material-ui/Google'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'

// Styled Grid component
const StyledGrid = styled(Grid)(({ theme }) => ({
  // [theme.breakpoints.down('sm')]: {
  //     borderBottom: `1px solid ${theme.palette.divider}`
  // },
  // [theme.breakpoints.up('sm')]: {
  //     borderRight: `1px solid ${theme.palette.grey[300]}`
  // }
}))

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))
const ContactInformations = props => {
  // ** Hooks
  const navigate = useNavigate()
  const { id } = useParams()

  // ** State
  const [contactInfo, setContactInfo] = useState({})

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)

  // ** Fill contact informations from the first created contact in user's contacts
  useEffect(() => {
    if (customerInformation != undefined) {
      if (customerInformation?.contacts?.length > 0)
        setContactInfo(
          customerInformation?.contacts?.reduce((oldest, current) =>
            current.createdAt < oldest.createdAt ? current : oldest
          )
        )
      else setContactInfo({})
    }
  }, [customerInformation])

  // ** Functions
  const openTab = () => {
    props.setValue('2')
  }

  const redirectDailyChanges = () => navigate('/customers/customer-display/daily-changes/' + id)

  return (
    <StyledCard>
      <Grid container sx={{ px: 3 }}>
        <Grid item xs={12} md={6}>
          <CardHeader
            title='Contact information'
            action={
              <Button
                variant='outlined'
                onClick={openTab}
                sx={{
                  mx: 1,
                  borderRadius: '5px !important',
                  border: theme => `1px solid ${theme.palette.grey[300]}`,
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: theme => `${theme.palette.secondary.main} !important`,
                    color: 'common.white'
                  }
                }}
                startIcon={<PencilIcon />}
              >
                Edit
              </Button>
            }
            // sx={{ borderBottom: "solid 1px" }}
          />
          <CardContent>
            <Divider sx={{ borderBottomWidth: 2, color: theme => theme.palette.grey[300], mt: '-0.5rem' }} />
            <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2 }}>
              <RoleIcon />
              <Typography variant='body2'>
                {(contactInfo?.firstname || '----') + ' ' + (contactInfo?.lastname || '----')}
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2 }}>
              <DomainIcon />
              <Typography variant='body2'>{customerInformation?.organization || '----'}</Typography>
            </Stack>
            <Stack direction='row' alignItems='start' gap={4} sx={{ my: 2 }}>
              <GoogleIcon />
              {customerInformation?.googleIds?.length > 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flexWrap: 'wrap' }}>
                  {customerInformation?.googleIds.map((item, index) => (
                    <Typography key={index} variant='body2'>
                      {item?.googleId}
                    </Typography>
                  ))}
                </Box>
              ) : (
                <Typography variant='body2'>----</Typography>
              )}
            </Stack>
            <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2 }}>
              <EmailIcon />
              <Typography variant='body2'>{contactInfo?.email || '----'}</Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2 }}>
              <DomainIcon />
              <Typography variant='body2'>{customerInformation?.domain}</Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2 }}>
              <PhoneIcon />
              <Typography variant='body2'>{contactInfo?.phone || customerInformation?.phone || '----'}</Typography>
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardHeader title='Quick links' />
          <CardContent>
            <Divider sx={{ borderBottomWidth: 2, color: theme => theme.palette.grey[300], mt: '-0.375rem' }} />
            <Typography variant='body2'>Google Cloud Platform</Typography>
            <Typography variant='body2' sx={{ my: 2, color: 'common.blue' }}>
              Freshbooks ID : {customerInformation.freshbooksId}
            </Typography>
            <Typography variant='body2' sx={{ my: 2, color: 'common.blue' }}>
              Freshbooks organization : {customerInformation.organization}
            </Typography>
            <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2, color: 'common.blue' }}>
              <WrenchIcon />
              <Typography variant='body2' sx={{ color: 'common.blue' }}>
                Google cloud console
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2, color: 'common.blue' }}>
              <CogIcon />
              <Typography variant='body2' sx={{ color: 'common.blue' }}>
                Configure billing subaccounts
              </Typography>
            </Stack>
            <Stack
              direction='row'
              alignItems='center'
              gap={4}
              sx={{ my: 2, color: 'common.blue', cursor: 'pointer' }}
              onClick={redirectDailyChanges}
            >
              <GoogleIcon />
              <Typography variant='body2' sx={{ color: 'common.blue' }}>
                Daily changes histroy
              </Typography>
            </Stack>
          </CardContent>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default ContactInformations
