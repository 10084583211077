// apiSlice.js

// ** APIs Call
import { getBoardedCustomersApi, getCustomersBillingApi } from 'configs/axios/api_helper'

// ** Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Create an API slice using RTK Query
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery(), // Set your base URL
  endpoints: builder => ({
    // Existing endpoints...
    getBoardedCustomers: builder.query({
      queryFn: params => getBoardedCustomersApi(params), // Use your custom Axios function
      invalidatesTags: _ => ['CustomersList']
    }),
    getCustomersToBill: builder.query({
      queryFn: params => getCustomersBillingApi(params), // Use your custom Axios function
      invalidatesTags: _ => ['customers_billing_list']
    })
  }),
  tagTypes: ['CustomersList', 'customers_billing_list']
})
// Export the getBoardedCustomersQuery as an action
export const { useGetBoardedCustomersQuery, useGetCustomersToBillQuery } = apiSlice
