// ** React Imports
import React, { forwardRef, Fragment, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

// ** Third Party imports
import moment from 'moment'

// ** Redux Imports
import { useDispatch } from 'react-redux'
import { getPayoutsData, paySalesAgents } from 'store/rules'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const PayCommissionDialog = props => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** Props
  const { agents } = props

  // ** State
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month'))

  // ** Selectors

  // ** Fucntions
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const payHandler = () => {
    setIsLoading(true)
    const body = {
      agents,
      dates: [startDate.startOf('month').format('YYYY-MM-DD')]
    }
    dispatch(
      paySalesAgents(body, () => {
        const currentYear = moment().year()
        const from = moment(`${currentYear}-01-01`).format('YYYY-MM-DD')
        const to = moment(`${currentYear}-12-01`).format('YYYY-MM-DD')
        dispatch(getPayoutsData(from, to))
        setOpen(false)
        setIsLoading(false)
      })
    )
  }

  return (
    <Fragment>
      <Button
        disabled={agents?.length < 1}
        variant='text'
        onClick={handleClickOpen}
        sx={{
          ml: 'auto',
          boxShadow: 0,
          '&:disabled': {
            color: theme => theme.palette.grey[700]
          }
        }}
      >
        Pay Commisions
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              minWidth: '40%' // Set your width here
            }
          }
        }}
      >
        <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Pay Commission Agents</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} my={5}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label='Month'
                inputFormat='MM/DD/YYYY'
                value={startDate}
                onChange={newValue => setStartDate(newValue)}
                sx={{ width: '100%' }}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                renderInput={params => <TextField {...params} />}
                views={['month']}
              />
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button
            sx={{
              color: 'text.secondary',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.white} !important`,
                color: 'text.secondary'
              }
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            sx={{
              backgroundColor: 'common.blue',
              color: 'common.white',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.blue} !important`,
                color: 'common.white'
              },
              '&:disabled': {
                backgroundColor: theme => theme.palette.grey[700],
                borderColor: theme => theme.palette.grey[700]
              }
            }}
            onClick={payHandler}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color='inherit' size={15} /> : null}
          >
            Pay
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default PayCommissionDialog
