// ** React Imports
import React from 'react'

// ** MUI Imports
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'

// ** Icon Imports
import CloseIcon from 'mdi-material-ui/Close'
import HorizontalLinearStepper from './stepper'

const PurchaseScreen = props => {
  // ** Props
  const { open, setOpen, selectedProduct } = props

  // ** Functions
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Dialog fullScreen onClose={handleClose} aria-labelledby='full-screen-dialog-title' open={open}>
        <DialogTitle id='full-screen-dialog-title' sx={{ backgroundColor: '#3367d6', height: 72 }}>
          <IconButton aria-label='close' onClick={handleClose}>
            <CloseIcon sx={{ color: 'white', mt: -1, mr: 2 }} />
          </IconButton>
          <Typography variant='h6' component='span' color='white'>
            Add new subscription
          </Typography>
        </DialogTitle>
        <Box sx={{ flex: '1 1 auto' }}>
          <HorizontalLinearStepper selectedProduct={selectedProduct} />
        </Box>
      </Dialog>
    </div>
  )
}

export default PurchaseScreen
