// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

// ** Third Party Imports
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import CustomAutocomplete from '../../CustomAutocomplete'

const defaultValues = {
    email: '',
    lastName: '',
    password: '',
    firstName: ''
}

const showErrors = (field, valueLen, min) => {
    if (valueLen === 0) {
        return `${field} field is required`
    } else if (valueLen > 0 && valueLen < min) {
        return `${field} must be at least ${min} characters`
    } else {
        return ''
    }
}

const schema = yup.object().shape({
    email: yup.string().email().required(),
    lastName: yup
        .string()
        .min(3, obj => showErrors('lastName', obj.value.length, obj.min))
        .required(),
    password: yup
        .string()
        .min(8, obj => showErrors('password', obj.value.length, obj.min))
        .required(),
    firstName: yup
        .string()
        .min(3, obj => showErrors('firstName', obj.value.length, obj.min))
        .required()
})

const BillingForm = () => {


    // ** States
    const [state, setState] = useState({
        password: '',
        showPassword: false
    })

    // ** Hook
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(schema)
    })

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }

    const handleMouseDownPassword = event => {
        event.preventDefault()
    }
    const onSubmit = () => toast.success('Form Submitted')

    return (


        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel
                            id='validation-basic-select'
                            error={Boolean(errors.select)}
                            htmlFor='validation-basic-select'
                        >
                            Payment Method
                        </InputLabel>
                        <Controller
                            name='select'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    value={value}
                                    label='Payment Method'
                                    onChange={onChange}
                                    error={Boolean(errors.select)}
                                    labelId='validation-basic-select'
                                    aria-describedby='validation-basic-select'
                                >
                                    <MenuItem value='UK'>UK</MenuItem>
                                    <MenuItem value='USA'>USA</MenuItem>
                                    <MenuItem value='Australia'>Australia</MenuItem>
                                    <MenuItem value='Germany'>Germany</MenuItem>
                                </Select>
                            )}
                        />
                        {errors.firstName && (
                            <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-first-name'>
                                {errors.firstName.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel
                            id='validation-basic-select'
                            error={Boolean(errors.select)}
                            htmlFor='validation-basic-select'
                        >
                            Currency
                        </InputLabel>
                        <Controller
                            name='select'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    value={value}
                                    label='Currency'
                                    onChange={onChange}
                                    error={Boolean(errors.select)}
                                    labelId='validation-basic-select'
                                    aria-describedby='validation-basic-select'
                                >
                                    <MenuItem value='UK'>UK</MenuItem>
                                    <MenuItem value='USA'>USA</MenuItem>
                                    <MenuItem value='Australia'>Australia</MenuItem>
                                    <MenuItem value='Germany'>Germany</MenuItem>
                                </Select>
                            )}
                        />
                        {errors.lastName && (
                            <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-last-name'>
                                {errors.lastName.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel
                            id='validation-basic-select'
                            error={Boolean(errors.select)}
                            htmlFor='validation-basic-select'
                        >
                            Tax
                        </InputLabel>
                        <Controller
                            name='select'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    value={value}
                                    label='Tax'
                                    onChange={onChange}
                                    error={Boolean(errors.select)}
                                    labelId='validation-basic-select'
                                    aria-describedby='validation-basic-select'
                                >
                                    <MenuItem value='UK'>UK</MenuItem>
                                    <MenuItem value='USA'>USA</MenuItem>
                                    <MenuItem value='Australia'>Australia</MenuItem>
                                    <MenuItem value='Germany'>Germany</MenuItem>
                                </Select>
                            )}
                        />
                        {errors.email && (
                            <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-email'>
                                {errors.email.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel
                            id='validation-basic-select'
                            error={Boolean(errors.select)}
                            htmlFor='validation-basic-select'
                        >
                            Billing Type
                        </InputLabel>
                        <Controller
                            name='select'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    value={value}
                                    label='Billing Type'
                                    onChange={onChange}
                                    error={Boolean(errors.select)}
                                    labelId='validation-basic-select'
                                    aria-describedby='validation-basic-select'
                                >
                                    <MenuItem value='UK'>UK</MenuItem>
                                    <MenuItem value='USA'>USA</MenuItem>
                                    <MenuItem value='Australia'>Australia</MenuItem>
                                    <MenuItem value='Germany'>Germany</MenuItem>
                                </Select>
                            )}
                        />
                        {errors.email && (
                            <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-email'>
                                {errors.email.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <Button type='submit' variant='contained'>
                        Add
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default BillingForm
