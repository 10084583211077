// ** React Imports
import React, { forwardRef, Fragment, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

// ** MUI Lab
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'

// ** Icons Imports
import ArrowDownIcon from 'mdi-material-ui/MenuDown'


// ** Third Party Imports
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'


const defaultValues = {
    email: '',
    lastName: '',
    password: '',
    firstName: ''
}

const showErrors = (field, valueLen, min) => {
    if (valueLen === 0) {
        return `${field} field is required`
    } else if (valueLen > 0 && valueLen < min) {
        return `${field} must be at least ${min} characters`
    } else {
        return ''
    }
}

const schema = yup.object().shape({
    email: yup.string().email().required(),
    lastName: yup
        .string()
        .min(3, obj => showErrors('lastName', obj.value.length, obj.min))
        .required(),
    password: yup
        .string()
        .min(8, obj => showErrors('password', obj.value.length, obj.min))
        .required(),
    firstName: yup
        .string()
        .min(3, obj => showErrors('firstName', obj.value.length, obj.min))
        .required()
})


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const SettingsAddModal = () => {
    // ** State
    const [open, setOpen] = useState(false)
    const handleClickOpen = () => setOpen(true)
    const [value, setValue] = useState('1')


    // ** Fucntions
    const handleClose = () => setOpen(false)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }


    // ** States
    const [state, setState] = useState({
        password: '',
        showPassword: false
    })

    // ** Hook
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(schema)
    })

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }

    const handleMouseDownPassword = event => {
        event.preventDefault()
    }
    const onSubmit = () => toast.success('Form Submitted')


    return (
        <Fragment>
            <Button variant='outlined' onClick={handleClickOpen}
                sx={{
                    borderRadius: "5px !important",
                    textTransform: "capitalize !important",
                    border: "none",
                    fontWeight: 600,
                    // color: "common.black",
                    '&:hover': {
                        border: "none",
                    },
                }}
            >
                Create Tax
            </Button>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                TransitionComponent={Transition}
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
               
            >
                <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: "center" }}>
                    <Typography sx={{ fontWeight: 600, fontSize: "1.5rem" }}>
                        Create new Tax
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={5} sx={{mt: "1rem"}}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='firstName'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                value={value}
                                                label='Name'
                                                onChange={onChange}
                                                placeholder='Leonard'
                                                error={Boolean(errors.firstName)}
                                                aria-describedby='validation-schema-first-name'
                                            />
                                        )}
                                    />
                                    {errors.firstName && (
                                        <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-first-name'>
                                            {errors.firstName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        name='lastName'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                value={value}
                                                label='Value'
                                                onChange={onChange}
                                                placeholder='Carter'
                                                error={Boolean(errors.lastName)}
                                                aria-describedby='validation-schema-last-name'
                                            />
                                        )}
                                    />
                                    {errors.lastName && (
                                        <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-last-name'>
                                            {errors.lastName.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>


                            <Grid item xs={12}>
                                <DialogActions className='dialog-actions-dense'>
                                    <Button type='submit' variant='contained'>
                                        Add
                                    </Button>
                                    <Button sx={{ color: "secondary.light" }} onClick={handleClose}>Cancel</Button>
                                </DialogActions>

                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                {/* <DialogActions className='dialog-actions-dense'>
                    <Button sx={{ color: "secondary.light" }} onClick={handleClose}>Cancel</Button>
                    {/* <Button onClick={handleClose}>Create</Button> 
                </DialogActions> */}
            </Dialog>
        </Fragment>
    )
}

export default SettingsAddModal
