// ** React Imports
import React, { useState, forwardRef, useRef, Fragment } from 'react'
import { useParams } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'

// ** Third Party Imports
import format from 'date-fns/format'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import DatePickerWrapper from 'core/styles/libs/react-datepicker'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerInvoice } from 'store/customers'


const PickersRange = (props) => {

    // eslint-disable-next-line react/display-name
    const CustomInput = forwardRef((props, ref) => {
        const startDate = (props.start !== undefined && props.start) ? `${format(props.start, 'MM/dd/yyyy')}` : null
        const endDate = (props.end !== undefined && props.end) ? `${format(props.end, 'MM/dd/yyyy')}` : null
        const value = `${startDate ? startDate : endDate}`
        return <TextField inputRef={ref} {...props} value={value ? value : " "} size="small" variant="standard" sx={{ fontSize: "0.875rem" }} />
    })



    // ** Hooks 
    const dispatch = useDispatch()
    const { id } = useParams()

    // ** Props
    const { dateHandler, setOpenDatePicker } = props
    // ** Refs 
    const startPicker = useRef()
    const endPicker = useRef()

    // ** States
    const [endDate, setEndDate] = useState(new Date)
    const [startDate, setStartDate] = useState(new Date())


    const submitFilterHandler = () => {
        dispatch(getCustomerInvoice({ id: id, filters: { startDate: moment(startDate).format("yyyy-MM-DD"), endDate: moment(endDate).format("yyyy-MM-DD") } }))
        setOpenDatePicker(false)
    }

    return (
        <Fragment>
            <DatePickerWrapper>
                <Typography sx={{ mb: 5 }}>
                    Custom
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                    <DatePicker
                        ref={startPicker}
                        selectsStart
                        maxDate={new Date(endDate).setDate(endDate?.getDate() - 1)}
                        endDate={endDate}
                        startDate={startDate}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        shouldCloseOnSelect={true}
                        customInput={<CustomInput start={startDate} />}
                    />
                    <Typography sx={{ fontSize: "1.25rem", mx: 2 }}>
                        -
                    </Typography>
                    <DatePicker
                        ref={endPicker}
                        //monthsShown={4}
                        selectsEnd
                        minDate={new Date(startDate).setDate(startDate?.getDate() + 1)}
                        endDate={endDate}
                        startDate={startDate}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        shouldCloseOnSelect={true}
                        // renderCustomHeader={() =>
                        //     <Box sx={{ height: 60 }}>
                        //         <TextField placeholder="mm/dd/yyyy" variant="standard" />
                        //         <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}`, pt: "0.5rem" }} />
                        //     </Box>
                        // }
                        customInput={<CustomInput end={endDate} />}
                    />
                </Box>
            </DatePickerWrapper>
            <Box sx={{ textAlign: "end", marginTop: "1rem" }}>
                <Button
                    sx={{
                        color: "common.blue",
                        fontWeight: 600
                    }}
                    onClick={() => setOpenDatePicker(false)}>
                    Cancel
                </Button>
                <Button
                    sx={{
                        color: "common.blue",
                        fontWeight: 600
                    }}
                    onClick={submitFilterHandler}>
                    Apply
                </Button>
            </Box>
        </Fragment>
    )
}

export default PickersRange
