// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

/// ** Custom Imports
import AlertMessages from 'pages/Billing/components/AlertMessages'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icons Imports
import DeleteIcon from 'mdi-material-ui/CloseCircle'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaxData, taxSelector } from 'store/metaData/tax'
import { fetchPaymentMethodsData, paymentMethodSelector } from 'store/metaData/paymentMethod'
import { currencySelector, fetchCurrencyData } from 'store/metaData/currency'
import { customersSelector, editCustomerBilling } from 'store/customers'
import { dueDateSelector, fetchDueDateData } from 'store/metaData/dueDate'
import { fetchPricingbooksData, pricingBooksSelector } from 'store/metaData/pricingbook'
import moment from 'moment'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
}

const defaultValues = {
  taxes: [],
  billingCurrencyId: '',
  paymentMethodId: '',
  dueDateId: '',
  billingDay: '',
  billingCycle: null,
  billingType: 'rear',
  deletedTaxes: [],
  pricingBooks: [],
  startBillingDate: null
}

const Container = styled(Grid)(({ theme }) => ({
  marginTop: 5,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

const BillingInformation = () => {
  // ** Redux dispatcher
  const dispatch = useDispatch()

  // ** Selectors
  const { taxData } = useSelector(taxSelector)
  const { paymentMethodsData } = useSelector(paymentMethodSelector)
  const { currenciesData } = useSelector(currencySelector)
  const { pricingBooksData } = useSelector(pricingBooksSelector)
  const { dueDateData } = useSelector(dueDateSelector)
  const { customerInformation, success, error, loading } = useSelector(customersSelector)

  // ** States
  const [selectedTax, setSelectedTax] = useState([])

  // ** Hooks

  // ** Fetch Tax data
  useEffect(() => {
    dispatch(fetchPricingbooksData({ status: 'active' }))
    dispatch(fetchTaxData())
    dispatch(fetchPaymentMethodsData())
    dispatch(fetchCurrencyData())
    dispatch(fetchDueDateData())
  }, [])

  // ** Initialize form values
  useEffect(() => {
    if (customerInformation != undefined) {
      const pricings = pricingBooksData
        ?.filter(item => customerInformation.subscriptionPricingBooks?.some(el => el.pricingBookId === item.id))
        ?.map(item => item.id)
      const defaultPricing = pricingBooksData?.filter(item => item?.is_default)?.map(item => item?.id)
      reset({
        show_gcp_usage: customerInformation?.show_gcp_usage || false,
        taxes: [],
        deletedTaxes: [],
        billingCurrencyId: customerInformation.billingCurrencyId,
        paymentMethodId: customerInformation.paymentMethodId,
        dueDateId: customerInformation.dueDateId,
        billingDay: customerInformation.billingDay,
        billingCycle: customerInformation.billingCycle,
        billingType: customerInformation.billingType,
        pricingBooks: pricings?.length > 0 ? pricings : defaultPricing,
        startBillingDate: customerInformation.startBillingDate
          ? new Date(moment(customerInformation.startBillingDate).format('YYYY/MM/DD'))
          : null
      })
      setSelectedTax(customerInformation?.taxes?.map(el => el?.tax?.name) || [])
    }
  }, [customerInformation, pricingBooksData])

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm({ defaultValues })

  const onSubmit = (data, e) => {
    const date = moment(data.startBillingDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('YYYY-MM-DD')
    dispatch(
      editCustomerBilling({
        id: customerInformation.id,
        ...data,
        ...(data.startBillingDate ? { startBillingDate: date } : {})
      })
    )
  }

  // select tax handler
  const handleChange = event => {
    let newarray = event.target.value.map(el => taxData.find(tax => tax.name == el)?.id)
    let oldarray = customerInformation.taxes?.map(el => el?.tax?.id)
    // Following arrays for filtering new added IDS, non changed IDS and removed IDS
    let deletedIds = oldarray.filter(id => !newarray.includes(id))
    let presentIds = oldarray.filter(id => newarray.includes(id))
    let newIds = newarray.filter(id => !presentIds.includes(id))
    //Returing deleted ID of the relation between the customer and taxes to be deleted
    let deletedRelationIds = deletedIds?.map(el => customerInformation.taxes?.find(tax => tax?.tax?.id == el)?.id)

    setValue(
      'taxes',
      newIds.map(el => ({ taxId: el }))
    )
    setValue('deletedTaxes', deletedRelationIds)
    setSelectedTax(event.target.value)
  }

  const deleteHandler = item => {
    let found = customerInformation?.taxes?.find(el => el?.tax?.name === item)
    if (found !== undefined) setValue('deletedTaxes', [...getValues().deletedTaxes, found?.id])
    setSelectedTax(selectedTax.filter(el => el !== item))
  }

  const renderChipLabel = value => {
    const name = pricingBooksData?.find(item => item.id === value)?.name
    const defaultPricing = pricingBooksData?.find(item => item.id === value)?.is_default
    if (!name) return null
    return name + `${!!defaultPricing ? ' (default)' : ''}`
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container container spacing={5} sx={{ px: '30%' }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-multiple-chip-label'>Tax</InputLabel>
              <Select
                multiple
                label='Tax'
                value={selectedTax}
                MenuProps={MenuProps}
                id='demo-multiple-chip'
                onChange={handleChange}
                labelId='demo-multiple-chip-label'
                renderValue={selected => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{
                          m: 0.75,
                          backgroundColor: theme => theme.palette.grey[300]
                        }}
                        //Stop ropagation to no open the select
                        deleteIcon={<DeleteIcon onMouseDown={event => event.stopPropagation()} />}
                        onDelete={() => deleteHandler(value)}
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value={null} disabled>
                  <em>None</em>
                </MenuItem>
                {taxData.map(tax => (
                  <MenuItem key={tax.id} value={tax.name}>
                    {tax.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel
                id='currency-select'
                error={Boolean(errors.currency)}
                htmlFor='currency-select'
                sx={{ color: theme => theme.palette.grey[500] }}
              >
                Currency
              </InputLabel>
              <Controller
                name='billingCurrencyId'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value}
                    label='Currency'
                    onChange={onChange}
                    error={Boolean(errors.billingCurrencyId)}
                    labelId='currency-select'
                    aria-describedby='currency-select'
                  >
                    <MenuItem value={null} disabled>
                      <em>None</em>
                    </MenuItem>
                    {currenciesData.map(currency => (
                      <MenuItem key={currency.id} value={currency.id}>
                        {currency.iso_code}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.billingCurrencyId && (
                <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-billingCurrencyId'>
                  {errors.billingCurrencyId.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel
                id='payment-method-select'
                error={Boolean(errors.paymentMethod)}
                htmlFor='payment-method-select'
                sx={{ color: theme => theme.palette.grey[500] }}
              >
                Payment Method
              </InputLabel>
              <Controller
                name='paymentMethodId'
                control={control}
                //rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value ?? ''}
                    label='Payment Method'
                    onChange={onChange}
                    error={Boolean(errors.paymentMethodId)}
                    labelId='payment-method-select'
                    aria-describedby='payment-method-select'
                  >
                    <MenuItem value={null} disabled>
                      <em>None</em>
                    </MenuItem>
                    {paymentMethodsData.map(paymentMethod => (
                      <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
                        {paymentMethod.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.paymentMethodId && (
                <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-paymentMethodId'>
                  {errors.paymentMethodId.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel
                id='paymentType-select'
                error={Boolean(errors.paymentType)}
                htmlFor='paymentType-select'
                sx={{ color: theme => theme.palette.grey[500] }}
              >
                Billing Type
              </InputLabel>
              <Controller
                name='billingType'
                control={control}
                //rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value ?? ''}
                    label='Billing Type'
                    onChange={onChange}
                    error={Boolean(errors.billingType)}
                    labelId='billingType-select'
                    aria-describedby='billingType-select'
                  >
                    <MenuItem value={null} disabled>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value='rear'>Rear</MenuItem>
                    <MenuItem value='inadvance'>in Advance</MenuItem>
                  </Select>
                )}
              />
              {errors.billingType && (
                <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-billingType'>
                  {errors.billingType.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel
                id='payment-method-select'
                error={Boolean(errors.dueDate)}
                htmlFor='payment-method-select'
                sx={{ color: theme => theme.palette.grey[500] }}
              >
                Payment Term
              </InputLabel>
              <Controller
                name='dueDateId'
                control={control}
                //rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value ?? ''}
                    label='Payment Term'
                    onChange={onChange}
                    error={Boolean(errors.dueDateId)}
                    labelId='payment-method-select'
                    aria-describedby='payment-method-select'
                  >
                    <MenuItem value={null} disabled>
                      <em>None</em>
                    </MenuItem>
                    {dueDateData.map(dueDate => (
                      <MenuItem key={dueDate.id} value={dueDate.id}>
                        {dueDate.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.dueDateId && (
                <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-dueDateId'>
                  {errors.dueDateId.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel
                id='validation-schema-pricingBookId'
                htmlFor='validation-schema-pricingBookId'
                sx={{ color: theme => theme.palette.grey[500] }}
              >
                Pricing Book
              </InputLabel>
              <Controller
                name='pricingBooks'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value ?? []}
                    disabled
                    multiple
                    label='Pricing Book'
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={renderChipLabel(value)}
                            sx={{
                              m: 0.75,
                              backgroundColor: theme => theme.palette.grey[300]
                            }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    <MenuItem value={null} disabled>
                      <em>None</em>
                    </MenuItem>
                    {pricingBooksData?.map(pricing => (
                      <MenuItem key={pricing.id} value={pricing.id}>
                        {pricing.name}
                        {/* {pricing.is_default ? ' (default)' : ''} */}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl fullWidth>
                <Controller
                  name='startBillingDate'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DesktopDatePicker
                      label='Start billing date'
                      value={value ?? null}
                      inputFormat='MM/DD/YYYY'
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                      renderInput={params => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={12}>
              <Typography variant='h6'>Billing Cycle</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  id='validation-schema-billingCycle'
                  error={Boolean(errors.billingCycle)}
                  htmlFor='validation-schema-billingCycle'
                  sx={{ color: theme => theme.palette.grey[500] }}
                >
                  Billing cycle
                </InputLabel>
                <Controller
                  name='billingCycle'
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value ?? ''}
                      label='Billing Cycle'
                      onChange={onChange}
                      error={Boolean(errors.billingCycle)}
                      labelId='validation-schema-billingCycle'
                      aria-describedby='validation-schema-billingCycle'
                    >
                      <MenuItem value={null} disabled>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value='monthly'>Monthly</MenuItem>
                      <MenuItem value='quarterly'>Quarterly</MenuItem>
                      <MenuItem value='semi yearly'>Semi-yearly</MenuItem>
                      <MenuItem value='yearly'>Yearly</MenuItem>
                    </Select>
                  )}
                />
                {errors.billingCycle && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-billingCycle'>
                    {errors.billingCycle.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name='billingDay'
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      type='number'
                      InputProps={{ inputProps: { min: 1, max: 31 } }}
                      value={value}
                      label='Billing Day'
                      onChange={onChange}
                      placeholder='Carter'
                      error={Boolean(errors.billingDay)}
                      aria-describedby='validation-basic-billingDay'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.billingDay && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                    This field is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={12}>
              <Typography variant='h6'>GCP Usage</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name='show_gcp_usage'
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      label='Show GCP usage'
                      control={<Checkbox {...field} checked={!!field.value} name='gcp-usage-checkbox' />}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'end' }}>
            <Button
              startIcon={
                loading == 'UPDATE_CUSTOMER' && <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
              }
              size='large'
              type='submit'
              variant='contained'
            >
              Update
            </Button>
          </Grid>
        </Container>
      </form>
      <AlertMessages
        success={success}
        error={error === 'BILLING_INFO_ERROR'}
        messageValue={success === 'UPDATE' ? 'UPDATE' : ''}
        message={success === 'UPDATE' && 'Action done successfully !'}
      />
    </Fragment>
  )
}

export default BillingInformation
