// ** React Imports
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'

// ** Custom Components
import FilterPopper from './FilterPopper'

// ** Icons Imports
import PlusIcon from 'mdi-material-ui/Plus'

// ** Redux Imports
import {
  customersSelector,
  fetchBoardedCustomers,
  persistSearchFilters,
  clearPersistedSearchFilters
} from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'
import { useGetBoardedCustomersQuery } from 'store/customers/apiCaching'

// Styled Card component
const Item = styled(Typography, { shouldForwardProp: prop => prop !== 'selected' })(({ theme, selected }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  fontSize: '0.925rem',
  cursor: 'pointer',
  ...(selected ? { backgroundColor: '#eee' } : {}),
  '&:hover': {
    backgroundColor: '#eee'
  }
}))

export default function Filters() {
  // ** Hooks
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  // ** Selectors
  const { billingSearch } = useSelector(customersSelector)

  // ** Refs
  const wrapperRef = useRef(null)

  // ** State
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState([])
  const [popper, setPopper] = useState(false)
  const [filterValue, setFilterValue] = useState({
    billingDay: '',
    organization: '',
    freshbooksId: ''
  })

  // ** Hook which detect clicking outside the popper box
  useEffect(() => {
    // ** Alert if clicked on outside of element
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const selectFilterHandler = value => {
    let found = filters.includes(value)
    if (!found) {
      setFilters([...filters, value])
      setOpen(false)
      setPopper(true)
    }
  }

  useEffect(() => {
    setFilterValue(billingSearch)
    if (billingSearch.organization && !filters.includes('organization'))
      setFilters(state => [...state.filter(item => item !== 'organization'), 'organization'])
    if (billingSearch.billingDay && !filters.includes('billingDay'))
      setFilters(state => [...state.filter(item => item !== 'billingDay'), 'billingDay'])

    if (billingSearch.freshbooksId && !filters.includes('freshbooksId'))
      setFilters(state => [...state.filter(item => item !== 'freshbooksId'), 'freshbooksId'])
  }, [pathname])

  const handleClick = () => {
    setOpen(true)
  }

  const closePopperHandler = () => {
    setPopper(false)
    //setFilterValue({...filterValue, filterValue[filters[filters.length - 1]]})
    setFilters(filters.filter(el => el !== filters[filters.length - 1]))
  }

  const submitFilter = () => {
    setPopper(false)
    // dispatch(fetchBoardedCustomers(filterValue))
    dispatch(persistSearchFilters(filterValue))
  }

  const deleteFilter = filter => {
    setFilters(filters.filter(el => el !== filter))
    setFilterValue({ ...filterValue, [filter]: '' })
    dispatch(clearPersistedSearchFilters({ ...filterValue, [filter]: '' }))
    // dispatch(fetchBoardedCustomers({ ...filterValue, [filter]: '' }))
  }

  const deleteAllFilters = () => {
    setFilters([])
    setFilterValue({ billingDay: '', organization: '', freshbooksId: '' })
    dispatch(clearPersistedSearchFilters({ billingDay: '', organization: '', freshbooksId: '' }))
    // dispatch(fetchBoardedCustomers())
  }

  return (
    <Box sx={{ position: 'relative', display: 'flex', width: '100%' }}>
      {filters.map(
        (filter, index) =>
          filterValue[filter] && (
            <Chip
              key={filter}
              onDelete={() => deleteFilter(filter)}
              label={
                filter === 'billingDay'
                  ? 'Billing Day: ' + filterValue.billingDay
                  : filter === 'organization'
                  ? 'Organization: ' + filterValue.organization
                  : 'Frechbooks ID: ' + filterValue.freshbooksId
              }
              variant='outlined'
              sx={{
                cursor: 'pointer',
                backgroundColor: '#e0e0e0',
                color: 'text.primary',
                fontSize: '0.875rem',
                mr: 1
              }}
            />
          )
      )}
      {!open && (
        <Chip
          onClick={handleClick}
          icon={<PlusIcon />}
          label='Add a filter'
          variant='outlined'
          sx={{
            cursor: 'pointer',
            color: 'secondary.light',
            fontSize: '0.875rem',
            '&': {
              border: '1px dashed #BDBDBD'
            }
          }}
        />
      )}
      {open && filters.length < 4 && (
        <Box
          ref={wrapperRef}
          sx={{
            zIndex: 99999,
            mt: '1.725rem',
            backgroundColor: 'white',
            boxShadow: theme => theme.shadows[2],
            border: theme => `1px solid ${theme.palette.grey[400]}`,
            position: 'absolute',
            width: 300,
            height: 'fit-content'
          }}
        >
          {!filters.includes('billingDay') && (
            <Item onClick={() => selectFilterHandler('billingDay')}>Billing day</Item>
          )}
          {!filters.includes('organization') && (
            <Item onClick={() => selectFilterHandler('organization')}>Organization</Item>
          )}
          {!filters.includes('freshbooksId') && (
            <Item onClick={() => selectFilterHandler('freshbooksId')}>Freshbooks ID</Item>
          )}
        </Box>
      )}
      {filters.length > 0 && (
        <Typography
          onClick={deleteAllFilters}
          sx={{
            color: 'text.secondary',
            ml: 'auto',
            fontSize: '0.875rem',
            fontWeight: 600,
            cursor: 'pointer',
            mt: '0.5rem'
          }}
        >
          DELETE FILTERS
        </Typography>
      )}
      {popper && (
        <FilterPopper
          popper={popper}
          filterValue={filterValue}
          selectedFilter={filters[filters.length - 1]}
          setFilterValue={setFilterValue}
          closePopperHandler={closePopperHandler}
          submitFilter={submitFilter}
        />
      )}
    </Box>
  )
}
