// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import InputAdornment from '@mui/material/InputAdornment'
import { DataGrid } from '@mui/x-data-grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

// ** Redux Imports
import { customersSelector, getCustomersUsages, getFreshbooksPrices } from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

// ** Icon Imports
import SearchIcon from 'mdi-material-ui/Magnify'
import SkusDialog from '../components/SkusDialog'

const BillingFreshbooksComparator = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** State
  const [startDate, setStartDate] = useState(moment())
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [billingType, setBillingType] = useState('')

  // ** Selectors
  const { customersFreshbooksUsagesCompare, pagination, loading, error } = useSelector(customersSelector)

  // ** Get customer invoices
  useEffect(() => {
    dispatch(
      getFreshbooksPrices({
        from: startDate.startOf('month').format('YYYY-MM-DD'),
        to: startDate.endOf('month').format('YYYY-MM-DD'),
        limit,
        page,
        search,
        billingType
      })
    )
  }, [startDate, page, limit, search, billingType])

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'customerName',
      headerName: 'Customer',
      renderCell: params => (
        <Typography noWrap variant='body2' sx={{ color: 'common.blue', cursor: 'pointer' }}>
          {params.row.customerName}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 150,
      field: 'generationDate',
      headerName: 'Billing Day',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {moment(params.row.generationDate).format('YYYY-MM-DD')}
        </Typography>
      )
    },
    {
      flex: 0.25,
      minWidth: 200,
      field: 'amount',
      headerName: 'Billing App. invoice',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          ${params.row.amount}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 200,
      field: 'googleUsageData',
      headerName: 'Freshbooks invoice',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          ${params.row.freshbooksAmount}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'difference',
      headerName: 'Difference',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {((params.row.freshbooksAmount || 0) - (params.row.amount || 0)).toFixed(2)}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'billingType',
      headerName: 'Billing Type',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.billingType || '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'period',
      headerName: 'Billing period',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {moment(params.row.startDate).format('YYYY-MM-DD') + ' ~ ' + moment(params.row.endDate).format('YYYY-MM-DD')}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'freshbookperiod',
      headerName: 'Freshbook Billing period',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {moment(params.row.freshbooksFromDate).format('YYYY-MM-DD') +
            ' ~ ' +
            moment(params.row.freshbooksToDate).format('YYYY-MM-DD')}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 350,
      field: 'billedSkusOnApp',
      headerName: 'Skus',
      renderCell: params => (
        <SkusDialog
          active={1}
          freshBooksSkus={params.row.billedSkusOnFreshbooks}
          customerSkus={params.row.billedSkusOnApp}
        />
      )
    },
    {
      flex: 0.125,
      minWidth: 350,
      field: 'billedSkusOnFreshbooks',
      headerName: 'Freshbooks Skus',
      renderCell: params => (
        <SkusDialog
          active={2}
          freshBooksSkus={params.row.billedSkusOnFreshbooks}
          customerSkus={params.row.billedSkusOnApp}
        />
      )
    },

    {
      flex: 0.1,
      minWidth: 150,
      field: 'currency',
      headerName: 'Currency',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.currency}
        </Typography>
      )
    }
  ]

  // ** Functions
  const onChangeBillingType = event => {
    setBillingType(event.target.value)
  }

  const handleChangePage = newPage => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPage(++newPage)
  }

  const handleChangeRowsPerPage = rows => {
    setPage(1)
    setLimit(rows)
  }

  const debounced = useDebouncedCallback(
    event => {
      const { value } = event.target
      setSearch(value)
    },
    400,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Billing Reconciliation</Typography>
            <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>| Compare customers transactions</Typography>
          </Box>
        }
      />
      <Box p={5} display='flex' alignItems='center' gap={5}>
        <TextField
          placeholder='Search for customer'
          size='small'
          onChange={debounced}
          sx={{ width: 350 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ color: 'common.blue' }} />
              </InputAdornment>
            )
          }}
        />
        <Box width={350}>
          <FormControl fullWidth size='small'>
            <InputLabel id='pricing-book-label'>Billing Type</InputLabel>
            <Select label='Billing Type' onChange={onChangeBillingType} labelId='validation-basic-select'>
              <MenuItem value={null}>
                <em>None</em>
              </MenuItem>
              <MenuItem value='rear'>Rear</MenuItem>
              <MenuItem value='inadvance'>In advance</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box width={350}>
            <DesktopDatePicker
              label='Start date'
              inputFormat='MM/DD/YYYY'
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
              sx={{ width: '100%' }}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={params => <TextField {...params} />}
              views={['month']}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={loading ? [] : customersFreshbooksUsagesCompare || []}
        getRowId={row => row.id}
        onRowClick={({ row }) => window.open('/billing/' + row.customerId)}
        columns={columns}
        loading={loading}
        pagination
        pageSize={limit || 0}
        rowCount={pagination?.total || 0}
        page={pagination?.currentPage ? pagination?.currentPage - 1 : 0}
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default BillingFreshbooksComparator
