// ** React Imports
import React from 'react'

// ** MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'

// ** Icons Imports
import CloseIcon from 'mdi-material-ui/Close'

const FilterPopper = props => {
  // ** Props
  const { popper, filterValue, selectedFilter, closePopperHandler, setFilterValue, submitFilter } = props

  // ** Functions
  const onChangeFilter = event => {
    const { name, value } = event.target
    setFilterValue(prevState => {
      return { ...prevState, [name]: value }
    })
  }

  return (
    <Fade in={popper}>
      <Card
        sx={{
          position: 'absolute',
          zIndex: 9999,
          borderRadius: 0,
          width: 244,
          mt: '-0.725rem'
        }}
      >
        <CardHeader
          title={
            <Typography sx={{ color: 'common.white', fontSize: '0.875rem' }}>
              {selectedFilter === 'billingDay' && 'Billing day'}
              {selectedFilter === 'organization' && 'Organization'}
              {selectedFilter === 'freshbooksId' && 'Freshbooks ID'}
            </Typography>
          }
          sx={{
            backgroundColor: '#3367d6',
            color: 'common.white',
            height: 56
          }}
          action={<CloseIcon sx={{ mt: '-1rem', cursor: 'pointer' }} onClick={closePopperHandler} />}
        />
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12} sx={{ px: '5%' }}>
            <TextField
              type={selectedFilter === 'billingDay' ? 'number' : 'text'}
              name={selectedFilter ?? ''}
              onChange={onChangeFilter}
              label={selectedFilter === 'billingDay' ? 'Customer billing day' : 'Starts with'}
              variant='standard'
              InputProps={{ ...(selectedFilter === 'billingDay' && { inputProps: { min: 1, max: 31 } }) }}
              InputLabelProps={{ style: { fontSize: '0.925rem' } }} // font size of input label
              sx={{ width: '100%' }}
              autoFocus
              onKeyDownCapture={ev => {
                if (ev.key === 'Enter') {
                  submitFilter()
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'end', mt: 5 }}>
            <Button
              disabled={
                (selectedFilter === 'billingDay' &&
                  !filterValue.billingDay &&
                  filterValue.billingDay > 31 &&
                  filterValue.billingDay < 1) ||
                (selectedFilter === 'organization' && !filterValue.organization) ||
                (selectedFilter === 'freshbooksId' && !filterValue.freshbooksId)
              }
              onClick={submitFilter}
              sx={{
                backgroundColor: 'common.white',
                borderRadius: 0,
                fontWeight: 600,
                '&:disabled': { color: 'text.secondary' }
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Fade>
  )
}

export default FilterPopper
