// ** React Imports
import React from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'

// ** Third Party Libraries
import moment from 'moment'

// ** Icon Imports
import CheckIcon from 'mdi-material-ui/CheckCircleOutline'
import ErrorIcon from 'mdi-material-ui/CloseCircleOutline'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  cursor: 'pointer',
  transition: '.25s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10]
  }
}))

// Styled Img component
const Img = styled('img')(({ theme }) => ({
  height: 32,
  width: 32
}))

// Given products icons and names
const icons = [
  { name: 'workspace', path: '/google_workspace.svg' },
  { name: 'cloud identity', path: '/cloud-identity.svg' },
  { name: 'chrome', path: '/chrome.svg' },
  { name: 'meet', path: '/meet.svg' },
  { name: 'voice', path: '/voice.svg' },
  { name: 'vault', path: '/vault.png' },
  { name: 'drive', path: '/drive.svg' },
  { name: 'cloud platform', path: '/cloud-platform.svg' }
]

const SubscriptionCard = props => {
  // ** Props
  const { sub, selectSubHandler, selectedSub, customerInformation } = props

  // ** Functions
  const renderIcon = sub => {
    // Render the product icon based on the given product name
    let name = sub?.product ? sub?.product?.productName?.toLowerCase() : sub?.skuName?.toLowerCase()
    let icon = icons.find(icon => name?.includes(icon?.name)) ?? icons[0]
    return <Img alt={name} src={`/google-products${icon?.path}`} />
  }

  return (
    <Grid item xs={12} sm={6} md={4.5} lg={3} xl={1.75} sx={{ maxWidth: '190px !important' }}>
      <StyledCard onClick={() => selectSubHandler(sub)}>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            height: 240,
            transition: 'all 0.1s ease-in-out',
            ...(selectedSub?.id == sub.id ? { borderBottom: theme => `solid 6px ${theme.palette.common.blue}` } : {})
          }}
        >
          {/* Render Icons based on product names  */}
          {renderIcon(sub)}
          <Typography sx={{ fontWeight: 600, mt: '0.725rem', fontSize: '0.825rem' }}>{sub.skuName}</Typography>
          {/* Display Plan name based on provided planName */}
          {sub.planName && (
            <Typography sx={{ mt: '0.525rem', fontSize: '0.685rem' }}>
              {sub.planName === 'ANNUAL_YEARLY_PAY' && 'Annual plan'}
              {sub.planName === 'ANNUAL' && 'Annual plan'}
              {sub.planName === 'FREE' && 'Free plan'}
              {sub.planName === 'FLEXIBLE' && 'Flexible plan'}
              {sub.planName === 'TRIAL' && 'Trial plan'}
            </Typography>
          )}
          {/* Billing Cycle payment */}
          {customerInformation.billingCycle && (
            <Typography sx={{ fontSize: '0.685rem' }}>({customerInformation.billingCycle + ' Payment'})</Typography>
          )}
          {sub.endTime && (
            <Typography sx={{ fontSize: '0.685rem' }}>
              Renewel {moment(new Date(parseInt(sub?.endTime))).format('MMM DD, YYYY')}
            </Typography>
          )}
          {/* Licenced seats based on if FLexible or Annual case */}
          {!isNaN(sub.licensedNumberOfSeats) && sub.planName !== 'FREE' ? (
            <Typography sx={{ fontSize: '0.685rem' }}>
              {(!isNaN(sub.licensedNumberOfSeats) ? sub.licensedNumberOfSeats : '') +
                (sub.planName === 'FLEXIBLE'
                  ? !isNaN(sub.maximumNumberOfSeats)
                    ? ' / ' + sub.maximumNumberOfSeats
                    : ''
                  : sub.numberOfSeats
                  ? ' / ' + sub.numberOfSeats
                  : '') +
                ' licences'}
            </Typography>
          ) : null}
          {/* Display Domain Name */}
          {sub.customerDomain && (
            <Typography sx={{ fontSize: '0.685rem', mb: '0.325rem' }}>{sub.customerDomain}</Typography>
          )}
          {/* Display Status */}
          <Stack direction='row' alignItems='center' gap={4} sx={{ mt: 'auto' }}>
            {sub.status == 'ACTIVE' ? (
              <CheckIcon sx={{ color: 'green', fontSize: '1rem' }} />
            ) : (
              <ErrorIcon sx={{ color: 'red', fontSize: '1rem' }} />
            )}
            <Typography sx={{ fontSize: '0.685rem' }}>{sub.status}</Typography>
          </Stack>
        </CardContent>
      </StyledCard>
    </Grid>
  )
}

export default SubscriptionCard
