
// ** Axios 
import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// ** API Imports
import { getTaxData } from 'configs/axios/api_helper';


export const initialState = {
    loading: null,
    user: {},
    token: null,
    success: "",
    error: "",
};
// A slice
const authSlice = createSlice({
    name: 'authData',
    initialState,
    reducers: {
        startLoading: (state, action) => {
            state.loading = action.payload
            state.error = ""
            state.success = ""
        },
        signInSuccess: (state, action) => {
            state.user = action.payload
            state.loading = null
            state.success = 'SIGNIN'
        },
        signInFailure: (state) => {
            state.loading = null
            state.error = "SIGNIN"
        },
    },
});

// Actions generated from the slice
const {
    startLoading,
    signInSuccess,
    signInFailure,
} = authSlice.actions;

// export user selector to get the slice in any component
export const authSelector = (state) => state.authData;


// export The reducer
const authReducer = authSlice.reducer;
export default authReducer;



// Actions
export const signIn = (data) => async (dispatch) => {
    try {
        dispatch(startLoading("SIGNIN"));
        dispatch(signInSuccess(data));
    } catch (error) {
        dispatch(signInFailure());
    }
};

