// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** API Imports
import {
  fetchRulesData,
  addRule,
  updateRule as updateRuleApi,
  fetchRule,
  removeRule,
  fetchOpportunitiesData,
  fetchSalesAgent,
  fetchAdminsGroup,
  getAllPayouts,
  payAgents,
  fetchAgentDealsData
} from 'configs/axios/api_helper'

export const initialState = {
  rules: [],
  salesAgents: [],
  opportunities: [],
  adminsGroup: [],
  payouts: [],
  rule: {},
  loading: false,
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const rulesSlice = createSlice({
  name: 'rulesData',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    createRule: (state, action) => {
      state.rules.push(action.payload)
      ;(state.success = 'CREATE_RULE'), (state.loading = false)
    },
    editRule: (state, action) => {
      state.success = 'UPDATE_RULE'
      state.rule = { ...state.rule, ...action.payload }
      state.loading = null
    },
    getRulesSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.rules = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET_RULES'
    },
    getOpportunitiesSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.opportunities = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET_OPPORTUNITIES'
    },
    getPayoutsSuccess: (state, action) => {
      state.payouts = action.payload
      state.loading = null
      state.success = 'GET_PAYOUTS'
    },
    getAdminsGroupSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.adminsGroup = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET_ADMINS_GROUP'
    },
    getSalesAgentsSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.salesAgents = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET_SALES_AGENTS'
    },
    paySalesAgentsSuccess: (state, action) => {
      state.loading = null
      state.success = 'PAY_SALES_AGENTS'
    },
    getRuleSuccess: (state, action) => {
      state.rule = action.payload
      state.loading = false
      state.success = 'GET_RULE'
    },
    cleanRule: state => {
      state.rule = {}
    },
    deleteRuleSuccess: (state, action) => {
      state.success = 'DELETE_RULE'
      state.rules = state.rules.filter(rule => rule.id !== action.payload)
      state.loading = false
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    },
    setErrorMessage: (state, action) => {
      state.loading = null
      state.error = action.payload
      state.success = ''
    }
  }
})

// Actions generated from the slice
const {
  createRule,
  editRule,
  getRulesSuccess,
  getRuleSuccess,
  startLoading,
  getAdminsGroupSuccess,
  getOpportunitiesSuccess,
  getSalesAgentsSuccess,
  paySalesAgentsSuccess,
  resetMessages,
  cleanRule,
  getPayoutsSuccess,
  setErrorMessage,
  deleteRuleSuccess
} = rulesSlice.actions

// export user selector to get the slice in any component
export const rulesSelector = state => state.rulesData

// export The reducer
const rulesReducer = rulesSlice.reducer
export default rulesReducer

// Actions
export const getRulesData = () => async dispatch => {
  try {
    dispatch(startLoading('GET_RULES'))
    const data = await fetchRulesData()
    dispatch(getRulesSuccess(data))
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const getOpportunitiesData = (salesAgentId, status) => async dispatch => {
  try {
    dispatch(startLoading('GET_OPPORTUNITIES'))
    const { data } = await fetchOpportunitiesData(salesAgentId, status)
    dispatch(getOpportunitiesSuccess(data))
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const getAgentOpportunitiesData = (salesAgentId, from, to) => async dispatch => {
  try {
    dispatch(startLoading('GET_OPPORTUNITIES'))
    const { data } = await fetchAgentDealsData(salesAgentId, from, to)
    dispatch(getOpportunitiesSuccess(data))
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const getPayoutsData = (from, to) => async dispatch => {
  try {
    dispatch(startLoading('GET_PAYOUTS'))
    const { data } = await getAllPayouts(from, to)
    const {
      data: { data: salesAgents }
    } = await fetchSalesAgent()
    const payouts = salesAgents?.map(agent => {
      const agentPayouts = data?.filter(payout => payout.salesAgentId === agent.id) || null
      return {
        id: agent.id,
        name: agent.firstname + ' ' + agent.lastname,
        sum: agentPayouts?.map(payout => ({ date: payout.dueDate, sum: payout.sum, status: payout.status })) || [],
        currency: 'CAD'
      }
    })
    dispatch(getPayoutsSuccess([...(payouts || []), { id: 999999, name: 'Total', sum: [], currency: null }]))
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const getSalesAgentsData = () => async dispatch => {
  try {
    dispatch(startLoading('GET_SALES_AGENTS'))
    const { data } = await fetchSalesAgent()
    dispatch(getSalesAgentsSuccess(data))
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const paySalesAgents = (payload, callBack) => async dispatch => {
  try {
    dispatch(startLoading('PAY_SALES_AGENTS'))
    const response = await payAgents(payload)
    callBack()
    dispatch(paySalesAgentsSuccess())
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const getAdminsGroup = () => async dispatch => {
  try {
    dispatch(startLoading('GET_ADMINS_GROUP'))
    const data = await fetchAdminsGroup()
    dispatch(getAdminsGroupSuccess(data))
  } catch (error) {
    dispatch(setErrorMessage(error))
  }
}

export const newRule = (rule, navigate) => async dispatch => {
  try {
    dispatch(startLoading('CREATE_RULE'))
    const data = await addRule(rule)
    dispatch(createRule(data))
    setTimeout(() => {
      navigate('/commissions/rules')
    }, 1500)
  } catch (error) {
    console.log('---eee', error)
    dispatch(
      setErrorMessage({
        status: error?.response?.status || 500,
        message: error?.response?.data?.message || 'An error has occured'
      })
    )
  }
}

export const getRuleData = id => async dispatch => {
  try {
    dispatch(startLoading('GET_RULE'))
    const { data } = await fetchRule(id)
    console.log('-azea---', data)
    dispatch(getRuleSuccess(data))
  } catch (error) {
    console.log('---', error)
    dispatch(setErrorMessage(error))
  }
}

export const updateRule = (id, rule) => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_RULE'))
    const data = await updateRuleApi(id, rule)
    dispatch(editRule(data))
  } catch (error) {
    dispatch(setErrorMessage())
  }
}

export const deleteRule = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE_RULE'))
    const data = await removeRule(id)
    dispatch(deleteRuleSuccess(id))
  } catch (error) {
    dispatch(setErrorMessage())
  }
}

export const resetRule = () => dispatch => {
  dispatch(cleanRule())
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
