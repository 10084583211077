// ** React Import
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import Table from '@mui/material/Table'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Divider from '@mui/material/Divider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// ** Redux Imports
import { customersSelector, getCustomersDailyUsages, getCustomersUsages } from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'

// ** Icon Imports
import SearchIcon from 'mdi-material-ui/Magnify'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import ChevronUp from 'mdi-material-ui/ChevronUp'

const CheckDailyUsages = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** State
  const [startDate, setStartDate] = useState(moment())
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)

  // ** Selectors
  const { checkDailyUsages, loading, error } = useSelector(customersSelector)

  // ** Get customer invoices
  useEffect(() => {
    dispatch(
      getCustomersDailyUsages({
        from: startDate.startOf('month').format('YYYY-MM-DD'),
        to: startDate.endOf('month').format('YYYY-MM-DD'),
        limit,
        page
      })
    )
  }, [startDate, page, limit])

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'day',
      headerName: 'Day',
      renderCell: params => (
        <Typography noWrap sx={{ fontSize: 14, fontWeight: 550 }}>
          {params.row.day ? moment(params.row.day).format('YYYY-MM-DD') : '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'nb_google_subscriptions',
      headerName: 'Google subscriptions',
      renderCell: params => (
        <Typography noWrap variant='body2' sx={{ fontSize: 14, fontWeight: 550 }}>
          {params.row.nb_google_subscriptions || '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'nb_daily_usages_subscriptions',
      headerName: 'Daily usages',
      renderCell: params => (
        <Typography noWrap variant='body2' sx={{ fontSize: 14, fontWeight: 550 }}>
          {params.row.nb_daily_usages_subscriptions || '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 50,
      field: 'missed_subscriptions',
      headerName: '',
      renderCell: params => {
        params.row?.missed_subscriptions?.length > 0 ? open ? <ChevronUp /> : <ChevronDown /> : null
      }
    }
  ]

  // ** Functions
  const openRowHandler = id => {
    if (open == id) {
      setOpen(null)
    } else {
      setOpen(id)
    }
  }

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Daily usages</Typography>
            <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>| Check daily usage generation</Typography>
          </Box>
        }
      />
      <Box p={5} display='flex' alignItems='center' gap={5}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box width={350}>
            <DesktopDatePicker
              label='Date'
              inputFormat='MM/DD/YYYY'
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
              sx={{ width: '100%' }}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={params => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Divider sx={{ color: theme => theme.palette.grey[300] }} />
      <Box sx={{ position: 'relative' }}>
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align='right'>Google Subscriptions</TableCell>
                <TableCell align='right'>Daily usage</TableCell>
                <TableCell align='right'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? null
                : checkDailyUsages?.map(row => (
                    <Fragment key={row?.day}>
                      <TableRow
                        sx={{ '& > *': {}, cursor: 'pointer' }}
                        onClick={() => (row.missed_subscriptions?.length > 0 ? openRowHandler(row?.day) : null)}
                      >
                        <TableCell>
                          <Typography noWrap sx={{ fontSize: 14, fontWeight: 550 }}>
                            {row.day ? moment(row.day).format('YYYY-MM-DD') : '----'}
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          <Typography noWrap variant='body2' sx={{ fontSize: 14, fontWeight: 550 }}>
                            {row?.nb_google_subscriptions || '----'}
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          <Typography noWrap variant='body2' sx={{ fontSize: 14, fontWeight: 550 }}>
                            {row?.nb_daily_usages_subscriptions || '----'}
                          </Typography>
                        </TableCell>
                        <TableCell align='right'>
                          {row.missed_subscriptions?.length > 0 ? open ? <ChevronUp /> : <ChevronDown /> : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={8} sx={{ py: '0 !important' }}>
                          <Collapse in={open == row.day ? true : false} timeout='auto' unmountOnExit>
                            <Box my={5}>
                              <Table size='small' aria-label='subscriptions'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Domain</TableCell>
                                    <TableCell>Sku name</TableCell>
                                    <TableCell>Seats</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row.missed_subscriptions?.map((sub, index) => (
                                    <TableRow key={sub.subscription_id}>
                                      <TableCell>{sub.customer_domain}</TableCell>
                                      <TableCell>{sub.sku_name}</TableCell>
                                      <TableCell>{sub.seats}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Backdrop
          open={loading}
          sx={{
            position: 'absolute',
            color: theme => theme.palette.common.blue,
            zIndex: theme => theme.zIndex.mobileStepper - 1,
            '&.MuiBackdrop-root': {
              backgroundColor: 'common.white'
            }
          }}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </Box>
    </Card>
  )
}

export default CheckDailyUsages
