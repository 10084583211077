// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// ** APIs
import { createFreshbooksId, getFreshbooksSuggestions, splitCustomerGoogleId } from 'configs/axios/api_helper'

// ** Third Party Liraries
import { useDebounce } from 'use-debounce'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Snackbar from '@mui/material/Snackbar'
import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/Delete'

// ** Redux Imports
import { useDispatch } from 'react-redux'
import { getBoardedCustomer } from 'store/customers'

// ** Select Freshbooks Modal

const SelectFreshbookOrganization = props => {
  // ** Props
  const { organizations, selectMemberHandler, filterHandler, customer, loading } = props

  // ** State
  const [open, setOpen] = useState(false)

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <Button
        disabled={loading == 'create_new_freshbooks'}
        variant='outlined'
        onClick={handleClickOpen}
        sx={{
          borderRadius: '0.5rem !important',
          textTransform: 'capitalize !important',
          border: theme => `1px solid ${theme.palette.grey[300]}`,
          color: 'common.black',
          '&:hover': {
            backgroundColor: theme => `${theme.palette.grey[50]} !important`,
            border: theme => `1px solid ${theme.palette.grey[300]}`
          }
        }}
      >
        {customer?.organization || '------'}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>Please select your organization:</DialogTitle>
        <DialogContent>
          <Autocomplete
            onInputChange={filterHandler}
            onChange={selectMemberHandler}
            options={organizations}
            loading={loading === 'get_organizations'}
            getOptionLabel={option => option?.organization}
            sx={{ width: '100%' }}
            size='small'
            renderInput={params => (
              <TextField
                {...params}
                label='Search for organizations'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading === 'get_organizations' ? (
                        <CircularProgress color='inherit' size={20} sx={{ mr: 10 }} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  )
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button
            onClick={handleClose}
            startIcon={
              loading == 'get_organizations' && <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
            }
            variant='text'
            size='small'
            color='primary'
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

const ReassignGoogleId = props => {
  // ** Props
  const { selectedIds, boardedCustomers } = props

  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()

  // ** State
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('existing')
  const [query, setQuery] = useState(null)
  const [loading, setLoading] = useState(null)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [organizationName, setOrganizationName] = useState(null)
  const [message, setMessage] = useState({ success: false, error: false, message: null, open: false })

  // ** Debouncing the input value
  const [value] = useDebounce(query, 500)

  // ** Fetch organizations from freshbooks
  useEffect(() => {
    const fetchOrganizations = async query => {
      setLoading('get_organizations')
      setOrganizations([])
      try {
        const { data } = await getFreshbooksSuggestions(query)
        setOrganizations(data)
        setLoading(null)
      } catch (error) {
        setLoading(null)
        setOrganizations([])
      }
    }
    if (value) {
      fetchOrganizations(value)
    } else {
      setOrganizations([])
    }
  }, [value])

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  // ** Functions
  const handleCloseMessage = () => setMessage({ success: false, error: false, message: '', open: false })

  const handleChange = event => setType(event.target.value)

  const optionRenderer = (props, option, { selected }) => {
    return (
      <li {...props} key={option.id}>
        {option.organization} {`(${option.domain})`}
      </li>
    )
  }

  const filterOptions = (options, { inputValue }) => {
    return options.filter(option => {
      // Customize this part to search in multiple fields of each object
      const searchString = `${option.organization} ${option.domain}`.toLowerCase()
      return searchString.includes(inputValue.toLowerCase())
    })
  }

  const filterHandler = async (event, email) => {
    const { value } = event.target
    setQuery(value)
  }

  const selectMemberHandler = (event, value) => {
    setSelectedOrganization(value)
  }

  const onChangeCustomer = (event, customer) => {
    setSelectedCustomer(customer)
  }

  const onChangeOrganizationName = event => setOrganizationName(event.target.value)

  const handleFreshbooksCreate = async () => {
    try {
      setLoading('create_new_freshbooks')
      const data = await createFreshbooksId({ organization: organizationName, allowLateNotifications: true })
      if (data?.id) {
        setLoading(null)
        setSelectedOrganization({ ...data })
      }
    } catch (error) {
      setLoading(null)
    }
  }

  const updateGoogleIdHandler = async () => {
    try {
      if (type === 'existing' && selectedCustomer.id) {
        setLoading('update')
        const body = {
          customerId: parseInt(id),
          newCustomerId: selectedCustomer?.id,
          domains: selectedIds
        }
        const response = await splitCustomerGoogleId(body)
        setMessage({ success: true, error: false, open: true, message: 'Action was done successfully !' })
        setLoading(null)
        dispatch(getBoardedCustomer(id))
        setOpen(false)
      } else if (type === 'new' && selectedOrganization.id) {
        setLoading('update')
        const body = {
          organization: organizationName,
          freshbooksId: selectedOrganization?.id,
          customerId: parseInt(id),
          domains: selectedIds
        }
        const response = await splitCustomerGoogleId(body)
        setMessage({ success: true, error: false, open: true, message: 'Action was done successfully !' })
        setLoading(null)
        dispatch(getBoardedCustomer(id))
        setOpen(false)
      }
    } catch (error) {
      setLoading(null)
      setMessage({ success: false, error: true, open: true, message: 'An error has occured, please try again.' })
    }
  }

  return (
    <Fragment>
      <Button
        disabled={!selectedIds.length || loading}
        variant='text'
        onClick={handleClickOpen}
        sx={{
          ml: '2rem',
          boxShadow: 0,
          '&:disabled': {
            color: theme => theme.palette.grey[700]
          }
        }}
      >
        Reassign
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>Please select one of the following choice</DialogTitle>
        <DialogContent>
          <FormControl sx={{ flexWrap: 'wrap', flexDirection: 'column' }}>
            <RadioGroup value={type} name='type-radio' onChange={handleChange} aria-label='type-radio'>
              <FormControlLabel value='existing' control={<Radio />} label='Existing customer' />
              <FormControlLabel value='new' control={<Radio />} label='New customer' />
            </RadioGroup>
          </FormControl>
          {type === 'existing' ? (
            <Box mt={4}>
              <Autocomplete
                onChange={onChangeCustomer}
                options={boardedCustomers?.data || []}
                getOptionLabel={option => option?.organization}
                filterOptions={filterOptions}
                renderOption={optionRenderer}
                size='small'
                sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
                renderInput={params => <TextField {...params} label='Search for customers by organization or domain' />}
              />
            </Box>
          ) : null}
          {type === 'new' ? (
            <Box mt={4}>
              <TextField
                label='Organization'
                fullWidth
                size='small'
                onChange={onChangeOrganizationName}
                placeholder='Premier Cloud'
              />
              <Box mt={4}></Box>
              <Box>
                <Typography variant='h6' sx={{ mt: '2rem', mb: '1rem' }}>
                  Freshbooks link:
                </Typography>
                <Box display='flex' flexDirection='row' alignItems='center' gap={4} sx={{ my: 2 }}>
                  <Typography fontSize={14} fontWeight={600}>
                    Freshbook ID :
                  </Typography>
                  <Button
                    disabled
                    sx={{
                      borderRadius: '0.5rem !important',
                      textTransform: 'capitalize !important',
                      border: theme => `1px solid ${theme.palette.grey[300]}`,
                      color: 'common.black'
                    }}
                  >
                    {selectedOrganization?.id || '-----'}
                  </Button>
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center' gap={4} sx={{ my: 2 }}>
                  <Typography fontSize={14} fontWeight={600}>
                    Freshbooks organization :
                  </Typography>
                  <SelectFreshbookOrganization
                    selectMemberHandler={selectMemberHandler}
                    organizations={organizations}
                    filterHandler={filterHandler}
                    loading={loading}
                    customer={selectedOrganization}
                  />
                </Box>
              </Box>
              <Box mt={5} display='flex' flexDirection='column'>
                <Typography variant='h7' sx={{ color: 'common.black', mb: 3 }}>
                  -- OR --
                </Typography>
                <Button
                  variant='contained'
                  size='medium'
                  color='primary'
                  onClick={handleFreshbooksCreate}
                  startIcon={
                    loading == 'create_new_freshbooks' && (
                      <CircularProgress sx={{ color: 'primary.main' }} size='1rem' />
                    )
                  }
                  disabled={loading === 'create_new_freshbooks' || !organizationName}
                >
                  Create new
                </Button>
              </Box>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading === 'update'} onClick={handleClose} size='medium' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading === 'update'}
            onClick={updateGoogleIdHandler}
            startIcon={loading == 'update' && <CircularProgress sx={{ color: 'primary.main' }} size='1rem' />}
            variant='text'
            size='medium'
            color='primary'
            sx={{ fontWeight: 600 }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        sx={{ mt: '3rem' }}
        open={message.open}
        onClose={handleCloseMessage}
        autoHideDuration={1500}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Grow}
        message={message.message}
      />
    </Fragment>
  )
}

export default ReassignGoogleId
