// ** React Imports
import React from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { useMediaQuery } from '@mui/material'

// ** Modal Imports
import SubscriptionEdit from '../modals/SubscriptionEdit'
import PricingInformation from '../modals/PricingInformation'

// ** Icons Imports
import PencilIcon from 'mdi-material-ui/PencilOutline'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))

// Styled Img component
const Img = styled('img')(({ theme }) => ({
  height: 45,
  width: 45
}))

const icons = [
  { name: 'workspace', path: '/google_workspace.svg' },
  { name: 'cloud identity', path: '/cloud-identity.svg' },
  { name: 'chrome', path: '/chrome.svg' },
  { name: 'meet', path: '/meet.svg' },
  { name: 'voice', path: '/voice.svg' },
  { name: 'vault', path: '/vault.png' },
  { name: 'drive', path: '/drive.svg' },
  { name: 'cloud platform', path: '/cloud-platform.svg' }
]
//
const SubscriptionDetails = props => {
  // ** Props
  const { selectedSub, selectSubHandler, customerInformation } = props
  // **  Hooks
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** Hooks
  const renderIcon = sub => {
    let name = sub?.product ? sub?.product?.productName?.toLowerCase() : sub?.skuName?.toLowerCase()
    let icon = icons.find(icon => name?.includes(icon?.name) || icon.name.includes('workspace'))
    return <Img alt={name} src={`/google-products${icon?.path}`} />
  }

  return (
    <StyledCard>
      <CardHeader
        title={
          <Grid container spacing={4}>
            <Grid
              item
              container
              xs={12}
              md={6}
              spacing={4}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Grid
                item
                xs={12}
                md={9}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ...(small ? { flexDirection: 'column', justifyContent: 'center', textAlign: 'center' } : {})
                }}
              >
                {renderIcon(selectedSub)}
                <Typography sx={{ fontWeight: 600, mx: '1rem', ...(small && { mt: '1rem', mx: 0 }) }}>
                  {selectedSub?.skuName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} sx={{ ...(small ? { textAlign: 'center' } : { textAlign: 'end' }) }}>
                <Button
                  variant='contained'
                  sx={{
                    boxShadow: 0,
                    borderRadius: '4rem',
                    color: 'common.blue',
                    backgroundColor: 'common.blueLight',
                    '&:hover': {
                      backgroundColor: 'common.blueLight',
                      boxShadow: 0
                    }
                  }}
                >
                  <PencilIcon sx={{ mr: 2 }} />
                  Active
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sx={{ ...(small ? { textAlign: 'center' } : { textAlign: 'end' }) }}>
              {selectedSub?.planName?.toLowerCase() !== 'free' && (
                <SubscriptionEdit
                  subscription={selectedSub}
                  customerId={customerInformation?.id}
                  selectSubHandler={selectSubHandler}
                />
              )}
            </Grid>
          </Grid>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <CardContent>
            <Typography variant='body1'>Actions</Typography>
            <Divider sx={{ borderBottomWidth: 2, color: theme => theme.palette.grey[300] }} />
            <Stack direction='row' alignItems='center' gap={2}>
              <Typography variant='body2'>Licenses : {selectedSub.licensedNumberOfSeats || '--'}</Typography>
            </Stack>
            <Typography sx={{ fontSize: '0.825rem', color: 'text.secondary' }}>Manage licenses</Typography>
          </CardContent>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardContent>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1' sx={{ mr: 2 }}>
                  Payment
                </Typography>
                <Typography
                  sx={{
                    borderRadius: '3rem',
                    border: theme => `solid 1px ${theme.palette.grey[400]}`,
                    px: 3,
                    mx: 2,
                    fontSize: '0.875rem',
                    minHeight: '1.25rem',
                    color: theme => theme.palette.grey[500],
                    whiteSpace: 'nowrap'
                  }}
                >
                  {selectedSub?.planName === 'ANNUAL_YEARLY_PAY' && 'ANNUAL plan'}
                  {selectedSub?.planName === 'ANNUAL' && 'ANNUAL plan'}
                  {selectedSub?.planName === 'FLEXIBLE' && 'Flexible plan'}
                  {selectedSub?.planName === 'FREE' && 'Free plan'}
                  {selectedSub?.planName === 'TRIAL' && 'Trial plan'}
                </Typography>
                <PricingInformation
                  subscription={selectedSub}
                  iso_code={customerInformation?.currency?.iso_code}
                  selectSubHandler={selectSubHandler}
                />
              </Grid>
            </Grid>
            <Divider sx={{ borderBottomWidth: 2, color: theme => theme.palette.grey[300] }} />
            {selectedSub?.licensedNumberOfSeats ? (
              <Typography variant='body2'>
                {(
                  selectedSub?.licensedNumberOfSeats *
                  (customerInformation?.currency?.iso_code == 'EUR'
                    ? selectedSub?.product?.priceEur || 0
                    : customerInformation?.currency?.iso_code == 'USD'
                    ? selectedSub?.product?.priceUsd || 0
                    : selectedSub?.product?.priceCad || 0)
                )?.toFixed(2)}{' '}
                {customerInformation?.currency?.iso_code || 'CAD'} /month (estimated)
              </Typography>
            ) : null}
          </CardContent>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default SubscriptionDetails
