// ** React Imports
import React, { useState } from 'react'

// ** MUI IMports
import { Typography, Grid } from '@mui/material'

// ** Custom Components
import ProductCard from './ProductCard'
import PurchaseScreen from './PurchaseScreen'
import EmptyProducts from './EmptyProducts'

// ** Hooks
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'

// ** APIs

const ProductsList = props => {
  // ** Props
  const { skusData, categoryName } = props

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)

  // ** Constants
  const productsData = skusData?.map(product => {
    const found = customerInformation?.googleIds?.some?.(googleId =>
      googleId?.subscriptions?.some(sub => sub?.product?.id === product?.id)
    )
    if (found) return { ...product, purchased: true }
    return { ...product, purchased: false }
  })

  // ** State
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [open, setOpen] = useState(false)

  // ** Functions
  const openPurchaseScreen = id => {
    const product = skusData?.find(item => item.id === id)
    setSelectedProduct(product)
    setOpen(true)
  }
  const findPrice = product => {
    const currency = customerInformation?.currency || null
    const flexiblePrice =
      product?.prices?.find(
        item => (item.currencyId == currency?.id || item.currencyId === 12) && item.type === 'annual'
      )?.value || 0
    return flexiblePrice
  }
  return (
    <>
      {productsData?.length > 0 ? (
        <Grid container item xs={12} spacing={4}>
          <Grid item xs={12} height='fit-content'>
            <Typography fontSize={16} variant='body1' p={3}>
              {categoryName}
            </Typography>
          </Grid>
          {productsData?.map((item, index) => (
            <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
              <ProductCard
                name={item.skuName}
                id={item.id}
                description={item.skuDescription}
                icon={item.productName}
                openPurchaseScreen={openPurchaseScreen}
                purchased={item.purchased}
                price={findPrice(item)}
                unit='licence'
              />
            </Grid>
          ))}
          <PurchaseScreen open={open} setOpen={setOpen} selectedProduct={selectedProduct} />
        </Grid>
      ) : (
        <EmptyProducts />
      )}
    </>
  )
}

export default ProductsList
